
import { mapGetters } from 'vuex';

import { EDU_PATH } from '@/constants/paths';

import { baseUrl } from '@/plugins/constants';

import { moveToLaravelPage } from '@/utils/utils';

import {
	IS_UNIV_AFFILIATE_AVAILABLE,
	AFFILIATE_UNIV_EXPOSURE_DATA,
	mapGettersOfCase,
	USER_PARTNER_INFO,
} from '@/store/case';
import { GET_IS_LOADED_PASS_INFO, mapGettersOfMyPage, GET_PASS_INFO } from '@/store/myPage';
import { GET_USER_META_INTEREST_DEPARTMENT, mapGettersOfUserMeta } from '@/store/userMeta';

import caseMixin from '@/mixins/caseMixin';
import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import passInfoMixin from '@/mixins/passInfoMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

export default {
	name: 'MembershipStatusSection',
	mixins: [layoutMixin, caseMixin, userInfoMixin, passInfoMixin, routeMixin, gtmTriggerMixin],
	data() {
		return {
			isOpen: true,
			baseUrl,
		};
	},
	computed: {
		...mapGetters(['isUser', 'isMentee']),
		...mapGettersOfMyPage([GET_PASS_INFO, GET_IS_LOADED_PASS_INFO]),
		...mapGettersOfCase([IS_UNIV_AFFILIATE_AVAILABLE, USER_PARTNER_INFO, AFFILIATE_UNIV_EXPOSURE_DATA]),
		...mapGettersOfUserMeta([GET_USER_META_INTEREST_DEPARTMENT]),
		contentStatus() {
			return this.isUser ? this[GET_PASS_INFO].contents : '';
		},
		essayStatus() {
			return this.isUser ? this[GET_PASS_INFO].essay : '3회';
		},
		aiStatus() {
			return this.isUser ? this[GET_PASS_INFO].ai : '최초 1회';
		},
		isShowContent() {
			return this.isUser && this[GET_PASS_INFO].contents;
		},
		isShowVod() {
			return this.isUser && this[GET_PASS_INFO].vod;
		},
		userUniv() {
			return this[GET_PASS_INFO].userInfo.univ;
		},
		isLoaded() {
			return !this.isUser || this[GET_IS_LOADED_PASS_INFO];
		},
		passName() {
			return this.$_hasPassInfo ? this[GET_PASS_INFO].pass_name : '';
		},
		isSogang() {
			return this.passName === '서강대학교';
		},
		affiliateDisplayUnivName() {
			const univName = this[AFFILIATE_UNIV_EXPOSURE_DATA]?.partner ?? '';
			return univName.split(' ')[0];
		},
		hasCareer() {
			return this.$_userInfoForGtm.user_career === '경력';
		},
		hasInterestDepartment() {
			return this[GET_USER_META_INTEREST_DEPARTMENT].length > 0;
		},
	},
	mounted() {
		setTimeout(() => {
			this.isOpen = false;
		}, 10000);
	},
	methods: {
		moveToLaravelPage,
		toggle() {
			this.isOpen = !this.isOpen;
		},
		clickPromotionButton() {
			if (this.hasCareer) {
				this.$_routeMixin_href('/mentor');
				return;
			}

			if (this[IS_UNIV_AFFILIATE_AVAILABLE]) {
				moveToLaravelPage('/affiliated-university');
				return;
			}

			this.$_clickCommunityToEduCTA({
				...this.$_userInfoForGtm,
				where: 'onboardCaseList',
				category: 'sidebox',
				type: 'callout',
				link: `${baseUrl}${EDU_PATH}`,
			});
			this.$_routeMixin_href(EDU_PATH);
		},
		goToVod() {
			this.$_routeMixin_go_page('/vod');
		},
		onClickEduAffiliateButton() {
			this.$_clickCommunityToEdu_B2BList({
				...this.$_userInfoForGtm,
				where: 'onboardCaseList',
				type: 'callout',
				contractNo: this[AFFILIATE_UNIV_EXPOSURE_DATA].contractNo,
				partner_name: this[AFFILIATE_UNIV_EXPOSURE_DATA].partner,
				link: `/edu/univ/${this[AFFILIATE_UNIV_EXPOSURE_DATA].partnerUrl}`,
			});
			if (this.IS_MOBILE) {
				moveToLaravelPage(`/edu/univ/${this[AFFILIATE_UNIV_EXPOSURE_DATA].partnerUrl}`);
			} else {
				this.$_routeMixin_open_page(`${baseUrl}/edu/univ/${this[AFFILIATE_UNIV_EXPOSURE_DATA].partnerUrl}`);
			}
		},
	},
};
