import { createNamespacedHelpers } from 'vuex';

import customLogger from '@/plugins/custom-logger';

import { commonErrorLogger } from '@/utils/errorHandler';

const NAMESPACE = 'edu/encore';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfEduEncore,
	mapMutations as mapMutationsOfEduEncore,
	mapActions as mapActionsOfEduEncore,
};

// states
export const EDU_ENCORE_INFO = 'EDU_ENCORE_INFO';
export const EDU_ENCORE_TOKEN = 'EDU_ENCORE_TOKEN';
export const SELECTED_INDEX = 'SELECTED_INDEX';
export const SELECTED_ENCORE_TYPE = 'SELECTED_ENCORE_TYPE';
export const SHOW_ENCORE_CONFIRM_MODAL = 'SHOW_ENCORE_CONFIRM_MODAL';
export const KAKAO_INFO = 'KAKAO_INFO';
export const CAMP_SCHEDULE_LIST = 'CAMP_SCHEDULE_LIST';
export const IS_LINK_DUPLICATED = 'IS_LINK_DUPLICATED';

// mutations
export const SET_EDU_ENCORE_INFO = 'SET_EDU_ENCORE_INFO';
export const SET_EDU_ENCORE_TOKEN = 'SET_EDU_ENCORE_TOKEN';
export const SET_SELECTED_INDEX = 'SET_SELECTED_INDEX';
export const SET_SHOW_ENCORE_CONFIRM_MODAL = 'SET_SHOW_ENCORE_CONFIRM_MODAL';
export const SET_SELECTED_ENCORE_TYPE = 'SET_SELECTED_ENCORE_TYPE';
export const SET_SELECTED_ENCORE_TYPE_ITEM = 'SET_SELECTED_ENCORE_TYPE_ITEM';
export const SET_KAKAO_INFO = 'SET_KAKAO_INFO';
export const SET_CAMP_SCHEDULE_LIST = 'SET_CAMP_SCHEDULE_LIST';
export const SET_IS_LINK_DUPLICATED = 'SET_IS_LINK_DUPLICATED';

// actions
export const FETCH_ENCORE_INFO = 'FETCH_ENCORE_INFO';
export const REQUEST_ENCORE_ACCEPT = 'REQUEST_ENCORE_ACCEPT';
export const MAP_SELECTED_TYPE_LIST = 'MAP_SELECTED_TYPE_LIST';
export const SUBMIT_EDU_ENCORE_SUGGEST = 'SUBMIT_EDU_ENCORE_SUGGEST';

export const state = () => ({
	[EDU_ENCORE_INFO]: {},
	[KAKAO_INFO]: [],
	[CAMP_SCHEDULE_LIST]: {},
	[EDU_ENCORE_TOKEN]: '',
	[SELECTED_ENCORE_TYPE]: [],
	[SELECTED_INDEX]: 0,
	[SHOW_ENCORE_CONFIRM_MODAL]: false,
	[IS_LINK_DUPLICATED]: false,
});

export const getters = {
	[EDU_ENCORE_INFO]: state => state[EDU_ENCORE_INFO],
	[SELECTED_INDEX]: state => state[SELECTED_INDEX],
	[SELECTED_ENCORE_TYPE]: state => state[SELECTED_ENCORE_TYPE],
	[KAKAO_INFO]: state => state[KAKAO_INFO],
	[CAMP_SCHEDULE_LIST]: state => state[CAMP_SCHEDULE_LIST],
	[SHOW_ENCORE_CONFIRM_MODAL]: state => state[SHOW_ENCORE_CONFIRM_MODAL],
	[IS_LINK_DUPLICATED]: state => state[IS_LINK_DUPLICATED],
};

export const mutations = {
	[SET_EDU_ENCORE_INFO]: (state, payload) => {
		state[EDU_ENCORE_INFO] = payload;
	},
	[SET_EDU_ENCORE_TOKEN]: (state, payload) => {
		state[EDU_ENCORE_TOKEN] = payload;
	},
	[SET_SELECTED_INDEX]: (state, payload) => {
		state[SELECTED_INDEX] = payload;
	},
	[SET_SHOW_ENCORE_CONFIRM_MODAL]: (state, payload) => {
		state[SHOW_ENCORE_CONFIRM_MODAL] = payload;
	},
	[SET_SELECTED_ENCORE_TYPE]: (state, payload) => {
		state[SELECTED_ENCORE_TYPE] = payload;
	},
	[SET_SELECTED_ENCORE_TYPE_ITEM]: (state, { index, type }) => {
		state[SELECTED_ENCORE_TYPE] = state[SELECTED_ENCORE_TYPE].map((item, listIndex) => {
			return index === listIndex ? { selectedType: type } : item;
		});
	},
	[SET_KAKAO_INFO]: (state, payload) => {
		state[KAKAO_INFO] = payload;
	},
	[SET_CAMP_SCHEDULE_LIST]: (state, payload) => {
		state[CAMP_SCHEDULE_LIST] = payload;
	},
	[SET_IS_LINK_DUPLICATED]: (state, payload) => {
		state[IS_LINK_DUPLICATED] = payload;
	},
};

export const actions = {
	async [FETCH_ENCORE_INFO]({ commit, dispatch }, token) {
		const params = {
			token,
		};
		try {
			const { data } = await this.$api.eduEncore.getEncoreInfo(params);
			commit(SET_EDU_ENCORE_INFO, data);
			commit(SET_EDU_ENCORE_TOKEN, token);
			dispatch(MAP_SELECTED_TYPE_LIST, data.encore_camp_detail);
		} catch (error) {
			console.error('FETCH_ENCORE_INFO error: ', error);
		}
	},
	async [REQUEST_ENCORE_ACCEPT]({ dispatch, commit, getters, state, rootState }) {
		const weekMain = getters[CAMP_SCHEDULE_LIST].encoreSchedule.map((schedule, index) => {
			return {
				week: index + 1,
				title: index % 2 === 0 ? '현직자 온라인 세션' : `${index}차 과제 피드백`,
				start_at: schedule.startDate,
				object: 'main',
				end_at: schedule.endDate,
				description: '',
			};
		});
		const weekSub = getters[CAMP_SCHEDULE_LIST].encoreSchedule.map((schedule, index) => {
			return {
				description: '',
				end_at: schedule.assignmentDeadline,
				object: 'object',
				start_at: schedule.assignmentDeadline,
				title: `${index + 1}차 과제 수행`,
				week: index + 1,
			};
		});
		const data = {
			detail_id: getters[EDU_ENCORE_INFO].encore_camp_detail[getters[SELECTED_INDEX]].id,
			weekMain,
			weekSub: [...weekSub].slice(0, 4),
			kakao_link: getters[KAKAO_INFO].link,
			kakao_code: getters[KAKAO_INFO].code,
			holiday: 'update',
		};
		try {
			const { data: acceptResponse } = await this.$api.eduEncore.createEduEncore(data);
			if (acceptResponse.code === 201) {
				Toast.show({
					message: '해당 일시 개설을 수락하였습니다.',
					type: 'security',
					position: 'top',
					timeout: 3000,
					showIcon: false,
				});
			}
			if (acceptResponse.code === 409) {
				commit(SET_IS_LINK_DUPLICATED, true);
				return;
			}
			commit(SET_SHOW_ENCORE_CONFIRM_MODAL, false);
			dispatch(FETCH_ENCORE_INFO, state[EDU_ENCORE_TOKEN]);
		} catch (error) {
			console.error(error);
			customLogger({
				message: '직부캠 앵콜 수락 요청 실패',
				messageContext: {
					userNo: rootState.userInfo && rootState.userInfo.no,
					err: error.response?.data,
					status: error.response?.status,
					token: state[EDU_ENCORE_TOKEN],
				},
				level: 'debug',
			});
			Toast.show({
				message: '오류가 발생했습니다.',
				type: 'error',
				position: 'top',
				timeout: 3000,
				showIcon: false,
			});
		}
	},
	[MAP_SELECTED_TYPE_LIST]({ commit }, encoreInfoDetail) {
		const selectedObj = { selectedType: '' };
		const selectedList = new Array(encoreInfoDetail.length).fill({ ...selectedObj });
		commit(SET_SELECTED_ENCORE_TYPE, selectedList);
	},
	async [SUBMIT_EDU_ENCORE_SUGGEST]({ dispatch, state, getters }, suggest) {
		const data = {
			encore_id: getters[EDU_ENCORE_INFO].id,
			suggest,
		};
		try {
			await this.$api.eduEncore.submitEncoreSuggest(data);
			Toast.show({
				message: '교육 매니저에게 사유가 전달되었어요.',
				type: 'basic',
				position: 'top',
				timeout: 3000,
				showIcon: false,
			});
			dispatch(FETCH_ENCORE_INFO, state[EDU_ENCORE_TOKEN]);
		} catch (error) {
			commonErrorLogger(error, {
				title: '멘토 앵콜 요청 거절 사유 제출 실패',
			});
		}
	},
};
