export const EXPERIMENT_KEYS = {
	CM167: 107,
	CM171: 117,
	CM175: 120,
	CM185: 131,
	CM189: 135,
	VO045: 149,
};

export const getExperimentKeyById = id => {
	if (!id) {
		throw new Error('getExperimentKeyById id는 필수값입니다.');
	}
	return EXPERIMENT_KEYS[id];
};

export const getExperimentVariation = experimentDetail => experimentDetail.variation?.value || 'A';
