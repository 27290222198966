
import { getCdnImagePath } from '@/utils/cdn';
import { isEmptyData } from '@/utils/utils';

import {
	CREDIT_SHOP_LIST,
	LOAD_CREDIT_SHOP_LIST,
	TOGGLE_IS_OPEN_CREDIT_SHOP_LIST,
	IS_OPEN_CREDIT_SHOP_LIST,
	mapActionsOfCreditShop,
	mapGettersOfCreditShop,
	mapMutationsOfCreditShop,
} from '@/store/answer/creditShop';
import {
	GET_TOTAL_CREDIT,
	mapMutationsOfMyPage,
	mapGettersOfMyPage,
	SET_TOTAL_CREDIT,
	mapActionsOfMyPage,
	LOAD_CREDIT_USAGE,
} from '@/store/myPage';

import CreditShopItem from '@/components/answer/molecules/CreditShopItem.vue';
import HorizontalDragScroll from '@/components/common/atoms/HorizontalDragScroll.vue';

import appCommandMixin from '@/mixins/appCommandMixin';
import answerGtmMixin from '@/mixins/gtm/answerGtmMixin';
import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

const REVENUE_TYPES = {
	CREDIT: 'credit',
	GIFT: 'gift',
};

export default {
	name: 'AnswerSideBoxCreditShop',
	mixins: [appCommandMixin, layoutMixin, routeMixin, answerGtmMixin, userInfoMixin],
	computed: {
		...mapGettersOfCreditShop([CREDIT_SHOP_LIST, IS_OPEN_CREDIT_SHOP_LIST]),
		...mapGettersOfMyPage([GET_TOTAL_CREDIT]),
	},
	mounted() {
		this[LOAD_CREDIT_USAGE]();
		this[LOAD_CREDIT_SHOP_LIST]();
		this.$refs.creditShopSwiper.addEventListener('touchstart', () => this.$_app_setSwipe(false));
		this.$refs.creditShopSwiper.addEventListener('touchend', () => this.$_app_setSwipe(true));
	},
	beforeDestroy() {
		this.$refs.creditShopSwiper.addEventListener('touchstart', () => this.$_app_setSwipe(false));
		this.$refs.creditShopSwiper.addEventListener('touchend', () => this.$_app_setSwipe(true));
	},
	methods: {
		getCdnImagePath,
		isEmptyData,
		...mapActionsOfCreditShop([LOAD_CREDIT_SHOP_LIST]),
		...mapMutationsOfCreditShop([TOGGLE_IS_OPEN_CREDIT_SHOP_LIST]),
		...mapMutationsOfMyPage([SET_TOTAL_CREDIT]),
		...mapActionsOfMyPage([LOAD_CREDIT_USAGE]),
		goMyCredit() {
			this.$_clickCreditBox({
				...this.$_userInfoForGtm,
				revenue_type: REVENUE_TYPES.CREDIT,
			});
			this.$_routeMixin_go_page('/mypage/credit/list');
		},
		goCreditShop() {
			this.$_clickCreditBox({
				...this.$_userInfoForGtm,
				revenue_type: REVENUE_TYPES.GIFT,
			});
			this.$_routeMixin_go_page('/mypage/shop/list');
		},
		goCreditShopDetail({ id, type }) {
			this.$_clickCreditBox({
				...this.$_userInfoForGtm,
				revenue_type: REVENUE_TYPES.GIFT,
			});

			// type이 giftishow면 goods로 보내기
			const pathForType = type === undefined ? 'cash' : 'goods';
			return this.$_routeMixin_go_page(`/shop/${pathForType}/${id}`);
		},
	},
	components: { HorizontalDragScroll, CreditShopItem },
};
