
import { mapGetters } from 'vuex';

import {
	IS_MOBILE,
	IS_SHOW_ALERT_LAYOUT,
	IS_STICKY_ENABLED,
	IS_TRANSPARENT_TYPE,
	mapGettersOfLayout,
} from '@/store/layout';
import { GET_IS_COMMUNITY_LIST_PAGE, mapGettersOfLoading, ROUTE_NAMES_FOR_COMMUNITY_LIST } from '@/store/loading';

import GridLayout from '@/components/common/atoms/GridLayout.vue';

// TODO design system colors로 변경

import layoutMixin from '@/mixins/layoutMixin';
import myPageMixin from '@/mixins/myPageMixin';

import CommunityListNuxt from '@/layouts/CommunityListNuxt.vue';
import MyPageNuxt from '@/layouts/MyPageNuxt.vue';
import { colors } from '@/src/elements/core/colors';

export default {
	name: 'NuxtLayout',
	mixins: [myPageMixin, layoutMixin],
	data() {
		return {
			ROUTE_NAMES_FOR_COMMUNITY_LIST,
		};
	},
	computed: {
		...mapGetters(['metaFluid', 'metaBgColor', 'hasJwt', 'isKPC']),
		...mapGettersOfLayout([IS_MOBILE, IS_SHOW_ALERT_LAYOUT, IS_STICKY_ENABLED, IS_TRANSPARENT_TYPE]),
		...mapGettersOfLoading([GET_IS_COMMUNITY_LIST_PAGE]),
		computedStyle() {
			const colorValue = Object.keys(colors).includes(this.bgColor) ? colors[this.bgColor] : this.bgColor;
			return {
				'background-color': colorValue,
				// footer 높이 제외
				'min-height': 'calc(100vh - 324px)',
				...this.calculateHeaderOverlapMargin,
			};
		},
		calculateHeaderOverlapMargin() {
			if (this[IS_SHOW_ALERT_LAYOUT] && this[IS_TRANSPARENT_TYPE]) {
				// Alert + 투명 헤더일 때는 헤더가 콘텐츠 배경색을 사용할 수 겹치게 보여주기
				return { 'margin-top': `-${this[IS_MOBILE] ? '52px' : '68px'}` };
			}
			return {};
		},
		bgColor() {
			const bgColor = this.metaBgColor;
			if (Array.isArray(bgColor)) {
				return !this.IS_MOBILE ? this.metaBgColor[0] : this.metaBgColor[1];
			}
			return bgColor;
		},
		isCommunityListPage() {
			return this.ROUTE_NAMES_FOR_COMMUNITY_LIST.includes(this.$route.name);
		},
	},
	components: { CommunityListNuxt, MyPageNuxt, GridLayout },
};
