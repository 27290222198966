import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'myPage/shop';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfMyPageShop,
	mapMutations as mapMutationsOfMyPageShop,
	mapActions as mapActionsOfMyPageShop,
};

export const SHOPS = 'SHOPS';

export const SET_SHOPS = 'SET_SHOPS';

export const state = () => ({
	shops: [],
});

export const getters = {
	[SHOPS]: state => state.shops,
};

export const mutations = {
	[SET_SHOPS](state, shops) {
		state.shops = shops;
	},
};
