
import ExchangeCashNoticeModalContent from '@/components/my-page/shop/detail/ExchangeCashNoticeModalContent.vue';

import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'ExchangeCashNoticeModal',
	mixins: [layoutMixin],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		syncShow: {
			get() {
				return this.show;
			},
			set(value) {
				this.$emit('update:show', value);
			},
		},
	},
	methods: {
		emitClose() {
			this.$emit('close');
		},
	},
	components: { ExchangeCashNoticeModalContent },
};
