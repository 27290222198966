import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/alumni.js'), 'alumni.js')
  resolveStoreModules(require('../store/whitelist/index.js'), 'whitelist/index.js')
  resolveStoreModules(require('../store/vod/index.js'), 'vod/index.js')
  resolveStoreModules(require('../store/userVoice/index.js'), 'userVoice/index.js')
  resolveStoreModules(require('../store/userMeta/index.js'), 'userMeta/index.js')
  resolveStoreModules(require('../store/univ/index.js'), 'univ/index.js')
  resolveStoreModules(require('../store/training/index.js'), 'training/index.js')
  resolveStoreModules(require('../store/stateForApp/index.js'), 'stateForApp/index.js')
  resolveStoreModules(require('../store/secrecy/index.js'), 'secrecy/index.js')
  resolveStoreModules(require('../store/review/index.js'), 'review/index.js')
  resolveStoreModules(require('../store/refund/index.js'), 'refund/index.js')
  resolveStoreModules(require('../store/recruit/index.js'), 'recruit/index.js')
  resolveStoreModules(require('../store/pullToRefresh/index.js'), 'pullToRefresh/index.js')
  resolveStoreModules(require('../store/pay/index.js'), 'pay/index.js')
  resolveStoreModules(require('../store/notice/index.js'), 'notice/index.js')
  resolveStoreModules(require('../store/myPage/index.js'), 'myPage/index.js')
  resolveStoreModules(require('../store/mentorAuth/index.js'), 'mentorAuth/index.js')
  resolveStoreModules(require('../store/loading/index.js'), 'loading/index.js')
  resolveStoreModules(require('../store/live-class/index.js'), 'live-class/index.js')
  resolveStoreModules(require('../store/layout/index.js'), 'layout/index.js')
  resolveStoreModules(require('../store/kdc/index.js'), 'kdc/index.js')
  resolveStoreModules(require('../store/historyCustom/index.js'), 'historyCustom/index.js')
  resolveStoreModules(require('../store/error/index.js'), 'error/index.js')
  resolveStoreModules(require('../store/dreamverse/index.js'), 'dreamverse/index.js')
  resolveStoreModules(require('../store/classroom/index.js'), 'classroom/index.js')
  resolveStoreModules(require('../store/case/index.js'), 'case/index.js')
  resolveStoreModules(require('../store/camp/index.js'), 'camp/index.js')
  resolveStoreModules(require('../store/appContext/index.js'), 'appContext/index.js')
  resolveStoreModules(require('../store/answer/index.js'), 'answer/index.js')
  resolveStoreModules(require('../store/affiliate/index.js'), 'affiliate/index.js')
  resolveStoreModules(require('../store/advertisement/index.js'), 'advertisement/index.js')
  resolveStoreModules(require('../store/search/list.js'), 'search/list.js')
  resolveStoreModules(require('../store/vod/player/index.js'), 'vod/player/index.js')
  resolveStoreModules(require('../store/vod/lecture/index.js'), 'vod/lecture/index.js')
  resolveStoreModules(require('../store/vod/landing/index.js'), 'vod/landing/index.js')
  resolveStoreModules(require('../store/vod/kdc/index.js'), 'vod/kdc/index.js')
  resolveStoreModules(require('../store/user/bookmark/index.js'), 'user/bookmark/index.js')
  resolveStoreModules(require('../store/myPage/shop/index.js'), 'myPage/shop/index.js')
  resolveStoreModules(require('../store/myPage/matching/index.js'), 'myPage/matching/index.js')
  resolveStoreModules(require('../store/modal/alert/index.js'), 'modal/alert/index.js')
  resolveStoreModules(require('../store/edu/schedule/index.js'), 'edu/schedule/index.js')
  resolveStoreModules(require('../store/edu/purchase-survey/index.js'), 'edu/purchase-survey/index.js')
  resolveStoreModules(require('../store/edu/list/index.js'), 'edu/list/index.js')
  resolveStoreModules(require('../store/edu/landing/index.js'), 'edu/landing/index.js')
  resolveStoreModules(require('../store/edu/encore/index.js'), 'edu/encore/index.js')
  resolveStoreModules(require('../store/community/timeline/index.js'), 'community/timeline/index.js')
  resolveStoreModules(require('../store/community/lounge/index.js'), 'community/lounge/index.js')
  resolveStoreModules(require('../store/community/insight/index.js'), 'community/insight/index.js')
  resolveStoreModules(require('../store/classroom/pt/index.js'), 'classroom/pt/index.js')
  resolveStoreModules(require('../store/classroom/path/index.js'), 'classroom/path/index.js')
  resolveStoreModules(require('../store/class/on-air/index.js'), 'class/on-air/index.js')
  resolveStoreModules(require('../store/camp/path/index.js'), 'camp/path/index.js')
  resolveStoreModules(require('../store/answer/creditShop/index.js'), 'answer/creditShop/index.js')
  resolveStoreModules(require('../store/vod/player/lecture/index.js'), 'vod/player/lecture/index.js')
  resolveStoreModules(require('../store/vod/player/checkPlus/index.js'), 'vod/player/checkPlus/index.js')
  resolveStoreModules(require('../store/class/pt/open/index.js'), 'class/pt/open/index.js')
  resolveStoreModules(require('../store/class/pt/list/index.js'), 'class/pt/list/index.js')
  resolveStoreModules(require('../store/class/pt/detail/index.js'), 'class/pt/detail/index.js')
  resolveStoreModules(require('../store/class/pt/dashboard/index.js'), 'class/pt/dashboard/index.js')
  resolveStoreModules(require('../store/vod/player/lecture/note/index.js'), 'vod/player/lecture/note/index.js')
  resolveStoreModules(require('../store/vod/player/lecture/file/index.js'), 'vod/player/lecture/file/index.js')
  resolveStoreModules(require('../store/vod/player/lecture/dashboard/index.js'), 'vod/player/lecture/dashboard/index.js')
  resolveStoreModules(require('../store/vod/player/lecture/community/index.js'), 'vod/player/lecture/community/index.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
