
import { HackleProvider, createInstance } from '@comento/hackle-sdk';
import HackleDevTools from '@hackler/javascript-devtools';
import { mapGetters } from 'vuex';

import { CLASSROOM_PATH } from '@/constants/paths';
import { ROUTE_NAMES } from '@/constants/route-name';

import dayjs from '@/plugins/dayjs';

import { getLocalStorage } from '@/utils/utils';

import {
	IS_MOBILE,
	SET_IS_MOBILE,
	mapGettersOfLayout,
	mapMutationsOfLayout,
	IS_SHOW_ALERT_LAYOUT,
	IS_SHOW_GLOBAL_NOTICE_ALERT,
	SET_IS_SHOW_GLOBAL_NOTICE_ALERT,
	IS_SHOW_KPC_HEADER,
	SET_IS_SHOW_KPC_HEADER,
	IS_SHOW_HEADER,
	IS_TRANSPARENT_TYPE,
	BACKWARD_BUTTON_MARGIN_BOTTOM,
	IS_LOGO_TOP_BAR,
	IS_SHOW_SUB_HEADER,
	IS_SHOW_FOOTER,
} from '@/store/layout';
import {
	ALERT_SHOW_MODAL,
	ALERT_BUTTON_COLOR,
	ALERT_SUCCESS_MESSAGE,
	ALERT_PERSISTENT,
	ALERT_CONTENT,
	ALERT_CLOSE_MODAL,
	mapGettersOfAlertModal,
	mapMutationsOfAlertModal,
} from '@/store/modal/alert';

import FooterLayout from '@/components/layouts/FooterLayout.vue';
import HeaderWrapper from '@/components/layouts/HeaderWrapper.vue';

import appCommandMixin from '@/mixins/appCommandMixin';
import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';

import NuxtLayout from '@/layouts/NuxtLayout.vue';

const APP_LINK_MODAL_STORAGE_KEY = 'app-link-modal';

export default {
	name: 'Default',
	mixins: [routeMixin, layoutMixin, appCommandMixin],
	data() {
		return {
			isAppLinkModalVisible: false,
			modalComponent: null,
			storageKey: APP_LINK_MODAL_STORAGE_KEY,
		};
	},
	computed: {
		...mapGetters([
			'metaTitle',
			'metaIsDetail',
			'metaFluid',
			'metaBgColor',
			'hasJwt',
			'isKPC',
			'userNo',
			'userId',
			'isUser',
		]),
		...mapGettersOfLayout([
			IS_MOBILE,
			IS_SHOW_ALERT_LAYOUT,
			IS_SHOW_GLOBAL_NOTICE_ALERT,
			IS_SHOW_KPC_HEADER,
			IS_SHOW_HEADER,
			IS_TRANSPARENT_TYPE,
			BACKWARD_BUTTON_MARGIN_BOTTOM,
			IS_LOGO_TOP_BAR,
			IS_SHOW_SUB_HEADER,
			IS_SHOW_FOOTER,
		]),
		...mapGetters({
			showErrorModal: 'error/showErrorModal',
			errorModalTitle: 'error/errorModalTitle',
			errorModalContent: 'error/errorModalContent',
		}),
		...mapGettersOfAlertModal([
			ALERT_SHOW_MODAL,
			ALERT_BUTTON_COLOR,
			ALERT_SUCCESS_MESSAGE,
			ALERT_PERSISTENT,
			ALERT_CONTENT,
		]),
		hackleUserInfo() {
			return {
				userId: this.userNo,
			};
		},
		isClassroom() {
			return this.$route.path.startsWith(CLASSROOM_PATH);
		},
	},
	created() {
		this.hackleInstance = createInstance(this.$config.hackleApiKey, {
			// debug: process.env.NODE_ENV !== 'production',
			debug: false,
			devTool: HackleDevTools,
			autoOpenDevTool: process.env.NODE_ENV !== 'production' || this.userId?.endsWith('@comento.kr'),
		});
	},
	mounted() {
		this.setAlertLayout();
		window.addEventListener('resize', this.handleResize);
		this.$nextTick(() => {
			if (this.IS_APP) {
				this.$_app_setMeta({
					path: this.$route.fullPath,
					isDetail: this.metaIsDetail,
					bgColor: this.metaBgColor,
					title: this.metaTitle,
					backwardButtonMarginBottom: this.BACKWARD_BUTTON_MARGIN_BOTTOM,
					isLogoTopBar: this.IS_LOGO_TOP_BAR,
					// 기존 meta isShowSubHeader를 다음 조건식으로 변경
					isShowSubHeader: !(this.metaIsDetail || this.IS_LOGO_TOP_BAR),
					isShowFooter: this.IS_SHOW_FOOTER,
					isTransparentType: this.IS_TRANSPARENT_TYPE,
					isShowHeader: this.IS_SHOW_HEADER,
				});
			}
		});
		this.initializeBraze();
		this.checkRouteAndOpenAppLinkModal();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		...mapMutationsOfAlertModal([ALERT_CLOSE_MODAL]),
		...mapMutationsOfLayout([SET_IS_MOBILE, SET_IS_SHOW_KPC_HEADER, SET_IS_SHOW_GLOBAL_NOTICE_ALERT]),
		handleCloseErrorModal() {
			this.$store.commit('error/closeErrorModal');
		},
		handleAlertModalClose() {
			this[ALERT_CLOSE_MODAL]();
		},
		handleResize() {
			this[SET_IS_MOBILE](window.innerWidth <= 768);
		},
		setAlertLayout() {
			this[SET_IS_SHOW_KPC_HEADER](this.isKPC && !this.isClassroom);
			// this[SET_IS_SHOW_KPC_HEADER](true);
			// this[SET_IS_SHOW_GLOBAL_NOTICE_ALERT](true);
		},
		initializeBraze() {
			if (process.env.NODE_ENV !== 'production') {
				return;
			}

			import('@braze/web-sdk')
				.then(({ initialize, getUser, changeUser }) => {
					initialize(this.$config.brazeApiKey, {
						baseUrl: this.$config.brazeApiEndpoint,
						enableLogging: process.env.NODE_ENV === 'local',
					});
					const brazeUserId = getUser().getUserId();
					const shouldChangeUser = this.isUser && brazeUserId !== this.userNo.toString();
					if (shouldChangeUser) {
						changeUser(this.userNo.toString());
					}
				})
				.catch(e => {
					console.error('Braze SDK error', e);
				});
		},
		checkRouteAndOpenAppLinkModal() {
			const closeForTodayStorage = getLocalStorage(APP_LINK_MODAL_STORAGE_KEY);
			const isClosedForToday = dayjs().isSame(dayjs(closeForTodayStorage), 'day');

			if (this.IS_APP || this.IS_PC || isClosedForToday) {
				return;
			}

			const { name } = this.$route;
			const routesToMatch = [
				ROUTE_NAMES.CASE_LIST,
				ROUTE_NAMES.READ_CASE,
				ROUTE_NAMES.ANSWER,
				ROUTE_NAMES.VOD,
				ROUTE_NAMES.CAREER_RECRUIT,
			];

			if (routesToMatch.includes(name)) {
				this.modalComponent = () => import('@/layouts/AppLinkModal.vue');
				this.isAppLinkModalVisible = true;
			}
		},
	},
	head() {
		const { path, name } = this.$route;
		if ([ROUTE_NAMES.CLASS_PT_DETAIL, ROUTE_NAMES.CLASS_PT_DETAIL_OLD].includes(name)) {
			// 실무PT VED는 해당 page.vue에서 canonical 처리함
			return null;
		}
		return {
			link: [
				{ rel: 'canonical', href: `https://comento.kr${path}` },
				// https://www.filamentgroup.com/lab/load-css-simpler/ 참고
				{
					rel: 'stylesheet',
					type: 'text/css',
					href: '//cdn.comento.kr/tinymce/comento_content.css',
					media: 'print',
					onload: 'this.media = "all"',
				},
			],
		};
	},
	components: {
		HeaderWrapper,
		FooterLayout,
		NuxtLayout,
		HackleProvider,
	},
};
