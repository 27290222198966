
import { getTimeInAsia } from '@/plugins/dayjs';

import { mapGettersOfMyPage, CREDIT_REFUND_THIS_MONTH, CREDIT_REFUND_NEXT_MONTH } from '@/store/myPage';

import ExchangeCashNoticeModal from '@/components/my-page/shop/detail/ExchangeCashNoticeModal.vue';

import layoutMixin from '@/mixins/layoutMixin';

import Skeleton from '@/src/components/Other/Skeleton.vue';

export default {
	name: 'MyPageCashInfoBoard',
	mixins: [layoutMixin],
	data() {
		return {
			showExchangeCashNoticeModal: false,
		};
	},
	computed: {
		...mapGettersOfMyPage([CREDIT_REFUND_THIS_MONTH, CREDIT_REFUND_NEXT_MONTH]),
		isLoading() {
			return this[CREDIT_REFUND_THIS_MONTH] === null || this[CREDIT_REFUND_NEXT_MONTH] === null;
		},
		refunds() {
			if (this.isLoading) {
				return [];
			}

			return [
				{
					title: '환급 신청 금액',
					amount: this[CREDIT_REFUND_THIS_MONTH] + this[CREDIT_REFUND_NEXT_MONTH],
				},
				{
					title: getTimeInAsia().format('M') + '월 지급 금액',
					amount: this[CREDIT_REFUND_THIS_MONTH],
				},
				{
					title: getTimeInAsia().add(1, 'month').format('M') + '월 지급 금액',
					amount: this[CREDIT_REFUND_NEXT_MONTH],
				},
			];
		},
		myPageCashInfoBoardContainer() {
			return this.IS_MOBILE ? [24, 16, 24, 16] : [0, 0, 32, 0];
		},
		skeletonHeight() {
			return this.IS_MOBILE ? '48px' : '111px';
		},
		hasCashInfo() {
			return this[CREDIT_REFUND_THIS_MONTH] !== null && this[CREDIT_REFUND_NEXT_MONTH] !== null;
		},
	},
	methods: {
		closeExchangeCashNoticeModal() {
			this.showExchangeCashNoticeModal = false;
		},
	},
	components: { ExchangeCashNoticeModal, Skeleton },
};
