import { CLASS_VOD_DETAIL_SAMPLE_PATH, CLASS_VOD_DETAIL_PATH } from '@/constants/paths';

import { SET_IS_VOD_PAYMENT, SET_VOD_PARTNER, SET_VOD_PAYMENT, SET_VOD_TARGET_NARASARANG } from '@/store/vod';

const vodDetailPath = vodNo => `${CLASS_VOD_DETAIL_PATH}/${vodNo}`;
const vodSamplePath = vodNo => `${CLASS_VOD_DETAIL_SAMPLE_PATH}/${vodNo}`;
const detailRoute = 'class-vod-detail-no';
const sampleRoute = 'class-vod-detail-sample-no';

let isRedirecting = false;

export default async function (context) {
	await checkVodAuth(context);
}

export async function checkVodAuth(context) {
	const { $api, route, store, redirect, req } = context;

	const routeName = route.name;
	const vodDetailRoutes = [detailRoute, sampleRoute];
	const originalVodRoute = vodDetailRoutes.find(route => route === routeName);

	if (!originalVodRoute) {
		return;
	}

	const vodNo = +route.params.no;
	if (isRedirecting) {
		isRedirecting = false;
		return;
	}
	if (isNaN(vodNo)) {
		return;
	}

	// 나라사랑 vod 연계 로직
	const referer = req?.headers?.referer;
	const { userId, elProvCorsNum, gCorsAttnId } = route.query;

	if (referer && referer.includes('ext/vod') && userId && elProvCorsNum && gCorsAttnId) {
		try {
			const { data } = await context.$api.vod.postCheckPlayableTarget({
				userId,
				elProvCorsNum,
				gCorsAttnId,
				type: 'mmaacnc',
			});

			const { isDisplay, vod, message } = data;

			if (isDisplay && message === 'ok') {
				store.commit(`vod/${SET_IS_VOD_PAYMENT}`, true);
				store.commit(`vod/${SET_VOD_PAYMENT}`, isDisplay ? vod : 'null');
				store.commit(`vod/${SET_VOD_TARGET_NARASARANG}`, true);
				return;
			} else {
				return redirect(vodSamplePath(vodNo));
			}
		} catch (error) {
			console.error('Error checking playable target:', error);
		}
		return;
	}

	const { data } = await $api.vod.getVodPayment({
		vodNo,
	});
	// 권한 체크
	const canWatch = data.can_watch;
	store.commit(`vod/${SET_IS_VOD_PAYMENT}`, canWatch);
	store.commit(`vod/${SET_VOD_PAYMENT}`, canWatch ? data : null);

	if (canWatch) {
		const isVodFree = data.payment_vod_no_list.find(element => element <= -1);
		const isVodFreeIndex = data.payment.findIndex(element => element.vod_no <= -1);
		const payment = data.payment[isVodFreeIndex];
		if (isVodFree && payment.contract_no > 1) {
			const partner = {
				contract_no: payment.contract_no,
				contract_name: payment.partner_name,
				contract_org: payment.partner_org,
				/*
                    partner_org
                    null - 대학
                    1 - 기관
                    2 - 채용연계형
                    */
			};
			store.commit(`vod/${SET_VOD_PARTNER}`, partner);
		}
	}

	if (originalVodRoute === detailRoute && !canWatch) {
		isRedirecting = true;
		return redirect(vodSamplePath(vodNo));
	}

	if (originalVodRoute === sampleRoute && canWatch) {
		isRedirecting = true;
		return redirect(vodDetailPath(vodNo));
	}

	isRedirecting = false;
}
