
import { GTM_COMMUNITY_AD_PARAMETERS } from '@/constants/community/gtm';

import { cdnImageBaseUrl } from '@/plugins/constants';

import gtmCommunityAdMixin from '@/mixins/gtm/gtmCommunityAdMixin';
import layoutMixin from '@/mixins/layoutMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

export default {
	name: 'NotiAdBanner',
	mixins: [
		layoutMixin,
		userInfoMixin,
		gtmCommunityAdMixin({
			where: GTM_COMMUNITY_AD_PARAMETERS.WHERE.NOTI_LIST,
			product_name: GTM_COMMUNITY_AD_PARAMETERS.PRODUCT_NAME.IMAGE,
			page_depth: null,
			order: 1,
			inventory_number: 1,
			category: null,
			agency_name: null,
			client_name: GTM_COMMUNITY_AD_PARAMETERS.CLIENT_NAME.COMENTO_KDC,
			job_posting_id: GTM_COMMUNITY_AD_PARAMETERS.CLIENT_NAME.COMENTO_KDC + '-230921',
		}),
	],
	computed: {
		imageSrc() {
			return `${cdnImageBaseUrl}/community/ad/noti-list-ad-banner-test.png?date=241211`;
		},
	},
	methods: {
		handleClickNotiAdBanner() {
			this.$_gtmCommunityAdMixin_triggerGTMClickAdBanner();
			this.$emit('click-noti-ad-banner');
		},
	},
};
