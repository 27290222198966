import { ANALYTICS_PATH, ESSAY_TUTORIAL_PATH, REQUEST_ESSAY_PATH, REQUEST_INTERVIEW_PATH } from '@/constants/paths';

import { baseUrl, cdnImageBaseUrl } from '@/plugins/constants';

export const COMMUNITY_NAME = '커뮤니티';
export const SHOULD_FETCH_POST = 'shouldFetchPost';
export const COMMUNITY_LOUNGE_NAME = '자유게시판';
export const COMMUNITY_INSIGHT_NAME = '인사이트';

export const ANSWER_MATCHING_KEYWORD_MAX_COUNT = 5;

const CAREER = 'CAREER';
const INTEREST_DEPARTMENT = 'INTEREST_DEPARTMENT';
const NICKNAME = 'NICKNAME';
const UNIV = 'UNIV';

export const COMMUNITY_FEED_TYPE = {
	CASE: 'case',
	ANSWER: 'bookmarkAnswer',
	COMMENT: 'comment',
	REPLY: 'reply',
};

export const COMMUNITY_UPDATE_PROFILE_STEP = {
	CAREER,
	INTEREST_DEPARTMENT,
	NICKNAME,
	UNIV,
};

export const timelineCoreMenu = [
	{
		title: '자소서 튜토리얼',
		url: `${baseUrl}${ESSAY_TUTORIAL_PATH}`,
		description: '가장 쉽게 쓰는 방법',
		descriptionB: '어떻게 쓸지<br/>막막하다면?',
		where: 'beginTutorial',
		imageSrc: `${cdnImageBaseUrl}/community/essay-tutorial-icon-case-list.svg`,
		compactImageSrc: `${cdnImageBaseUrl}/community/essay-tutorial-icon-case-list-compact.svg`,
	},
	{
		title: 'AI 자소서 분석기',
		url: `${baseUrl}${ANALYTICS_PATH}`,
		description: '역량과 강점이 드러난 자소서 쓰기',
		descriptionB: '직무적합도<br/>알아보기',
		where: 'onboardAItest',
		imageSrc: `${cdnImageBaseUrl}/community/analytics-icon-case-list.svg`,
		compactImageSrc: `${cdnImageBaseUrl}/community/analytics-icon-case-list-compact.svg`,
	},
	{
		title: '자소서 첨삭',
		url: `${baseUrl}${REQUEST_ESSAY_PATH}`,
		description: '자소서, 통과한 사람이 가장 잘 압니다',
		descriptionB: '내 자소서<br/>몇점일까?',
		where: 'visitRequest-ce',
		imageSrc: `${cdnImageBaseUrl}/community/request-essay-icon-case-list.svg`,
		compactImageSrc: `${cdnImageBaseUrl}/community/request-essay-icon-case-list-compact.svg`,
	},
	{
		title: '면접 준비',
		url: `${baseUrl}${REQUEST_INTERVIEW_PATH}`,
		description: '자소서 기반 현직자 면접 가이드',
		descriptionB: '미리 준비해서<br/>합격률 높이기',
		where: 'visitRequest-in',
		imageSrc: `${cdnImageBaseUrl}/community/request-interview-icon-case-list.svg`,
		compactImageSrc: `${cdnImageBaseUrl}/community/request-interview-icon-case-list-compact.svg`,
	},
];
