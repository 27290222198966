
import get from 'lodash/get';
import { mapGetters } from 'vuex';

import { GTM_COMMUNITY_WHERE } from '@/constants/community/gtm';
import { CAREER } from '@/constants/user-meta';

import { convertBooleanToYorN } from '@/utils/utils';

import {
	GET_AD_LIST,
	GET_STACK_TIMELINE_AD_LIST,
	GET_TIMELINE_AD_INDEX_LIST,
	mapGettersOfAdvertisement,
} from '@/store/advertisement';
import { mapMutationsOfCamp, SET_CAMP_CHARM_ITEM } from '@/store/camp';
import {
	CASE_PAGE,
	CASE_SORTING,
	GET_IS_AUTHORITY,
	IS_EMPTY_RECOMMENDED_LIST,
	SET_CASE_LIST_INFO,
	mapGettersOfCase,
	mapMutationsOfCase,
	IS_SORT_LATEST,
	SET_IS_EMPTY_RECOMMENDED_LIST,
} from '@/store/case';
import {
	FETCH_POSTS,
	GET_INFINITE_ID,
	GET_POSTS,
	GET_POSTS_PARAMS,
	mapActionsOfCommunityTimeline,
	mapGettersOfCommunityTimeline,
	mapMutationsOfCommunityTimeline,
	SET_INFINITE_ID,
	SET_POSTS,
	SET_POSTS_PARAMS,
} from '@/store/community/timeline';
import { mapGettersOfUniv, SOGANG_CONTRACT } from '@/store/univ';
import {
	GET_USER_META_CAREER,
	GET_USER_META_INTEREST_DEPARTMENT,
	mapGettersOfUserMeta,
	mapMutationsOfUserMeta,
	SET_USER_META_CAREER,
} from '@/store/userMeta';

import CaseListNoCategoryContentBanner from '@/components/case/list/main/molecules/CaseListNoCategoryContentBanner.vue';
import CaseListNoRelationContentBanner from '@/components/case/list/main/molecules/CaseListNoRelationContentBanner.vue';
import InterestJobsChanger from '@/components/case/list/main/organisms/InterestJobsChanger.vue';
import TimelineCardList from '@/components/case/timeline/molecules/TimelineCardList.vue';
import TimelineRecommendationFeed from '@/components/case/timeline/organisms/TimelineRecommendationFeed.vue';
import LoadingSpinner from '@/components/common/atoms/LoadingSpinner.vue';
import UserMetaModal from '@/components/common/molecules/userMetaModal/UserMetaModal.vue';
import PullToRefresh from '@/components/pull-to/PullToRefresh.vue';
import CommunityCardSkeleton from '@/components/skeleton/CommunityCardSkeleton.vue';

import advertisementExposeMixin from '@/mixins/advertisement/advertisementExposeMixin';
import appCommandMixin from '@/mixins/appCommandMixin';
import caseMixin from '@/mixins/caseMixin';
import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import navigationMixin from '@/mixins/navigationMixin';
import stateForAppMixin from '@/mixins/stateForAppMixin';
import userInfoMixin from '@/mixins/userInfoMixin';
import userMetaModalMixin from '@/mixins/userMetaModalMixin';

export default {
	name: 'CommunityTimeline',
	mixins: [
		layoutMixin,
		gtmTriggerMixin,
		userInfoMixin,
		caseMixin,
		userMetaModalMixin,
		stateForAppMixin,
		appCommandMixin,
		communityGtmTriggerMixin,
		navigationMixin,
		advertisementExposeMixin,
	],
	async fetch() {
		await this.setEduCharm();
	},
	fetchOnServer: false,
	data() {
		return {
			// totalTrades: 0,
			isLoaded: false,
			hasCareer: false, // updateProfile에서 경력을 선택했는지
			showFilterModal: false,
		};
	},
	computed: {
		...mapGetters(['hasJwt', 'isUser']),
		...mapGettersOfCommunityTimeline([GET_INFINITE_ID, GET_POSTS, GET_POSTS_PARAMS]),
		...mapGettersOfUserMeta([GET_USER_META_CAREER, GET_USER_META_INTEREST_DEPARTMENT]),
		...mapGettersOfUniv([SOGANG_CONTRACT]),
		...mapGettersOfCase([GET_IS_AUTHORITY, IS_EMPTY_RECOMMENDED_LIST, CASE_PAGE, CASE_SORTING, IS_SORT_LATEST]),
		...mapGettersOfAdvertisement([GET_STACK_TIMELINE_AD_LIST, GET_AD_LIST, GET_TIMELINE_AD_INDEX_LIST]),
		page() {
			return get(this[GET_POSTS_PARAMS], 'page', 1);
		},
		userInterestDepartment() {
			return this[GET_USER_META_INTEREST_DEPARTMENT];
		},
	},
	watch: {
		userInterestDepartment() {
			this.resetRecommendation();
		},
	},
	async mounted() {
		this.$_resetStackTimelineAdList();
		this.handleCaseListGtm();
		if (!this.$_isRecommend) {
			await this.fetchTimeline();
		}
	},
	methods: {
		...mapActionsOfCommunityTimeline([FETCH_POSTS]),
		...mapMutationsOfCommunityTimeline([SET_POSTS, SET_POSTS_PARAMS, SET_INFINITE_ID]),
		...mapMutationsOfUserMeta([SET_USER_META_CAREER]),
		...mapMutationsOfCamp([SET_CAMP_CHARM_ITEM]),
		...mapMutationsOfCase([SET_CASE_LIST_INFO, SET_IS_EMPTY_RECOMMENDED_LIST]),
		resetTimeline() {
			// this.totalTrades = 0;
			this[SET_POSTS_PARAMS]({ page: 1, per_page: 25 });
			this[SET_POSTS]([]);
			this[SET_INFINITE_ID](new Date().getTime());
			this.isLoaded = false;
		},
		resetRecommendation() {
			this.resetTimeline();
			this[SET_INFINITE_ID](new Date().getTime());
		},
		setGTM(page) {
			// GTM - onboardCaseList
			this.$_onboardCaseList({
				...this.$_userInfoForGtm,
				page_depth: page || this.page,
				category: 'Home',
				authority: convertBooleanToYorN(this[GET_IS_AUTHORITY]),
			});
		},
		async fetchTimeline() {
			try {
				const currentParams = this[GET_POSTS_PARAMS];
				const nextParams = {
					...currentParams,
					page: this.page + 1,
				};
				this[SET_POSTS_PARAMS](nextParams);
				const {
					data: { data },
				} = await this[FETCH_POSTS](currentParams);

				const hasData = data.data.length > 0;
				if (!hasData) {
					return false;
				}

				await this.$_loadTimelineAdList(data.data, currentParams.page);

				return true;
			} catch (error) {
				console.error(error);
				return false;
			} finally {
				this.isLoaded = true;
			}
		},
		async infiniteHandler($state) {
			if (this.$_isRecommend) {
				await this.recommendationInfiniteHandler($state);
			} else {
				const isLoaded = await this.fetchTimeline();
				return isLoaded ? $state.loaded() : $state.complete();
			}
		},
		async refresh(loaded) {
			if (this.$_isRecommend) {
				this.resetRecommendation();
				loaded('done');
			} else {
				this.resetTimeline();
				await this.fetchTimeline();
				loaded('done');
			}
		},
		async fetchUserMetaCareer() {
			try {
				return await this.$api.userMeta.getUserMetaInfo({
					info_parameter_name: CAREER,
				});
			} catch (error) {
				console.error(error);
				return {};
			}
		},
		handleClickCaseCard() {
			this.clickCaseCardGTM();
		},
		clickCaseCardGTM() {
			this.$_clickCaseCard({
				...this.$_userInfoForGtm,
				category: 'Home',
				authority: convertBooleanToYorN(this[GET_IS_AUTHORITY]),
				where: GTM_COMMUNITY_WHERE.ON_BOARD_CASE_LIST,
			});
		},
		async fetchRecommendation() {
			const { hasNext } = await this.fetchFilteredRecommendation();
			return hasNext;
		},
		async fetchFilteredRecommendation() {
			try {
				const jobs = this.userInterestDepartment;
				if (jobs.length === 0) {
					return;
				}

				const currentParams = {
					page: this.page,
					limit: 16,
					job: jobs.join('|'),
				};
				const nextParams = {
					...currentParams,
					page: this.page + 1,
				};
				this[SET_POSTS_PARAMS](nextParams);

				const { data: response } = await this.$api.timeline.fetchFilteredFeed(currentParams);
				const { data, links } = response;
				await this.$_loadTimelineAdList(data, currentParams.page);
				return { feeds: data, hasNext: !!links.next };
			} catch (error) {
				console.error(error);
				return [];
			} finally {
				this.isLoaded = true;
			}
		},
		async handleCaseListGtm() {
			try {
				const {
					data: { b2b_user, contract_no },
				} = await this.$api.profile.getValidInfo();
				this.$_onboardCaseList({
					user_no: this.userNo,
					user_type: this.userType,
					sort_type: this[CASE_SORTING],
					page_depth: this[CASE_PAGE],
					category: 'Home',
					authority: convertBooleanToYorN(this[GET_IS_AUTHORITY]),
					b2b_user,
					contract_no,
				});
			} catch (error) {
				console.error('handleTriggerGTMOnboardCaseList error : ', error);
			}
		},
		setEmptyRecommendedList() {
			if (!this[IS_SORT_LATEST]) {
				// 추천사례 없음
				this[SET_IS_EMPTY_RECOMMENDED_LIST](true);
			}
		},
		async recommendationInfiniteHandler($state) {
			try {
				const hasNextPage = await this.fetchRecommendation();
				const currentData = this.GET_POSTS;
				if (currentData.length > 0) {
					$state.loaded();
				}

				if (!hasNextPage) {
					$state.complete();
				}
			} catch {
				$state.complete();
			}
		},
		openFilterModal() {
			this.showFilterModal = true;
		},
		async getEduCharm() {
			const params = { product_type: 'edu' };

			try {
				const {
					data: { info, link },
				} = await this.$api.camp.getEduCharm(params);
				return { ...info, link };
			} catch (error) {
				console.error(error);
				return null;
			}
		},
		async setEduCharm() {
			const data = await this.getEduCharm();

			if (data) {
				this[SET_CAMP_CHARM_ITEM]({ ...data });
			}
		},
	},
	components: {
		TimelineRecommendationFeed,
		UserMetaModal,
		LoadingSpinner,
		PullToRefresh,
		TimelineCardList,
		CaseListNoCategoryContentBanner,
		CaseListNoRelationContentBanner,
		CommunityCardSkeleton,
		InterestJobsChanger,
	},
};
