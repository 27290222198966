import { render, staticRenderFns } from "./CaseListBannerSection.vue?vue&type=template&id=453ac094&scoped=true&"
import script from "./CaseListBannerSection.vue?vue&type=script&lang=js&"
export * from "./CaseListBannerSection.vue?vue&type=script&lang=js&"
import style0 from "./CaseListBannerSection.vue?vue&type=style&index=0&id=453ac094&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "453ac094",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaseListNativeAdTestBanner: require('/app/components/case/list/main/atoms/CaseListNativeAdTestBanner.vue').default,CustomNuxtLink: require('/app/components/CustomNuxtLink.vue').default,CaseListEduCampBanner: require('/app/components/case/list/main/molecules/CaseListEduCampBanner.vue').default})
