import { mapGetters } from 'vuex';

import { CLASS_VOD_PATH, VOD_PATH } from '@/constants/paths';
import { MY_PAGE_PATH } from '@/constants/paths/my-page';

import { mapGettersOfLayout, IS_TRANSPARENT_TYPE, IS_MOBILE } from '@/store/layout';

export const TRANSPARENT_TYPE_COLOR_MAP = {
	logo: { color: 'white', opacity: 100 },
	searchIcon: { color: 'white', opacity: 60 },
	gnb: { color: 'white', opacity: 60 },
	menu: { color: 'white', opacity: 60 },
	activeMenu: { color: 'white', opacity: 90 },
	divider: { color: 'gray100', opacity: 10 },
};

export const WHITE_TYPE_COLOR_MAP = {
	logo: { color: 'primary', opacity: 100 },
	searchIcon: { color: 'gray600', opacity: 100 },
	gnb: { color: 'gray500', opacity: 100 },
	menu: { color: 'gray700', opacity: 100 },
	activeMenu: { color: 'gray900', opacity: 100 },
	divider: { color: 'gray200', opacity: 100 },
};

const navigationMixin = {
	computed: {
		...mapGettersOfLayout([IS_TRANSPARENT_TYPE, IS_MOBILE]),
		...mapGetters(['isUser']),
		$_isIndex() {
			const { name } = this.$route;
			return name === 'index';
		},
		$_isCommunityMenu() {
			const { name } = this.$route;
			const pathnames = ['job-questions-keyword', 'answer', 'community-lounge', 'community-insight'];
			return pathnames.includes(name);
		},
		$_isCommunityTimeline() {
			return this.$_isCommunityMenu && this.$route.query.feed === undefined;
		},
		$_isAnswer() {
			return this.$route.name === 'answer';
		},
		$_isRecommend() {
			return this.$_isCommunityTimeline && this.isUser;
		},
		$_isClassMenu() {
			return this.$_isClassHome || this.$_isEduMenu || this.$_isPathMenu || this.$_isVodMenu;
		},
		$_isClassHome() {
			return this.$route.name === 'class';
		},
		$_isEduMenu() {
			const { name } = this.$route;
			return name.startsWith('edu') && !this.$_isPathMenu;
		},
		$_isEduDetail() {
			const { path } = this.$route;
			return path.includes('learn');
		},
		$_isPathMenu() {
			const { path } = this.$route;
			return path.startsWith('/edu/path');
		},
		$_isVodMenu() {
			const { path } = this.$route;
			return path.startsWith(VOD_PATH) || path.startsWith(CLASS_VOD_PATH);
		},
		$_isVodDetail() {
			const { path } = this.$route;
			return path.includes(`${VOD_PATH}/lecture`);
		},
		$_isClassVodDetail() {
			const { path } = this.$route;
			return path.includes(`${CLASS_VOD_PATH}/detail`);
		},
		$_isCareerMenu() {
			const { path } = this.$route;
			return path.startsWith('/career/externship');
		},
		$_isBookmark() {
			const { name } = this.$route;
			return name === 'bookmark';
		},
		$_isMyPage() {
			const { path } = this.$route;
			return path.includes(MY_PAGE_PATH);
		},
		$_colorMap() {
			return this[IS_TRANSPARENT_TYPE] ? TRANSPARENT_TYPE_COLOR_MAP : WHITE_TYPE_COLOR_MAP;
		},
		$_getClickLogoUrl() {
			if (this.$_isEduDetail) {
				return '/edu';
			}
			if (this.$_isVodDetail || this.$_isClassVodDetail) {
				return '/vod';
			}
			return '/?index';
		},
	},

	methods: {
		$_getNavigationColor(property) {
			return this.$_getNavigationColorOrOpacity(property, 'color');
		},
		$_getNavigationOpacity(property) {
			return { opacity: this.$_getNavigationColorOrOpacity(property, 'opacity') / 100 };
		},
		$_getNavigationColorOrOpacity(property, type) {
			return this.$_colorMap[property][type];
		},
	},
};

export default navigationMixin;
