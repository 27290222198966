import { render, staticRenderFns } from "./CommunityTimeline.vue?vue&type=template&id=295e5654&scoped=true&"
import script from "./CommunityTimeline.vue?vue&type=script&lang=js&"
export * from "./CommunityTimeline.vue?vue&type=script&lang=js&"
import style0 from "./CommunityTimeline.vue?vue&type=style&index=0&id=295e5654&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "295e5654",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaseListNoRelationContentBanner: require('/app/components/case/list/main/molecules/CaseListNoRelationContentBanner.vue').default,CaseListNoCategoryContentBanner: require('/app/components/case/list/main/molecules/CaseListNoCategoryContentBanner.vue').default,PullToRefresh: require('/app/components/pull-to/PullToRefresh.vue').default})
