
import {
	ComentoHeader,
	EduListFilterActionButton,
	TOP_BAR_TYPES,
	SearchFullscreenModal,
} from '@comento/common-components';
import { useVariation } from '@comento/hackle-sdk';
import { datadogRum } from '@datadog/browser-rum';
import { watch, ref } from 'vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';

import { getExperimentKeyById } from '@/constants/experiment';
import { ROUTE_NAMES } from '@/constants/route-name';

import { baseUrl } from '@/plugins/constants';

import { moveToLaravelPage, debounce, isEmptyData } from '@/utils/utils';

import {
	IS_SHOW_HEADER,
	IS_SHOW_SUB_HEADER,
	mapGettersOfLayout,
	IS_SHOW_ALERT_LAYOUT,
	IS_TRANSPARENT_TYPE,
	IS_TRANSPARENT_TYPE_PAGE,
	mapMutationsOfLayout,
	SET_IS_TRANSPARENT_TYPE,
	SET_IS_OPEN_NOTI_DRAWER,
	IS_LOGO_TOP_BAR,
	TOP_BAR_BUTTONS,
} from '@/store/layout';
import {
	mapActionsOfUserBookmark,
	LOAD_BOOKMARK_COUNT,
	mapGettersOfUserBookmark,
	IS_RELOAD_BOOKMARK_COUNT,
} from '@/store/user/bookmark';

import NotiWrapper from '@/components/common/molecules/noti/NotiWrapper.vue';
import EduListFilterFullScreenModal from '@/components/edu/list/molecules/EduListFilterFullScreenModal.vue';

import appCommandMixin from '@/mixins/appCommandMixin';
import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import navigationMixin from '@/mixins/navigationMixin';
import routeMixin from '@/mixins/routeMixin';
import searchMixin from '@/mixins/searchMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

export default {
	name: 'HeaderLayout',
	mixins: [
		navigationMixin,
		layoutMixin,
		appCommandMixin,
		gtmTriggerMixin,
		searchMixin,
		routeMixin,
		userInfoMixin,
		communityGtmTriggerMixin,
	],
	setup() {
		const variation = useVariation(getExperimentKeyById('VO045'));
		const kdigitalMenuName = ref('국비지원');

		watch(
			variation,
			newVal => {
				kdigitalMenuName.value = getMenuName(newVal);
			},
			{ immediate: true },
		);

		function getMenuName(variation) {
			switch (variation) {
				case 'B':
					return '실무스킬';
				case 'C':
					return '직무스킬';
				case 'D':
					return '실무역량';
				default:
					return '국비지원';
			}
		}

		return {
			variation,
			kdigitalMenuName,
		};
	},
	async fetch() {
		await Promise.allSettled([
			this.getRecentKeywords(),
			this.fetchPopularKeywords(),
			this.getUserProfileImage(),
			this.getNotiCount(),
			this.getBookmarkCount(),
		]);
	},
	fetchOnServer: false,
	data() {
		return {
			notiCount: 0,
			bookmarkCount: 0,
			showNoti: false,
			showBookmark: false,
			isAppear: false,
			recentKeywords: [],
			autocompleteList: [],
			showSearchFullscreenModal: false,
			showEduListFilterModal: false,
		};
	},
	computed: {
		...mapGetters([
			'isUser',
			'userNickname',
			'metaIsDetail',
			'userProfileImage',
			'metaTitle',
			'userId',
			'userType',
		]),
		...mapGettersOfLayout([
			IS_SHOW_SUB_HEADER,
			IS_SHOW_ALERT_LAYOUT,
			IS_TRANSPARENT_TYPE,
			IS_TRANSPARENT_TYPE_PAGE,
			IS_SHOW_HEADER,
			IS_LOGO_TOP_BAR,
			TOP_BAR_BUTTONS,
		]),
		...mapGettersOfUserBookmark([IS_RELOAD_BOOKMARK_COUNT]),
		headerUserInfo() {
			return {
				id: this.userId,
				name: this.userNickname,
				profileImage: this.userProfileImage,
			};
		},
		popularKeywords() {
			return this.popularKeywordList.map(({ keyword }) => keyword);
		},
		isSearch() {
			return this.$route.name === ROUTE_NAMES.SEARCH_LIST;
		},
		isEduList() {
			return this.$route.name === ROUTE_NAMES.EDU_LIST;
		},
		topBarType() {
			if (this[IS_LOGO_TOP_BAR]) {
				return TOP_BAR_TYPES.LOGO;
			}
			return this.metaIsDetail ? TOP_BAR_TYPES.BACKWARD : TOP_BAR_TYPES.DEFAULT;
		},
		noSubHeader() {
			return this.metaIsDetail || (this.IS_MOBILE && this.IS_LOGO_TOP_BAR);
		},
		marginBottomForTransparent() {
			if (!this.IS_TRANSPARENT_TYPE_PAGE) {
				return {};
			}

			const headerHeight = this.IS_MOBILE ? 52 : 69;
			const subHeaderHeight = this.noSubHeader ? 0 : 43;

			const marginBottom = `-${headerHeight + subHeaderHeight}px`;

			return { marginBottom: this.IS_TRANSPARENT_TYPE ? '0' : marginBottom };
		},
		hasEduEtcFilter() {
			return this.$route.query.day || this.$route.query.endDate;
		},
	},
	watch: {
		searchKeyword() {
			this.debouncedAutocompleteList(this.searchKeyword);
		},
		[IS_RELOAD_BOOKMARK_COUNT]() {
			if (this[IS_RELOAD_BOOKMARK_COUNT]) {
				this.getBookmarkCount();
			}
		},
	},
	created() {
		this.baseUrl = baseUrl;
		this.initDatadogUserSession();
	},
	mounted() {
		this.toggleTransparentType(this.IS_TRANSPARENT_TYPE_PAGE);
		window.addEventListener('scroll', this.onScroll);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll);
	},
	methods: {
		...mapMutations(['setUserInfo', 'setJwt']),
		...mapActions(['getUserProfileImage']),
		...mapMutationsOfLayout([SET_IS_TRANSPARENT_TYPE, SET_IS_OPEN_NOTI_DRAWER]),
		...mapActionsOfUserBookmark([LOAD_BOOKMARK_COUNT]),
		moveToLaravelPage,
		async getRecentKeywords() {
			if (!this.isUser) {
				return;
			}
			const { data } = await this.$axios.get('/api/log/latest');
			this.recentKeywords = data;
		},
		async fetchPopularKeywords() {
			this.popularKeywordList = await this.$_getPopularKeywordList();
		},
		async getAutocompleteList(autocompleteKeyword) {
			try {
				if (!autocompleteKeyword) {
					return [];
				}
				const { data } = await this.$axios.get(`/api/search/auto?keyword=${encodeURI(autocompleteKeyword)}`);
				return data.list || [];
			} catch (error) {
				console.error(error);
				return [];
			}
		},
		debouncedAutocompleteList: debounce(async function (autocompleteKeyword) {
			this.autocompleteList = await this.getAutocompleteList(autocompleteKeyword);
		}, 200),
		async getBookmarkCount() {
			try {
				if (!this.isUser) {
					return;
				}
				this.bookmarkCount = await this[LOAD_BOOKMARK_COUNT]();
			} catch (error) {
				console.error(error);
				this.bookmarkCount = 0;
			}
		},
		async getNotiCount() {
			try {
				if (!this.isUser) {
					return;
				}

				const {
					data: { noti_count },
				} = await this.$api.noti.getNotiList({ page: 1 });
				this.notiCount = noti_count;
			} catch (error) {
				console.error(error);
				this.notiCount = 0;
			}
		},
		openNoti() {
			if (!this.isUser) {
				this.$_routeMixin_href(`/login?redirect_url=${this.$route.fullPath}`);
				return;
			}

			if (this.IS_APP && !this.metaIsDetail) {
				this.$_app_setUI({ all: false });
			}
			this.$_gtm({
				user_no: this.userNo,
				user_type: this.userType,
				email: this.userId,
				event: 'clickNotification',
			});
			this.showNoti = true;
			if (!this.IS_MOBILE) {
				this[SET_IS_OPEN_NOTI_DRAWER](true);
			}
		},
		closeNoti() {
			if (this.IS_APP && !this.metaIsDetail) {
				this.$_app_setUI({ all: true });
			}
			this.showNoti = false;
			setTimeout(() => {
				if (!this.IS_MOBILE) {
					this[SET_IS_OPEN_NOTI_DRAWER](false);
				}
			}, 300);
		},
		toggleTransparentType(transparentType = false) {
			this[SET_IS_TRANSPARENT_TYPE](this.IS_TRANSPARENT_TYPE_PAGE && transparentType);
		},
		resetNotiCount() {
			this.notiCount = 0;
		},
		async signOut() {
			this.resetUserRelatedData();
			await this.callLogoutApi();
			location.replace(`${baseUrl}/logout`);
		},
		resetUserRelatedData() {
			this.setUserInfo(null);
			this.setJwt(null);
		},
		async callLogoutApi() {
			try {
				await this.$axios.get('/api/user/jwt/logout');
			} catch (error) {
				console.error(error);
			}
		},
		async searchEvent({ keyword, searchType }) {
			await this.$_triggerSearch(keyword, searchType);
			this.getRecentKeywords();
		},
		async removeRecentKeyword({ index }) {
			const originalKeywords = this.recentKeywords;
			try {
				// UI에서 일단 바로 삭제하고 나중에 서버랑 동기화
				this.recentKeywords = this.recentKeywords.filter((_, i) => index !== i);
				const { data } = await this.$axios.post('/api/log/keyword-delete', { index });
				this.recentKeywords = data;
			} catch (error) {
				this.recentKeywords = originalKeywords;
			}
		},
		onScroll() {
			requestAnimationFrame(() => {
				this[SET_IS_TRANSPARENT_TYPE](this.IS_TRANSPARENT_TYPE_PAGE && window.scrollY < 1);
			});
		},
		openSearchFullscreenModal() {
			this.showSearchFullscreenModal = true;
		},
		closeSearchFullscreenModal() {
			this.showSearchFullscreenModal = false;
			this.$_app_setUI({ all: true });
		},
		async searchAtFullscreenModal({ keyword, searchType }) {
			await this.searchEvent({ keyword, searchType });
			this.closeSearchFullscreenModal();
			this.autocompleteList = [];
			if (['typing', 'popular'].includes(searchType)) {
				this.$_goSearchPage(keyword);
			}
		},
		showEduListFilter() {
			this.showEduListFilterModal = true;
		},
		/**
		 * 데이터독 RUM 유저정보 세팅
		 */
		initDatadogUserSession() {
			if (isEmptyData(datadogRum.getUser())) {
				datadogRum.setUser({
					id: this.userNo,
					email: this.userId,
					type: this.userType,
				});
			}
		},
	},
	components: {
		SearchFullscreenModal,
		ComentoHeader,
		NotiWrapper,
		// BookmarkFullScreenModal,
		EduListFilterFullScreenModal,
		EduListFilterActionButton,
	},
};
