import _cloneDeep from 'lodash/cloneDeep';

import { EDU_CAMP } from '@/constants/gtm';

import gtmMixin from '@/mixins/gtmMixin';

const gtmTriggerMixin = {
	mixins: [gtmMixin],
	methods: {
		$_insertEduItems(where, params, { group_id }) {
			const copiedParams = _cloneDeep(params);
			if (where === EDU_CAMP) {
				copiedParams.items = {
					id: group_id,
					google_business_vertical: 'education',
				};
			}
			return copiedParams;
		},
		$_VedSevenTimes({
			user_no,
			user_type,
			email,
			camp_id,
			group_id,
			camp_category,
			local_storage_id,
			revenue_amount,
			link,
			where,
		}) {
			const VED_SEVEN_TIMES = 'VedSevenTimes';
			this.$_gtm({
				user_no,
				user_type,
				email,
				camp_id,
				group_id,
				camp_category,
				local_storage_id,
				revenue_amount,
				link,
				where,
				event: VED_SEVEN_TIMES,
			});
			const data = {
				event_name: VED_SEVEN_TIMES,
				add_info: {
					group_no: group_id,
				},
			};
			this.$_requestKakaoPlusFriendApi(data);
		},
		$_requestKakaoPlusFriendApi(data) {
			this.$api.kakaoPlusFriend
				.updateUser(data)
				.then()
				.catch(error => console.error(error));
		},
		$_onboardReviewPage({ user_no, user_type, email }) {
			const ON_BOARD_REVIEW_PAGE = 'onboardReviewPage';
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: ON_BOARD_REVIEW_PAGE,
			});
		},
		// where (리뷰페이지에서 블로그/유튜브/리뷰 중 어떤 것을 누르는지)
		$_clickLinkOnReviewPage({ user_no, user_type, email, where, link }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				link,
				event: 'clickLinkOnReviewPage',
			});
		},
		$_clickEduBanner({
			user_no,
			user_type,
			email,
			banner_type,
			banner_at,
			banner_to,
			camp_id,
			group_id,
			local_storage_id,
			banner_to_detail_title,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				banner_type,
				banner_at,
				banner_to,
				camp_id,
				group_id,
				local_storage_id,
				banner_to_detail_title,
				event: 'clickEduBanner',
			});
		},
		$_clickCampRequestButton({ user_no, user_type, user_id, local_storage_id }) {
			this.$_gtm({
				user_no,
				user_type,
				email: user_id,
				local_storage_id,
				event: 'clickCampRequestButton',
			});
		},
		$_viewEduList({ user_no, user_type, user_id, camp_category, group_id, local_storage_id, link, where }) {
			let params = {
				link,
				user_no,
				user_type,
				email: user_id,
				camp_category,
				group_id,
				local_storage_id,
				where,
				event: 'viewEduList',
			};
			params = this.$_insertEduItems(where, params, { group_id });
			this.$_gtm(params);
		},
		$_viewEduList_B2B({ user_no, user_type, user_id, local_storage_id, link, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email: user_id,
				local_storage_id,
				link,
				where,
				event: 'viewEduList_B2B',
			});
		},
		$_viewEduDetail({
			user_no,
			user_type,
			email,
			camp_id,
			group_id,
			camp_category,
			camp_mid_category,
			camp_bottom_category,
			camp_onoff,
			category,
			industry,
			name,
			revenue_amount,
			link,
			image_link,
			local_storage_id,
			revenue_type,
			items,
			period,
			where,
		}) {
			let params = {
				user_no,
				user_type,
				email,
				camp_id,
				group_id,
				camp_category,
				camp_mid_category,
				camp_bottom_category,
				camp_onoff,
				category,
				industry,
				revenue_amount,
				name,
				link,
				image_link,
				local_storage_id,
				revenue_type,
				items,
				period,
				where,
				event: 'viewEduDetail',
			};
			params = this.$_insertEduItems(where, params, { group_id });
			this.$_gtm(params);
		},
		$_viewCampRequest({ user_no, user_type, user_id, local_storage_id }) {
			this.$_gtm({
				user_no,
				user_type,
				email: user_id,
				local_storage_id,
				event: 'viewCampRequest',
			});
		},
		$_requestChat({ user_no, user_type, local_storage_id }) {
			this.$_gtm({
				user_no,
				user_type,
				local_storage_id,
				event: 'requestChat',
			});
		},
		$_clickViralProgram({ user_no, user_type, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				event: 'clickViralProgram',
			});
		},
		$_onboardViralProgram({ user_no, user_type, email, user_session, user_referrer }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				user_session,
				user_referrer,
				event: 'onboardViralProgram',
			});
		},
		$_clickLinkShare({ user_no, user_type, email, user_session, user_referrer, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				user_session,
				user_referrer,
				where,
				event: 'clickLinkShare',
			});
		},
		$_onboardAlumniPage({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'onboardAlumniPage',
			});
		},
		$_viewAlumniContents({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'viewAlumniContents',
			});
		},
		$_onboardAlumniVODList({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'onboardAlumniVODList',
			});
		},
		$_clickAlumniVOD({ user_no, user_type, email, category, name }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				name,
				event: 'clickAlumniVOD',
			});
		},
		$_visitRequest({ user_no, user_type, user_from_partnership_univ, request_type, where, b2b_user, contract_no }) {
			this.$_gtm({
				user_no,
				user_type,
				user_from_partnership_univ,
				request_type,
				where,
				b2b_user,
				contract_no,
				event: 'visitRequest',
			});
		},
		$_adhocAuto({ user_no, user_type, where }) {
			this.$_gtm({
				user_no,
				user_type,
				where,
				event: 'adhocAuto',
			});
		},
		$_showUpdateProfile({ user_no, user_type, category }) {
			this.$_gtm({
				user_no,
				user_type,
				category,
				event: 'showUpdateProfile',
			});
		},
		$_updateProfile_1({ user_no, user_type, category }) {
			this.$_gtm({
				user_no,
				user_type,
				category,
				event: 'updateProfile_1',
			});
		},
		$_updateProfile_2({ user_no, user_type, category }) {
			this.$_gtm({
				user_no,
				user_type,
				category,
				event: 'updateProfile_2',
			});
		},
		$_updateProfile_3({ user_no, user_type, category }) {
			this.$_gtm({
				user_no,
				user_type,
				category,
				event: 'updateProfile_3',
			});
		},
		$_updateProfile_4({ user_no, user_type, category }) {
			this.$_gtm({
				user_no,
				user_type,
				category,
				event: 'updateProfile_4',
			});
		},
		$_updateProfile_5({ user_no, user_type, category }) {
			this.$_gtm({
				user_no,
				user_type,
				category,
				event: 'updateProfile_5',
			});
		},
		$_updateProfile_6({ user_no, user_type, category }) {
			this.$_gtm({
				user_no,
				user_type,
				category,
				event: 'updateProfile_6',
			});
		},
		$_updateProfile({
			user_no,
			user_type,
			user_career,
			user_university,
			user_major,
			user_companyGroup, // 관심유형
			user_job_topCategory, // 입력한 학교명
			user_nickname,
			user_current_company,
			user_current_job_position,
			user_former_company,
			user_former_job_position,
			user_edu_level,
			user_recent_school,
			user_recent_major,
			user_former_school,
			user_former_major,
			category,
			where,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				user_career,
				user_university,
				user_major,
				user_companyGroup,
				user_job_topCategory,
				user_nickname,
				user_current_company,
				user_current_job_position,
				user_former_company,
				user_former_job_position,
				user_edu_level,
				user_recent_school,
				user_recent_major,
				user_former_school,
				user_former_major,
				category,
				where,
				event: 'updateProfile',
			});
		},
		$_potentialPartnerUser({ user_no, user_type, user_email, user_university, user_major }) {
			this.$_gtm({
				user_no,
				user_type,
				user_email,
				user_university,
				user_major,
				event: 'potentialPartnerUser',
			});
		},
		/**
		 * caseList에 방문했을 때 발동. 데이터를 불러올때마다 발동.
		 * @param user_no
		 * @param user_type
		 * @param sort_type
		 * @param page_depth: parameter page number
		 * @param category
		 * @param {boolean} b2b_user: 현재 유효한 제휴 계약번호를 지닌 유저인지 아닌지 (Y/N)
		 *                            - 이용중인 제휴 계약번호가 없다면 N
		 *                            - 과거에 이용한적 있으나 이미 종료된 계약번호라면 N
		 *                            - 현재 시점에 만료일이 도래하지 않은 계약번호를 지닌 경우라면 Y
		 * @param {number} contract_no: 제휴 계약 번호
		 * @param {string} authority: 현재 유효한 이용권한, 조회권한 Y, N
		 * @param job_filter: 직무 필터
		 * */
		$_onboardCaseList({
			user_no,
			user_type,
			sort_type,
			page_depth,
			category,
			b2b_user,
			contract_no,
			authority,
			job_filter,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				sort_type,
				page_depth,
				category,
				b2b_user,
				contract_no,
				authority,
				job_filter,
				event: 'onboardCaseList',
				version: '2024-09-24',
			});
		},
		$_registerButtonClick({ signup_type, where, local_storage_id }) {
			this.$_gtm({
				signup_type,
				where,
				local_storage_id,
				event: 'registerButtonClick',
			});
		},
		$_clickCaseButton({
			user_no,
			user_type,
			request_no,
			request_company_name,
			request_job_position,
			email,
			where,
			type,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				request_no,
				request_company_name,
				request_job_position,
				email,
				where,
				type,
				event: 'clickCaseButton',
			});
		},
		$_clickLike({
			user_no,
			user_type,
			title,
			request_no,
			request_company_name,
			request_job_position,
			where,
			email,
			category,
			like_type,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				title,
				request_no,
				request_company_name,
				request_job_position,
				where,
				email,
				category,
				like_type,
				event: 'clickLike',
			});
		},
		$_answerLiked({ user_nickname, title, link, user_no, email }) {
			this.$_gtm({
				user_nickname,
				title,
				link,
				user_no,
				email,
				event: 'answerLiked',
			});
		},
		$_leaveReply({ user_no, user_type, request_no, request_type, where, category }) {
			this.$_gtm({
				user_no,
				user_type,
				request_no,
				request_type,
				where,
				category,
				event: 'leaveReply',
			});
		},
		$_newReplytoAnswer({ message, link, user_no, email, title, nickname_replier }) {
			this.$_gtm({
				message,
				link,
				user_no,
				email,
				title,
				nickname_replier,
				event: 'newReplytoAnswer',
			});
		},
		$_readCase({
			user_no,
			user_type,
			search_keyword,
			request_no,
			request_company_name,
			request_job_position,
			number_of_answer,
			mentor_company_name,
			correspondence_company,
			correspondence_industry,
			correspondence_job,
			answer_selected,
			api_partner_no,
			category,
			authority,
			where,
			b2b_user,
			contract_no,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				search_keyword,
				request_no,
				request_company_name,
				request_job_position,
				number_of_answer,
				mentor_company_name,
				correspondence_company,
				correspondence_industry,
				correspondence_job,
				answer_selected,
				api_partner_no,
				category,
				authority,
				where,
				b2b_user,
				contract_no,
				event: 'readCase',
			});
		},
		$_readCase_suggestion({ user_no, user_type, category }) {
			this.$_gtm({
				user_no,
				user_type,
				category,
				event: 'readCase_suggestion',
			});
		},
		$_showPartnerNotice({ user_no, user_type, email, user_university, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				user_university,
				where,
				event: 'showPartnerNotice',
			});
		},
		$_onboardLanding({ user_session, user_referrer }) {
			this.$_gtm({
				user_session,
				user_referrer,
				event: 'onboardLanding',
			});
		},
		$_beginApplyFilter({ user_no, user_type, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				event: 'beginApplyFilter',
			});
		},
		$_selectFilterOption({ user_no, user_type, email, where, category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				category,
				event: 'selectFilterOption',
			});
		},
		$_selectCampCategory({ user_no, user_type, email, where, camp_category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				camp_category,
				event: 'selectCampCategory',
			});
		},
		$_clickApplyFilterButton({ user_no, user_type, email, where, category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				category,
				event: 'clickApplyFilterButton',
			});
		},
		$_addBookMarks({
			user_no,
			user_type,
			email,
			group_id,
			camp_id,
			camp_category,
			camp_mid_category,
			revenue_amount,
			industry,
			local_storage_id,
			category,
			revenue_type,
			where,
			bookmark_at,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				group_id,
				camp_id,
				camp_category,
				camp_mid_category,
				revenue_amount,
				industry,
				local_storage_id,
				category,
				revenue_type,
				where,
				bookmark_at,
				event: 'addBookMarks',
			});
		},
		$_removeBookMarks({
			user_no,
			user_type,
			email,
			group_id,
			camp_id,
			camp_category,
			camp_mid_category,
			revenue_amount,
			industry,
			local_storage_id,
			category,
			revenue_type,
			where,
			bookmark_at,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				group_id,
				camp_id,
				camp_category,
				camp_mid_category,
				revenue_amount,
				industry,
				local_storage_id,
				category,
				revenue_type,
				where,
				bookmark_at,
				event: 'removeBookMarks',
			});
		},
		$_clickFreeSchedule({ user_no, user_type, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				event: 'clickFreeSchedule',
			});
		},
		$_clickFreeScheduleGuide({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'clickFreeScheduleGuide',
			});
		},
		$_onboardBBS({ user_no, user_type, email, category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				event: 'onboardBBS',
			});
		},
		$_readBBSPost({ user_no, user_type, email, category, post_title, post_no }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				post_title,
				post_no,
				event: 'readBBSPost',
			});
		},
		$_clickWriteBBSPost({ user_no, user_type, email, category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				event: 'clickWriteBBSPost',
			});
		},
		$_writeBBSPost({ user_no, user_type, email, category, post_title, post_no }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				post_title,
				post_no,
				event: 'writeBBSPost',
			});
		},
		$_replyBBSPost({ user_no, user_type, email, category, post_title, post_no }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				post_title,
				post_no,
				event: 'replyBBSPost',
			});
		},
		$_clickFloatingButton({ user_no, user_type, email, where, category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				category,
				event: 'clickFloatingButton',
			});
		},
		$_onboardPromotionPage({ user_no, user_type, email, link, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				link,
				where,
				event: 'onboardPromotionPage',
			});
		},
		$_clickPromotionCTA({ user_no, user_type, email, link, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				link,
				where,
				event: 'clickPromotionCTA',
			});
		},
		$_clickPromotionEduCard({ user_no, user_type, email, link, camp_category, title }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				link,
				camp_category,
				title,
				event: 'clickPromotionEduCard',
			});
		},
		$_clickBanner({
			user_no,
			user_type,
			email,
			banner_type,
			banner_at,
			banner_to,
			group_id,
			banner_to_detail_title,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				banner_type,
				banner_at,
				banner_to,
				group_id,
				banner_to_detail_title,
				event: 'clickBanner',
			});
		},
		$_viewEduDetail_B2B({
			user_no,
			user_type,
			email,
			camp_id,
			group_id,
			partner_name,
			local_storage_id,
			camp_category,
			camp_mid_category,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				camp_id,
				group_id,
				partner_name,
				local_storage_id,
				camp_category,
				camp_mid_category,
				event: 'viewEduDetail_B2B',
			});
		},
		$_leaveAnswer({
			user_no,
			user_type,
			email,
			user_level,
			correspondence_company,
			correspondence_industry,
			correspondence_job,
			correspondence_university,
			request_no,
			request_type,
			category,
			where,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				user_level,
				correspondence_company,
				correspondence_industry,
				correspondence_job,
				correspondence_university,
				request_no,
				request_type,
				category,
				where,
				event: 'leaveAnswer',
			});
		},
		/**
		 * 키워드 입력 요구 카드 노출시
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param keyword_count (멘토가 현재 등록해둔 키워드의 개수 (0~5)
		 * @param is_app
		 */
		$_showMatchingKeywordRequest({ user_no, user_type, email, keyword_count, is_app }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				keyword_count,
				is_app,
				event: 'showMatchingKeywordRequest',
			});
		},
		/**
		 * [관심 직무 > 설정] 또는 키워드 입력 요구 카드의 CTA 클릭시 발동
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param where
		 * @param category
		 * @param is_app
		 */
		$_clickMatchingKeywordCTA({ user_no, user_type, email, where, category, is_app }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				category,
				is_app,
				event: 'clickMatchingKeywordCTA',
			});
		},
		/**
		 * 키워드 설정에서 [확인] 누르고 저장시 발동
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param keyword_count
		 * @param is_app
		 */
		$_submitMatchingKeyword({ user_no, user_type, email, keyword_count, is_app }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				keyword_count,
				is_app,
				event: 'submitMatchingKeyword',
			});
		},
		$_onboardExternshipPage({ user_no, user_type, email, user_referrer }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				user_referrer,
				event: 'onboardExternshipPage',
			});
		},
		$_clickExternshipCTA({ user_no, user_type, email, where, type }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				type,
				event: 'clickExternshipCTA',
			});
		},
		$_clickBlogReview({ user_no, user_type, email, camp_id, group_id, camp_category, camp_mid_category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				camp_id,
				group_id,
				camp_category,
				camp_mid_category,
				event: 'clickBlogReview',
			});
		},
		/**
		 * 클릭이벤트가 있는 배너에만 사용
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param where
		 * @param link
		 */
		$_clickEduListBanner({ user_no, user_type, email, where, link }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				link,
				event: 'clickEduListBanner',
			});
		},
		$_clickWebinarBanner({ user_no, user_type, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				event: 'clickWebinarBanner',
			});
		},
		$_clickMentorBanner({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'clickEduBanner',
			});
		},
		$_clickHomeBanner({ user_no, user_type, banner_type, banner_order, category, link }) {
			this.$_gtm({
				user_no,
				user_type,
				banner_type,
				banner_order,
				category,
				link,
				event: 'clickHomeBanner',
			});
		},
		$_clickEmailSubmitButton({ user_no, user_type, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				event: 'clickEmailSubmitButton',
			});
		},
		$_showAppBanner({ user_no, user_type, email, where, authority }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				authority,
				event: 'showAppBanner',
			});
		},
		$_clickAppBanner({ user_no, user_type, email, where, authority }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				authority,
				event: 'clickAppBanner',
			});
		},
		$_clickApplyButton({
			user_no,
			user_type,
			email,
			where,
			camp_id,
			group_id,
			camp_category,
			camp_mid_category,
			industry,
			name,
			user_name,
			optIn,
			type,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				camp_id,
				group_id,
				camp_category,
				camp_mid_category,
				industry,
				name,
				user_name,
				optIn,
				type,
				event: 'clickApplyButton',
			});
		},
		$_clickOptOutButton({ user_no, user_type, email, where, type }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				type,
				event: 'clickOptOutButton',
			});
		},
		$_clickOptInButton({ user_no, user_type, email, where, type }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				type,
				event: 'clickOptInButton',
			});
		},
		$_clickMatchingApplyCTA({ user_no, user_type, email, where, optIn, type }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				optIn,
				type,
				event: 'clickMatchingApplyCTA',
			});
		},
		$_clickClassRoomButton({ user_no, user_type, email, where, category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				category,
				event: 'clickClassRoomButton',
			});
		},
		$_clickClassRoomBanner({ user_no, user_type, email, where, category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				category,
				event: 'clickClassRoomBanner',
			});
		},
		$_writeApplication({
			user_no,
			user_type,
			email,
			link,
			name,
			camp_id,
			group_id,
			camp_category,
			camp_mid_category,
			camp_bottom_category,
			revenue_amount,
			count,
			camp_onoff,
			industry,
			local_storage_id,
			category,
			revenue_type,
			where,
			image_link,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				link,
				name,
				camp_id,
				group_id,
				camp_category,
				camp_mid_category,
				camp_bottom_category,
				revenue_amount,
				count,
				camp_onoff,
				industry,
				local_storage_id,
				category,
				revenue_type,
				where,
				image_link,
				event: 'writeApplication',
			});
		},
		$_selectPG({
			user_no,
			user_type,
			email,
			where,
			camp_id,
			group_id,
			camp_category,
			camp_mid_category,
			camp_bottom_category,
			revenue_amount,
			link,
			camp_onoff,
			category,
			industry,
			revenue_type,
			local_storage_id,
			vod_no,
			image_link,
			name,
			user_name,
			recruit_end,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				where,
				email,
				camp_id,
				group_id,
				camp_category,
				camp_mid_category,
				camp_bottom_category,
				revenue_amount,
				link,
				camp_onoff,
				category,
				industry,
				revenue_type,
				local_storage_id,
				vod_no,
				image_link,
				name,
				user_name,
				recruit_end,
				event: 'selectPG',
			});
		},
		$_loadLMS({ user_no, user_type, email, group_id }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				group_id,
				event: 'loadLMS',
			});
		},
		$_watchVOD({ user_no, user_type, email, group_id, camp_id }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				group_id,
				camp_id,
				event: 'watchVOD',
			});
		},
		$_downloadFiles({ user_no, user_type, email, group_id }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				group_id,
				event: 'downloadFiles',
			});
		},
		$_completeRequest({
			user_no,
			user_type,
			email,
			request_type,
			request_no,
			where,
			category,
			match_type,
			subject,
			b2b_user,
			contract_no,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				request_type,
				request_no,
				where,
				category,
				match_type,
				subject,
				b2b_user,
				contract_no,
				event: 'completeRequest',
			});
		},
		$_showMatchingNotice({ user_no, user_type, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				event: 'showMatchingNotice',
			});
		},
		$_registerResume({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'registerResume',
			});
		},
		$_clickMatchingCTA({ user_no, user_type, email, optIn, count }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				optIn,
				count,
				event: 'clickMatchingCTA',
			});
		},
		$_registerMatching({ user_no, user_type, email, optIn }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				optIn,
				event: 'registerMatching',
			});
		},
		$_draftSaveButtonClick({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'draftSaveButtonClick',
			});
		},
		$_showEduConditionNotice({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'showEduConditionNotice',
			});
		},
		$_clickEduLandingCTA({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'clickEduLandingCTA',
			});
		},
		$_clickInfoButton({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'clickInfoButton',
			});
		},
		$_registerScholarship({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'registerScholarship',
			});
		},
		$_onboardClassRoomMatching({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'onboardClassRoomMatching',
			});
		},
		$_clickClassRoomMatchingCTA({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'clickClassRoomMatchingCTA',
			});
		},
		$_showMatchingHover({ user_no, user_type, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				event: 'showMatchingHover',
			});
		},
		$_showActionToast({ user_no, user_type, email, where, round, partner_name }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				round,
				partner_name,
				event: 'showActionToast',
			});
		},
		$_showCTAButtonOnMyPage({ user_no, user_type, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				event: 'showCTAButtonOnMyPage',
			});
		},
		$_clickActionToastCTA({ user_no, user_type, email, where, round, partner_name }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				round,
				partner_name,
				event: 'clickActionToastCTA',
			});
		},
		$_clickCTAButtonOnMyPage({ user_no, user_type, email, where, click_to }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				click_to,
				event: 'clickCTAButtonOnMyPage',
			});
		},
		$_selectEduToCreate({ user_no, user_type, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				event: 'selectEduToCreate',
			});
		},
		$_onboardClassCreatePage({ user_no, user_type, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				event: 'onboardClassCreatePage',
			});
		},
		$_requestContactInfo({ user_no, user_type, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				event: 'requestContactInfo',
			});
		},
		$_submitContactInfo({ user_no, user_type, user_name, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				user_name,
				email,
				where,
				event: 'submitContactInfo',
			});
		},
		$_inProgressToCreateClass({ user_no, user_type, email, where, step }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				step,
				event: 'inProgressToCreateClass',
			});
		},
		$_submitClassContent({ user_no, user_type, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				event: 'submitClassContent',
			});
		},
		$_clickDetailBanner({ user_no, user_type, email, where, group_id, title }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				group_id,
				title,
				event: 'clickDetailBanner',
			});
		},
		$_clickSaraminBanner({ user_no, user_type, email, banner_type }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				banner_type,
				event: 'clickSaraminBanner',
			});
		},
		$_writeApplication_KDC({
			user_no,
			user_type,
			email,
			link,
			name,
			camp_id,
			group_id,
			camp_category,
			camp_mid_category,
			camp_bottom_category,
			local_storage_id,
			category,
			where,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				link,
				name,
				camp_id,
				group_id,
				camp_category,
				camp_mid_category,
				camp_bottom_category,
				local_storage_id,
				category,
				where,
				event: 'writeApplication_KDC',
			});
		},
		$_submitApplication_KDC({
			user_no,
			user_type,
			email,
			user_name,
			link,
			name,
			camp_id,
			group_id,
			camp_category,
			camp_mid_category,
			camp_bottom_category,
			kdc_type,
			category,
			hrd_link,
			subsidy_amount,
			deposit_amount,
			recruit_end,
			start_date,
			end_date,
			local_storage_id,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				user_name,
				link,
				name,
				camp_id,
				group_id,
				camp_category,
				camp_mid_category,
				camp_bottom_category,
				kdc_type,
				category,
				hrd_link,
				subsidy_amount,
				deposit_amount,
				recruit_end,
				start_date,
				end_date,
				local_storage_id,
				event: 'submitApplication_KDC',
			});
		},
		$_onboardProgress_KDC({
			user_no,
			user_type,
			email,
			link,
			name,
			camp_id,
			group_id,
			camp_category,
			camp_mid_category,
			local_storage_id,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				link,
				name,
				camp_id,
				group_id,
				camp_category,
				camp_mid_category,
				local_storage_id,
				event: 'onboardProgress_KDC',
			});
		},
		$_clickMovingToDetailCTA({ user_no, user_type, email, where, type }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				type,
				event: 'clickMovingToDetailCTA',
			});
		},
		/**
		 * 실무PT 디테일페이지에서 강의계획서 버튼을 클릭하면 발생
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param where
		 * @param camp_id
		 * @param title
		 */
		$_clickCurriculumDetail({ user_no, user_type, email, where, camp_id, title }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				camp_id,
				title,
				event: 'clickCurriculumDetail',
			});
		},
		$_showCreditTip({ user_no, user_type, email, is_app }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				is_app,
				event: 'showCreditTip',
			});
		},
		$_viewRequestList({
			user_no,
			user_type,
			email,
			user_level,
			re_mentoring,
			mentor_company_name,
			mentor_job_position,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				user_level,
				re_mentoring,
				mentor_company_name,
				mentor_job_position,
				event: 'viewRequestList',
			});
		},
		$_showGPTNotYet({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'showGPTNotYet',
			});
		},
		$_onboardClassLanding({ user_no, user_type, email, local_storage_id, is_app }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				local_storage_id,
				is_app,
				event: 'onboardClassLanding',
			});
		},
		$_clickClassCard({ user_no, user_type, email, category, is_app }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				is_app,
				event: 'clickClassCard',
			});
		},
		$_clickClassBanner({ user_no, user_type, email, where, link, banner_order }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				link,
				banner_order,
				event: 'clickClassBanner',
			});
		},
		/**
		 * 검색어 입력 후 엔터를 눌러서 검색 실행 시 발생
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param search_keyword
		 * @param local_storage_id
		 * @param where
		 * @param type
		 */
		$_searchContent({ user_no, user_type, email, search_keyword, local_storage_id, where, type }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				search_keyword,
				local_storage_id,
				where,
				type,
				event: 'searchContent',
			});
		},
		/**
		 * 통합검색 랜딩 시 발생
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param search_keyword
		 * @param where
		 * @param search_order
		 */
		$_viewSearchContent({ user_no, user_type, email, search_keyword, where, search_order }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				search_keyword,
				where,
				search_order,
				event: 'viewSearchContent',
			});
		},
		/**
		 * 검색결과의 detail로 가는 카드를 클릭하면 발생
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param search_keyword
		 * @param where
		 */
		$_clickSearchCard({ user_no, user_type, email, search_keyword, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				search_keyword,
				where,
				event: 'clickSearchCard',
			});
		},
		/**
		 * 검색결과의 detail로 가는 카드를 클릭하면 발생
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param search_keyword
		 * @param where
		 * @param correspondence_company
		 */ $_clickSearchCard_community({ user_no, user_type, email, search_keyword, where, correspondence_company }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				search_keyword,
				where,
				correspondence_company,
				event: 'clickSearchCard_community',
			});
		},
		/**
		 * 각 검색 서브헤더 페이지 랜딩 시 발생
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param search_keyword
		 * @param where
		 * @param page_depth
		 */
		$_viewSearchProduct({ user_no, user_type, email, search_keyword, where, page_depth }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				search_keyword,
				where,
				page_depth,
				event: 'viewSearchProduct',
			});
		},
		/**
		 * VED7 모달을 보여줌
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_showVedSevenNotice({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'showVedSevenNotice',
			});
		},
		$_clickVedSevenModalCTA({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'clickVedSevenModalCTA',
			});
		},
		$_showBlogEventNotice({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'showBlogEventNotice',
			});
		},
		$_clickBlogEventCTA({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'clickBlogEventCTA',
			});
		},
		$_onboardB2Bpage({ user_no, user_type, email, user_referrer, link, where, partner_name, round }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				user_referrer,
				link,
				where,
				partner_name,
				round,
				event: 'onboardB2Bpage',
			});
		},
		$_clickB2BpageCTA({ user_no, user_type, email, link, where, type, partner_name, round }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				link,
				where,
				type,
				partner_name,
				round,
				event: 'clickB2BpageCTA',
			});
		},
		$_downEduDiploma({
			user_no,
			user_type,
			email,
			where,
			camp_id,
			group_id,
			camp_category,
			camp_mid_category,
			name,
			membership,
			revenue_type,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				camp_id,
				group_id,
				camp_category,
				camp_mid_category,
				name,
				membership,
				revenue_type,
				event: 'downEduDiploma',
			});
		},
		$_showThanksCreditNotiA({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'showThanksCreditNoti_A',
			});
		},
		$_showThanksCreditNotiB({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'showThanksCreditNoti_B',
			});
		},
		$_clickFAQGuide({ user_no, user_type, email, group_id, title, where }) {
			this.$_gtm({ user_no, user_type, email, group_id, title, where, event: 'clickFAQGuide' });
		},
		$_eduSectionImpression({ user_no, user_type, email, category, contract_no, partner_name }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				contract_no,
				partner_name,
				event: 'eduSectionImpression',
			});
		},
		$_clickEduBanner_B2B({
			user_no,
			user_type,
			email,
			contract_no,
			partner_name,
			group_id,
			banner_to_detail_title,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				contract_no,
				partner_name,
				group_id,
				banner_to_detail_title,
				event: 'clickEduBanner_B2B',
			});
		},
		/**
		 * 고용부 드림버스 등 채용 헤더 제품 리스트페이지 랜딩시 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param local_storage_id
		 * @param link
		 * @param where
		 */
		$_viewCareerList({ user_no, user_type, email, local_storage_id, link, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				local_storage_id,
				link,
				where,
				event: 'viewCareerList',
			});
		},
		/**
		 * 고용부 일경험 리스트 페이지에서 직무부트캠프 랜딩으로 유입되는 CTA를 클릭했을 때 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param type
		 * @param link
		 */
		$_clickDreamverseToEduCTA({ user_no, user_type, email, type, link }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				type,
				link,
				event: 'clickDreamverseToEduCTA',
			});
		},
		$_onboardPTLanding_B2B({ user_no, user_type, email, user_referrer }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				user_referrer,
				event: 'onboardPTLanding_B2B',
			});
		},
		$_clickPTLandingCTA_B2B({ user_no, user_type, email, where, type }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				type,
				event: 'clickPTLandingCTA_B2B',
			});
		},
		/**
		 * 커뮤니티에서 직무부트캠프 도메인으로 데리고 오는 CTA 요소에 대한 클릭시 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param {string} where
		 * @param {string} category
		 * @param {string} type
		 * @param {string} link
		 */
		$_clickCommunityToEduCTA({ user_no, user_type, email, where, category, type, link }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				category,
				type,
				link,
				event: 'clickCommunityToEduCTA',
			});
		},
		/**
		 * 고용부 일경험 리스트 페이지에 출력되는 CTA 버튼을 클릭시 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param {string} type
		 * @param {string} link
		 */
		$_clickDreamverseListCTA({ user_no, user_type, email, type, link }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				type,
				link,
				event: 'clickDreamverseListCTA',
			});
		},
		/**
		 * VOD 재생 시 발동
		 * https://comento.agit.io/g/300315030/wall/339061118
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param title
		 */
		$_watchJobVOD({ user_no, user_type, email, title }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				title,
				event: 'watchJobVOD',
			});
		},
		/**
		 * 교육상품 리스트 페이지에 랜딩했을 때 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param local_storage_id
		 * @param link
		 * @param where
		 */
		$_viewEduList_Path({ user_no, user_type, email, local_storage_id, link, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				local_storage_id,
				link,
				where,
				event: 'viewEduList',
			});
		},
		/**
		 * 교육상품 상세 페이지에 랜딩했을 때 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param camp_id
		 * @param group_id
		 * @param link
		 * @param image_link
		 * @param local_storage_id
		 * @param camp_category
		 * @param camp_mid_category
		 * @param where
		 */
		$_viewEduDetail_Path({
			user_no,
			user_type,
			email,
			camp_id,
			group_id,
			link,
			image_link,
			local_storage_id,
			camp_category,
			camp_mid_category,
			where,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				camp_id,
				group_id,
				link,
				image_link,
				local_storage_id,
				camp_category,
				camp_mid_category,
				where,
				event: 'viewEduDetail',
			});
		},
		/**
		 * 진로부트캠프 상세페이지에서 캠프를 추가하여 '캠프가 추가되었습니다' toast 출력시 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param camp_id
		 * @param group_id
		 * @param camp_category
		 * @param camp_mid_category
		 * @param industry
		 * @param where
		 * @param contract_no
		 * @param partner_name
		 */
		$_pathCampAdded({
			user_no,
			user_type,
			email,
			camp_id,
			group_id,
			camp_category,
			camp_mid_category,
			industry,
			where,
			contract_no,
			partner_name,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				camp_id,
				group_id,
				camp_category,
				camp_mid_category,
				industry,
				where,
				contract_no,
				partner_name,
				event: 'pathCampAdded',
			});
		},
		/**
		 * 진로부트캠프 ver.2에서 내부 클래스룸에 랜딩했을 때 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param camp_id
		 * @param group_id
		 * @param camp_category
		 * @param camp_mid_category
		 * @param industry
		 * @param where
		 * @param industry
		 * @param contract_no
		 * @param partner_name
		 */
		$_onboardInternalClassroom({
			user_no,
			user_type,
			email,
			camp_id,
			group_id,
			camp_category,
			camp_mid_category,
			industry,
			where,
			contract_no,
			partner_name,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				camp_id,
				group_id,
				camp_category,
				camp_mid_category,
				industry,
				where,
				contract_no,
				partner_name,
				event: 'onboardInternalClassroom',
			});
		},
		/**
		 * 진로부트캠프 ver.2에서 내부 클래스룸에t서, 오픈카톡방 입장 배너 클릭 후, '입장하기 'CTA 클릭했을 때 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param camp_id
		 * @param group_id
		 * @param camp_category
		 * @param camp_mid_category
		 * @param industry
		 * @param contract_no
		 * @param partner_name
		 */
		$_enterOpenChatCTAclick({
			user_no,
			user_type,
			email,
			camp_id,
			group_id,
			camp_category,
			camp_mid_category,
			industry,
			contract_no,
			partner_name,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				camp_id,
				group_id,
				camp_category,
				camp_mid_category,
				industry,
				contract_no,
				partner_name,
				event: 'enterOpenChatCTAclick',
			});
		},
		/**
		 * 진로부트캠프 ver.2에서 내부 클래스룸에서, '5일 이내 과제 제출하기' CTA 클릭하여 실무과제 신청이 완료되었을 때 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param camp_id
		 * @param group_id
		 * @param camp_category
		 * @param camp_mid_category
		 * @param industry
		 */
		$_submitPathApplicant({
			user_no,
			user_type,
			email,
			camp_id,
			group_id,
			camp_category,
			camp_mid_category,
			industry,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				camp_id,
				group_id,
				camp_category,
				camp_mid_category,
				industry,
				event: 'submitPathApplicant',
			});
		},
		/**
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param search_keyword
		 */
		$_groupA({ user_no, user_type, email, search_keyword }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				search_keyword,
				event: 'groupA',
			});
		},
		/**
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param search_keyword
		 */
		$_groupB({ user_no, user_type, email, search_keyword }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				search_keyword,
				event: 'groupB',
			});
		},
		/**
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_onboardJobDescriptionList({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'onboardJobDescriptionList',
			});
		},
		/**
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param post_title
		 * @param company
		 * @param company_type
		 */
		$_viewJobDescriptionDetail({ user_no, user_type, email, post_title, company, company_type }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				post_title,
				company,
				company_type,
				event: 'viewJobDescriptionDetail',
			});
		},
		/**
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param post_title
		 * @param company
		 * @param company_type
		 * @param link
		 */
		$_clickJobDescriptionCTA({ user_no, user_type, email, post_title, company, company_type, link }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				post_title,
				company,
				company_type,
				link,
				event: 'clickJobDescriptionCTA',
			});
		},
		/**
		 * "면접"을 포함한 키워드로 커뮤니티 검색 시 보여주는 toast 노출 시 발동됨.
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param where
		 * @param click_to
		 */
		$_showToast({ user_no, user_type, email, where, click_to }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				click_to,
				event: 'showToast',
			});
		},
		/**
		 * 일경험 튜토리얼 대문 페이지 랜딩시 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_onboardWorkTutorial({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'onboardWorkTutorial',
			});
		},
		/**
		 * 일경험 튜토리얼 디테일 페이지 랜딩시 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_onboardWorkTutorialDetail({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'onboardWorkTutorialDetail',
			});
		},
		$_onboardClassroomLanding({ user_no, user_type, email, type, period, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				type,
				period,
				where,
				event: 'onboardClassroomLanding',
			});
		},
		$_clickTutorialCTA({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'clickTutorialCTA',
			});
		},
		$_playAlumniVOD({ user_no, user_type, email, category, name }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				name,
				event: 'playAlumniVOD',
			});
		},
		/**
		 * 클래스룸 내에서 일경험 온보딩 모달이 출력됐을 때 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_showOnboardModal({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'showOnboardModal',
			});
		},
		/**
		 * 클래스룸 일경험 온보딩 모달 내 각 체크박스 요소를 클릭했을 때 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param where (checkbox 출력 순서를 내림차순으로 호출)
		 * @param link (checkbox 클릭시 랜딩되는 URL을 호출)
		 */
		$_clickOnboardCheckbox({ user_no, user_type, email, where, link }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				link,
				event: 'clickOnboardCheckbox',
			});
		},
		/**
		 * 직무부트캠프 클래스룸에서 멘티가 중간업무보고 제출을 위한 디테일 페이지에 랜딩했을 때 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_onboardInterimReport({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'onboardInterimReport',
			});
		},
		/**
		 * 멘티가 중간업무보고 제출을 위한 디테일 페이지에서 중간업무보고가 정상 제출 완료됐을 때 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_submitInterimReport({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'submitInterimReport',
			});
		},
		/**
		 * 직무부트캠프 클래스룸에서 멘티가 업무요청서 디테일 페이지에 랜딩하면 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_onboardReportRequest({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'onboardReportRequest',
			});
		},
		/**
		 * 직무부트캠프 클래스룸에서 멘티가 업무요청서 디테일 페이지 내의 CTA 클릭시 trigger
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param type
		 */
		$_downloadReferences({ user_no, user_type, email, type }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				type,
				event: 'downloadReferences',
			});
		},
		$_selectVODCategory({ user_no, user_type, email, where, vod_category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				vod_category,
				event: 'selectVODCategory',
			});
		},
		$_clickShare({
			user_no,
			user_type,
			email,
			membership_type,
			request_no,
			request_company_name,
			request_job_position,
			category,
			is_mine,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				membership_type,
				request_no,
				request_company_name,
				request_job_position,
				category,
				is_mine,
				event: 'clickShare',
			});
		},
		$_completeShare({
			user_no,
			user_type,
			email,
			membership_type,
			request_no,
			request_company_name,
			request_job_position,
			category,
			is_mine,
			link_type,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				membership_type,
				request_no,
				request_company_name,
				request_job_position,
				category,
				is_mine,
				link_type,
				event: 'completeShare',
			});
		},
		$_clickFreeClassCTA({ name, group_id }) {
			this.$_gtm({
				name,
				group_id,
				event: 'clickFreeClassCTA',
			});
		},
		$_clickClassroomCallout({ user_no, user_type, email, where, link }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				link,
				event: 'clickClassroomCallout',
			});
		},
		$_startZoomLive({ where, name, camp_id, group_id, link, period, zoom_link }) {
			this.$_gtm({
				where,
				name,
				camp_id,
				group_id,
				link,
				period,
				zoom_link,
				event: 'startZoomLive',
			});
		},
		$_requestScheduleChange({ camp_id, group_id, name, week, primary_schedule, permute_schedule }) {
			this.$_gtm({
				camp_id,
				group_id,
				name,
				week,
				primary_schedule,
				permute_schedule,
				event: 'requestScheduleChange',
			});
		},
		/**
		 * 진로부트캠프 클래스룸에서 skip 아이콘 클릭시 trigger
		 */
		$_clickSkipCTA({ user_no, user_type, contract_no, partner_name, email }) {
			this.$_gtm({
				user_no,
				user_type,
				contract_no,
				partner_name,
				email,
				event: 'clickSkipCTA',
			});
		},
		/**
		 * 진로부트캠프  클래스룸에서 콜아웃 클릭시 trigger
		 */
		$_clickPathCallout({ user_no, user_type, contract_no, partner_name, email }) {
			this.$_gtm({
				user_no,
				user_type,
				contract_no,
				partner_name,
				email,
				event: 'clickPathCallout',
			});
		},
		/**
		 * 진로부트캠프 "확인했어요" gtm trigger
		 */
		$_submitPathAssignment({ user_no, user_type, contract_no, partner_name, email, camp_id, title }) {
			this.$_gtm({
				user_no,
				user_type,
				contract_no,
				partner_name,
				email,
				camp_id,
				title,
				event: 'submitPathAssignment',
			});
		},
		/**
		 * 직무부트캠프 클래스룸 수강생 업무(과제) 페이지에서, 리드멘토가 제출된 멘티의 과제 리스트 페이지를 클릭했을 때 trigger (user_type =2 일때만 trigger)
		 */
		$_clickAssignmentList({ user_no, user_type, email, status, type, group_id, camp_id, week_no, name }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				status,
				type,
				group_id,
				camp_id,
				week_no,
				name,
				event: 'clickAssignmentList',
			});
		},
		/**
		 * 직무부트캠프 클래스룸에서, 리드멘토가 제출된 멘티의 과제 디테일 페이지에 랜딩했을 때 trigger (user_type =2 일때만 trigger)
		 */
		$_onboardAssignmentDetail({ user_no, user_type, email, status, type, group_id, camp_id, week_no, name }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				status,
				type,
				group_id,
				camp_id,
				week_no,
				name,
				event: 'onboardAssignmentDetail',
			});
		},
		/**
		 * 직무부트캠프 클래스룸에서, 리드멘토가 피드백을 작성하여 정상 제출 완료되었을 때 trigger (user_type =2 일때만 trigger)
		 */
		$_submitAssignmentFeedback({
			user_no,
			user_type,
			email,
			status,
			type,
			group_id,
			camp_id,
			week_no,
			name,
			count,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				status,
				type,
				group_id,
				camp_id,
				week_no,
				name,
				count,
				event: 'submitAssignmentFeedback',
			});
		},
		/**
		 * ** 직무부트캠프 클래스룸에서, 리드멘토가 피드백 작성 페이지 에디터 피드백이 실제 불러오기가 동작했을 때 trigger
		 */
		$_loadFeedbackTemplate({ user_no, user_type, email, type, group_id, camp_id, week_no, name }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				type,
				group_id,
				camp_id,
				week_no,
				name,
				event: 'loadFeedbackTemplate',
			});
		},
		$_clickCommunityToEdu_B2BList({ user_no, user_type, email, where, type, contractNo, partner_name, link }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				type,
				contractNo,
				partner_name,
				link,
				event: 'clickCommunityToEdu_B2BList',
			});
		},
		$_clickCommunityToEdu_B2BDetail({ user_no, user_type, email, where, type, contractNo, partner_name, link }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				type,
				contractNo,
				partner_name,
				link,
				event: 'clickCommunityToEdu_B2BDetail',
			});
		},
		$_clickApplyCTA_B2B({
			user_no,
			user_type,
			email,
			camp_id,
			group_id,
			partner_name,
			camp_category,
			camp_mid_category,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				camp_id,
				group_id,
				partner_name,
				camp_category,
				camp_mid_category,
				event: 'clickApplyCTA_B2B',
			});
		},
		$_clickInterimReportCTA({ user_no, user_type, email, group_id, camp_id, week_no, name }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				group_id,
				camp_id,
				week_no,
				name,
				event: 'clickInterimReportCTA',
			});
		},
		$_showRecruitModal({ user_no, user_type, email, where, camp_category, partner_name, link }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				camp_category,
				partner_name,
				link,
				event: 'showRecruitModal',
			});
		},
		$_clickRecruitModalCTA({ user_no, user_type, email, where, camp_category, partner_name, link }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				camp_category,
				partner_name,
				link,
				event: 'clickRecruitModalCTA',
			});
		},
	},
};

export default gtmTriggerMixin;
