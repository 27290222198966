
import { getCdnImagePath } from '@/utils/cdn';

export default {
	name: 'ExchangeCashNoticeModalContent',
	data() {
		return {
			noticeContent: [
				{
					title: '환급 예정일은 11일 입니다',
					description: '11일이 휴일이라면, 다음 영업일에 지급됩니다',
					image: 'illust-calendar.png',
				},
				{
					title: '지급되는 현금은 전월 1일부터 말일까지의<br />신청 금액입니다',
					image: 'illust-cashBag.png',
				},
				{
					title: '소득세법에 따라 기타소득 8.8%가 공제됩니다',
					image: 'illust-calculator.png',
				},
			],
		};
	},
	methods: {
		getCdnImagePath,
	},
};
