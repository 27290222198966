import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'vod';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfVod, mapMutations as mapMutationsOfVod, mapActions as mapActionsOfVod };

// getters

export const VOD_PARTNER = 'VOD_PARTNER';
export const VOD_PAYMENT = 'VOD_PAYMENT';
export const IS_VOD_PAYMENT = 'IS_VOD_PAYMENT';
export const VOD_DETAIL_NO = 'VOD_DETAIL_NO';
export const VOD_DETAIL_INFO = 'VOD_DETAIL_INFO';
export const VOD_DETAIL_SELECT_TAB = 'VOD_DETAIL_SELECT_TAB';
export const VOD_DETAIL_EDU_INFO = 'VOD_DETAIL_EDU_INFO';
export const VOD_DETAIL_REVIEW_PAGE = 'VOD_DETAIL_REVIEW_PAGE';
export const VOD_DETAIL_REVIEW_SORE = 'VOD_DETAIL_REVIEW_SORE';
export const VOD_TARGET_NARASARANG = 'VOD_TARGET_NARASARANG';

// mutations
export const SET_VOD_PARTNER = 'SET_VOD_PARTNER';
export const SET_VOD_PAYMENT = 'SET_VOD_PAYMENT';
export const SET_IS_VOD_PAYMENT = 'SET_IS_VOD_PAYMENT';
export const SET_VOD_DETAIL_NO = 'SET_VOD_DETAIL_NO';
export const SET_VOD_DETAIL_INFO = 'SET_VOD_DETAIL_INFO';
export const SET_VOD_DETAIL_SELECT_TAB = 'SET_VOD_DETAIL_SELECT_TAB';
export const SET_VOD_DETAIL_EDU_INFO = 'SET_VOD_DETAIL_EDU_INFO';
export const SET_VOD_DETAIL_REVIEW_PAGE = 'SET_VOD_DETAIL_REVIEW_PAGE';
export const SET_VOD_DETAIL_REVIEW_SCORE = 'SET_VOD_DETAIL_REVIEW_SCORE';
export const SET_VOD_TARGET_NARASARANG = 'SET_VOD_TARGET_NARASARANG';

export const state = () => ({
	[VOD_PARTNER]: null,
	[VOD_PAYMENT]: null,
	[IS_VOD_PAYMENT]: null,
	[VOD_DETAIL_NO]: null,
	[VOD_DETAIL_INFO]: null,
	[VOD_DETAIL_SELECT_TAB]: 0,
	[VOD_DETAIL_EDU_INFO]: null,
	[VOD_DETAIL_REVIEW_PAGE]: 1,
	[VOD_DETAIL_REVIEW_SORE]: 0,
	[VOD_TARGET_NARASARANG]: false,
});
export const getters = {
	[VOD_PARTNER]: state => state[VOD_PARTNER],
	[VOD_PAYMENT]: state => state[VOD_PAYMENT],
	[IS_VOD_PAYMENT]: state => state[IS_VOD_PAYMENT],
	[VOD_DETAIL_NO]: state => state[VOD_DETAIL_NO],
	[VOD_DETAIL_INFO]: state => state[VOD_DETAIL_INFO],
	[VOD_DETAIL_EDU_INFO]: state => state[VOD_DETAIL_EDU_INFO],
	[VOD_DETAIL_REVIEW_PAGE]: state => state[VOD_DETAIL_REVIEW_PAGE],
	[VOD_DETAIL_REVIEW_SORE]: state => state[VOD_DETAIL_REVIEW_SORE],
	[VOD_DETAIL_SELECT_TAB]: state => state[VOD_DETAIL_SELECT_TAB],
	[VOD_TARGET_NARASARANG]: state => state[VOD_TARGET_NARASARANG],
};

export const mutations = {
	[SET_VOD_PARTNER]: (state, payload) => {
		state[VOD_PARTNER] = payload;
	},
	[SET_VOD_PAYMENT]: (state, payload) => {
		state[VOD_PAYMENT] = payload;
	},
	[SET_IS_VOD_PAYMENT]: (state, payload) => {
		state[IS_VOD_PAYMENT] = payload;
	},
	[SET_VOD_DETAIL_NO]: (state, payload) => {
		state[VOD_DETAIL_NO] = payload;
	},
	[SET_VOD_DETAIL_INFO]: (state, payload) => {
		state[VOD_DETAIL_INFO] = payload;
	},
	[SET_VOD_DETAIL_SELECT_TAB]: (state, payload) => {
		state[VOD_DETAIL_SELECT_TAB] = payload;
	},
	[SET_VOD_DETAIL_EDU_INFO]: (state, payload) => {
		state[VOD_DETAIL_EDU_INFO] = payload;
	},
	[SET_VOD_DETAIL_REVIEW_PAGE]: (state, payload) => {
		state[VOD_DETAIL_REVIEW_PAGE] = payload;
	},
	[SET_VOD_DETAIL_REVIEW_SCORE]: (state, payload) => {
		state[VOD_DETAIL_REVIEW_SORE] = payload;
	},
	[SET_VOD_TARGET_NARASARANG]: (state, payload) => {
		state[VOD_TARGET_NARASARANG] = payload;
	},
};
