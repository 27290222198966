import axios from 'axios';

import { baseUrl, isLocal } from '@/plugins/constants';

const PREFIX = '/baserow';
const nuxtBaseUrl = baseUrl + (isLocal ? ':8000' : '');
axios.defaults.baseURL = nuxtBaseUrl;

export default () => ({
	getSeSACList(params) {
		return axios.get(`${PREFIX}/class/training/list`, { params });
	},
	getSeSACDetail(params) {
		return axios.get(`${PREFIX}/class/training/detail`, { params });
	},
	getDreamverseList(params) {
		return axios.get(`${PREFIX}/dreamverse/list`, { params });
	},
	getDreamverseDetail(params) {
		return axios.get(`${PREFIX}/dreamverse/detail`, { params });
	},
	getDreamverseSectionYoutube(params) {
		return axios.get(`${PREFIX}/dreamverse/detail/section-youtube`, { params });
	},
});
