import get from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';

import { EDU_CAMP_TYPE } from '@/constants/edu/camp-type';

import dayjs, { getTimeInAsia } from '@/plugins/dayjs';

import { isEmptyData } from '@/utils/utils';

const NAMESPACE = 'classroom';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfClassroom,
	mapMutations as mapMutationsOfClassroom,
	mapActions as mapActionsOfClassroom,
};

// states
// 멘토가 줌 강의를 생성했는지
export const IS_ON_AIR = 'IS_ON_AIR';
export const CLASSROOM_CAMP_INFO = 'CLASSROOM_CAMP_INFO';
export const CLASSROOM_SELECTED_TAB = 'CLASSROOM_SELECTED_TAB';
export const FREE_SCHEDULE_ASSIGNMENTS = 'FREE_SCHEDULE_ASSIGNMENTS';
export const USER_START_INFO = 'USER_START_INFO';
export const SHOW_OPEN_CHAT_MODAL = 'SHOW_OPEN_CHAT_MODAL';
export const SHOW_OPEN_CHAT_ALERT = 'SHOW_OPEN_CHAT_ALERT';
export const CLASSROOM_B2B_SURVEY_DATA = 'CLASSROOM_B2B_SURVEY_DATA';
// getters
export const GET_IS_ON_AIR = 'GET_IS_ON_AIR';
export const GET_CLASSROOM_CAMP_INFO = 'GET_CLASSROOM_CAMP_INFO';
export const IS_EDU_FREE_SCHEDULE = 'IS_EDU_FREE_SCHEDULE';
export const GET_CLASSROOM_SELECTED_TAB = 'GET_CLASSROOM_SELECTED_TAB';
export const GET_FREE_SCHEDULE_ASSIGNMENTS = 'GET_FREE_SCHEDULE_ASSIGNMENTS';
export const GET_USER_START_INFO = 'GET_USER_START_INFO';
export const IS_CLASSROOM_TAB_EQUAL_TO = 'IS_CLASSROOM_TAB_EQUAL_TO';
export const GET_SHOW_OPEN_CHAT_MODAL = 'GET_SHOW_OPEN_CHAT_MODAL';
export const GET_SHOW_OPEN_CHAT_ALERT = 'GET_SHOW_OPEN_CHAT_ALERT';
export const GET_CLASSROOM_B2B_SURVEY_DATA = 'GET_CLASSROOM_B2B_SURVEY_DATA';
export const GET_CLASSROOM_B2B_SURVEY_URL = 'GET_CLASSROOM_B2B_SURVEY_URL';
export const CAN_ASSIGNMENT_SUBMIT = 'CAN_ASSIGNMENT_SUBMIT';
export const IS_EDU_PATH_CAMP = 'IS_EDU_PATH_CAMP';
export const IS_EDU_B2C_CAMP = 'IS_EDU_B2C_CAMP';
export const IS_EDU_B2B_CAMP = 'IS_EDU_B2B_CAMP';
export const IS_EDU_VIRTUAL_COMPANY = 'IS_EDU_VIRTUAL_COMPANY';
export const CLASSROOM_ASSIGNMENT_QUESTION_ID = 'CLASSROOM_ASSIGNMENT_QUESTION_ID';
export const IS_ASSIGNMENT_WITH_QUESTION = 'IS_ASSIGNMENT_WITH_QUESTION';
export const IS_EDU_LIVE_CAMP = 'IS_EDU_LIVE_CAMP';
export const IS_EDU_JUMP_UP = 'IS_EDU_JUMP_UP';
export const CLASSROOM_ASSIGNMENT_QUESTION = 'CLASSROOM_ASSIGNMENT_QUESTION';
export const IS_FINISHED_EDU_WORK_EXPERIENCE = 'IS_FINISHED_EDU_WORK_EXPERIENCE';
export const IS_B2B_WEEK3_CAMP = 'IS_B2B_WEEK3_CAMP';

// mutations
export const SET_IS_ON_AIR = 'SET_IS_ON_AIR';
export const SET_CLASSROOM_CAMP_INFO = 'SET_CLASSROOM_CAMP_INFO';
export const SET_CLASSROOM_SELECTED_TAB = 'SET_CLASSROOM_SELECTED_TAB';
export const SET_FREE_SCHEDULE_ASSIGNMENTS = 'SET_FREE_SCHEDULE_ASSIGNMENTS';
export const SET_USER_START_INFO = 'SET_USER_START_INFO';
export const SET_SHOW_OPEN_CHAT_MODAL = 'SET_SHOW_OPEN_CHAT_MODAL';
export const SET_SHOW_OPEN_CHAT_ALERT = 'SET_SHOW_OPEN_CHAT_ALERT';
export const SET_CLASSROOM_B2B_SURVEY_DATA = 'SET_CLASSROOM_B2B_SURVEY_DATA';
export const SET_CLASSROOM_ASSIGNMENT_QUESTION = 'SET_CLASSROOM_ASSIGNMENT_QUESTION';
export const SET_FINISHED_EDU_WORK_EXPERIENCE = 'SET_FINISHED_EDU_WORK_EXPERIENCE';
export const SET_IS_EDU_VIRTUAL_COMPANY = 'SET_IS_EDU_VIRTUAL_COMPANY';
export const SET_IS_EDU_PATH_CAMP = 'SET_IS_EDU_PATH_CAMP';

// actions
export const LOAD_CLASSROOM_CAMP_INFO = 'LOAD_CLASSROOM_CAMP_INFO';
export const LOAD_FREE_SCHEDULE_ASSIGNMENTS = 'LOAD_FREE_SCHEDULE_ASSIGNMENTS';
export const LOAD_CLASSROOM_B2B_SURVEY_DATA = 'LOAD_CLASSROOM_B2B_SURVEY_DATA';
export const LOAD_CLASSROOM_ASSIGNMENT_QUESTION = 'LOAD_CLASSROOM_ASSIGNMENT_QUESTION';
export const LOAD_CHECK_EDU_WORK_EXPERIENCE_QUEST = 'LOAD_CHECK_EDU_WORK_EXPERIENCE_QUEST';

export const state = () => ({
	assignments: {
		items: [],
		totalCount: 0,
		page: 1,
		perPage: 10,
	},
	assignmentsThisWeek: {
		items: [],
		totalCount: 0,
		page: 1,
		perPage: 10,
	},
	assignmentsMine: {
		items: [],
		totalCount: 0,
		page: 1,
		perPage: 1000,
	},
	isMyAssignment: false,
	attendancesMine: {
		list: [],
	},
	feedback: {
		fileList: [],
		content: '',
	},
	replyStreams: {
		items: [],
		totalCount: 0,
		page: 1,
		perPage: 5,
	},
	replyStreamNotReadCount: 0,
	assignmentUnreadCount: 0,
	assignment: null,
	liveClassTabIndex: -1,
	[IS_ON_AIR]: false,
	[CLASSROOM_CAMP_INFO]: null,
	[CLASSROOM_SELECTED_TAB]: null,
	[FREE_SCHEDULE_ASSIGNMENTS]: {
		items: null,
		freeScheduleAssignmentInstructions: null,
		assignmentEtcMaterial: null,
		totalCount: 0,
	},
	[USER_START_INFO]: {
		isNdaConfirmed: false,
		isGuideRead: false,
		isLectureFinished: false,
		vodProgress: -1,
		vodNo: -1,
	},
	[SHOW_OPEN_CHAT_MODAL]: false,
	[SHOW_OPEN_CHAT_ALERT]: false,
	[CLASSROOM_B2B_SURVEY_DATA]: {
		isB2B: false,
		isCoupon: false,
		isFreePass: false,
		surveyUrl: '',
		contractNo: 0,
		partnerName: '',
	},
	classroomAssignmentQuestion: [],
	isFinishedEduWorkExperience: false,
	[IS_EDU_VIRTUAL_COMPANY]: false,
});

export const getters = {
	assignments: state => {
		return state.assignments;
	},
	assignmentsThisWeek: state => {
		return state.assignmentsThisWeek;
	},
	assignmentsThisWeekItems: state => {
		return state.assignmentsThisWeek.items;
	},
	assignmentsMine: state => {
		return state.assignmentsMine;
	},
	assignmentsMineItems: state => {
		return state.assignmentsMine.items;
	},
	isMyAssignment: state => {
		return state.isMyAssignment;
	},
	attendancesMine: state => {
		return state.attendancesMine;
	},
	attendancesMineItems: state => {
		return state.attendancesMine.list;
	},
	feedbackContent: state => {
		return state.feedback.content;
	},
	feedbackFileList: state => {
		return state.feedback.fileList;
	},
	replyStreams: state => {
		return state.replyStreams;
	},
	replyStreamsCount: state => {
		return state.replyStreams.totalCount;
	},
	replyStreamNotReadCount: state => {
		return state.replyStreamNotReadCount;
	},
	assignmentUnreadCount: state => {
		return state.assignmentUnreadCount;
	},
	assignment: state => state.assignment,
	liveClassTabIndex: state => {
		return state.liveClassTabIndex;
	},
	[GET_IS_ON_AIR]: state => state[IS_ON_AIR],
	[GET_CLASSROOM_CAMP_INFO]: state => state[CLASSROOM_CAMP_INFO],
	[GET_CLASSROOM_SELECTED_TAB]: state => state[CLASSROOM_SELECTED_TAB],
	[GET_FREE_SCHEDULE_ASSIGNMENTS]: state => state[FREE_SCHEDULE_ASSIGNMENTS],
	[GET_USER_START_INFO]: state => state[USER_START_INFO],
	[IS_CLASSROOM_TAB_EQUAL_TO]: state => tab => state[CLASSROOM_SELECTED_TAB] === tab,
	[GET_SHOW_OPEN_CHAT_MODAL]: state => state[SHOW_OPEN_CHAT_MODAL],
	[GET_SHOW_OPEN_CHAT_ALERT]: state => state[SHOW_OPEN_CHAT_ALERT],
	[GET_CLASSROOM_B2B_SURVEY_DATA]: state => state[CLASSROOM_B2B_SURVEY_DATA],
	[GET_CLASSROOM_B2B_SURVEY_URL]: state => get(state[CLASSROOM_B2B_SURVEY_DATA], 'surveyUrl', ''),
	[CAN_ASSIGNMENT_SUBMIT]: (state, _getters, rootState) => {
		if (rootState.isEduMentor || !rootState.campInfo) {
			return false;
		}
		// 자율일정일 때
		const isFreeSchedule = rootState.campInfo.type === 'free_schedule';
		if (isFreeSchedule) {
			const expiringDate = rootState.campInfo.applyInfo && rootState.campInfo.applyInfo.expire_at;
			return dayjs(expiringDate).diff(getTimeInAsia(), 'day') > -1;
		}

		const isFreePassCamp = state[CLASSROOM_B2B_SURVEY_DATA].isFreePass;
		if (isFreePassCamp) {
			// B2B 개설형은 마지막 수업일 +10일 동안 과제 제출 가능
			return dayjs(rootState.campInfo.end_date).diff(getTimeInAsia(), 'day') > -10;
		}

		// 5주차 수업일까지 포함
		return dayjs(rootState.campInfo.end_date).diff(getTimeInAsia(), 'day') > -1;
	},
	[IS_EDU_FREE_SCHEDULE]: state => {
		const campInfo = get(state, [CLASSROOM_CAMP_INFO] || state, [GET_CLASSROOM_CAMP_INFO], {});
		if (!campInfo) {
			return false;
		}
		return campInfo.camp_type === EDU_CAMP_TYPE.FREE_SCHEDULE;
	},
	[IS_EDU_PATH_CAMP]: state => {
		const campInfo = get(state, [CLASSROOM_CAMP_INFO], {});
		if (!campInfo) {
			return false;
		}
		return (
			campInfo.camp_type === EDU_CAMP_TYPE.EDU_PATH ||
			campInfo?.edu_camp_group?.type === EDU_CAMP_TYPE.EDU_PATH_2 ||
			campInfo?.variation === '9b'
		);
	},
	[IS_EDU_B2C_CAMP]: state => {
		const campInfo = get(state, [CLASSROOM_CAMP_INFO], {});
		if (!campInfo) {
			return false;
		}
		return campInfo.camp_type === EDU_CAMP_TYPE.B2C_DEFAULT;
	},
	[IS_EDU_B2B_CAMP]: state => {
		const campInfo = get(state, [CLASSROOM_CAMP_INFO], {});
		if (!campInfo) {
			return false;
		}
		return campInfo.camp_type === EDU_CAMP_TYPE.B2B_DEFAULT;
	},
	[IS_EDU_VIRTUAL_COMPANY]: state => {
		const campInfo = get(state, [CLASSROOM_CAMP_INFO], {});
		if (!campInfo) {
			return state[IS_EDU_VIRTUAL_COMPANY];
		}
		return campInfo.camp_type === EDU_CAMP_TYPE.VIRTUAL_COMPANY;
	},
	[IS_EDU_LIVE_CAMP]: (_state, getters) => {
		return getters[IS_EDU_B2C_CAMP] || getters[IS_EDU_B2B_CAMP] || getters[IS_EDU_JUMP_UP];
	},
	[IS_EDU_JUMP_UP]: state => {
		const campInfo = get(state, [CLASSROOM_CAMP_INFO], {});
		return campInfo?.camp_type === EDU_CAMP_TYPE.JUMP_UP;
	},
	[CLASSROOM_ASSIGNMENT_QUESTION_ID]: (_state, getters) => {
		if (getters[IS_ASSIGNMENT_WITH_QUESTION]) {
			return 1;
		}
		// 분기 처리를 위해 기본값으로 두었음
		return 0;
	},
	[IS_ASSIGNMENT_WITH_QUESTION]: (_state, getters) => {
		return (
			getters[IS_EDU_B2C_CAMP] ||
			getters[IS_EDU_B2B_CAMP] ||
			getters[IS_EDU_PATH_CAMP] ||
			getters[IS_EDU_VIRTUAL_COMPANY] ||
			getters[IS_EDU_FREE_SCHEDULE]
		);
	},
	[CLASSROOM_ASSIGNMENT_QUESTION]: state => {
		return state.classroomAssignmentQuestion;
	},
	[IS_FINISHED_EDU_WORK_EXPERIENCE]: state => {
		return state.isFinishedEduWorkExperience;
	},
	[IS_B2B_WEEK3_CAMP]: (_state, getters) => {
		/* 직부캠 B2B 3주 직부캠용 */
		const isWeek3 = getters[GET_CLASSROOM_CAMP_INFO]?.edu_camp_week_count === 3;
		const isB2B = getters[IS_EDU_B2B_CAMP];
		return isWeek3 && isB2B;
	},
};

export const mutations = {
	setAssignments: (state, payload) => {
		state.assignments = payload;
	},
	setAssignmentsItems: (state, payload) => {
		state.assignments.items = payload;
	},
	setAssignmentsPage: (state, payload) => {
		state.assignments.page = payload;
	},
	setAssignmentsThisWeek: (state, payload) => {
		state.assignmentsThisWeek = payload;
	},
	setAssignmentsThisWeekItems: (state, payload) => {
		state.assignmentsThisWeek.items = payload;
	},
	setAssignmentsThisWeekPage: (state, payload) => {
		state.assignmentsThisWeek.page = payload;
	},
	setAssignmentsMine: (state, payload) => {
		state.assignmentsMine = payload;
	},
	setAttendancesMine: (state, payload) => {
		state.attendancesMine = payload;
	},
	setIsMyAssignment: (state, payload) => {
		state.isMyAssignment = payload;
	},
	setFeedbackContent: (state, payload) => {
		state.feedback.content = payload;
	},
	setFeedbackFileList: (state, payload) => {
		state.feedback.fileList = payload;
	},
	setReplyStreams: (state, payload) => {
		state.replyStreams = payload;
	},
	setReplyStreamsItems: (state, payload) => {
		state.replyStreams.items = payload;
	},
	setReplyStreamsPage: (state, payload) => {
		state.replyStreams.page = payload;
	},
	setReplyStreamNotReadCount: (state, payload) => {
		state.replyStreamNotReadCount = payload;
	},
	setAssignmentUnreadCount: (state, payload) => {
		state.assignmentUnreadCount = payload;
	},
	setAssignment: (state, payload) => {
		state.assignment = payload;
	},
	setLiveClassTabIndex: (state, payload) => {
		state.liveClassTabIndex = payload;
	},
	[SET_IS_ON_AIR]: (state, payload) => {
		state[IS_ON_AIR] = payload;
	},
	[SET_CLASSROOM_CAMP_INFO]: (state, payload) => {
		state[CLASSROOM_CAMP_INFO] = payload;
	},
	[SET_CLASSROOM_SELECTED_TAB]: (state, payload) => {
		state[CLASSROOM_SELECTED_TAB] = payload;
	},
	[SET_FREE_SCHEDULE_ASSIGNMENTS]: (state, payload) => {
		state[FREE_SCHEDULE_ASSIGNMENTS] = payload;
	},
	[SET_USER_START_INFO]: (state, payload) => {
		state[USER_START_INFO] = payload;
	},
	[SET_SHOW_OPEN_CHAT_MODAL]: (state, payload) => {
		state[SHOW_OPEN_CHAT_MODAL] = payload;
	},
	[SET_SHOW_OPEN_CHAT_ALERT]: (state, payload) => {
		state[SHOW_OPEN_CHAT_ALERT] = payload;
	},
	[SET_CLASSROOM_B2B_SURVEY_DATA]: (state, payload) => {
		state[CLASSROOM_B2B_SURVEY_DATA] = payload;
	},
	[SET_CLASSROOM_ASSIGNMENT_QUESTION]: (state, payload) => {
		state.classroomAssignmentQuestion = payload;
	},
	[SET_FINISHED_EDU_WORK_EXPERIENCE]: (state, payload) => {
		state.isFinishedEduWorkExperience = payload;
	},
	[SET_IS_EDU_VIRTUAL_COMPANY]: (state, payload) => {
		state[IS_EDU_VIRTUAL_COMPANY] = payload;
	},
	[SET_IS_EDU_PATH_CAMP]: (state, payload) => {
		state[IS_EDU_PATH_CAMP] = payload;
	},
};

export const actions = {
	async LOAD_CLASSROOM_CAMP_INFO({ commit }, campNo) {
		const params = {
			camp_no: campNo,
		};
		return await this.$api.classroom
			.fetchClassroomCampInfo(params)
			.then(({ data }) => {
				commit(SET_CLASSROOM_CAMP_INFO, data);
			})
			.catch(err => {
				console.error('LOAD_CLASSROOM_CAMP_INFO error: ', err);
			});
	},

	async LOAD_FREE_SCHEDULE_ASSIGNMENTS({ commit, state }, camp_no) {
		const { items, free_schedule_assignment_instructions, assignment_etc_material } =
			await this.$api.classroom.getAssignmentCatalog(camp_no);
		commit('SET_FREE_SCHEDULE_ASSIGNMENTS', {
			...state[FREE_SCHEDULE_ASSIGNMENTS],
			items,
			freeScheduleAssignmentInstructions: free_schedule_assignment_instructions,
			assignmentEtcMaterial: assignment_etc_material,
		});
	},

	async LOAD_CLASSROOM_B2B_SURVEY_DATA({ commit }, applicant_no) {
		const surveyInfo = await this.$api.classroom.getB2BApplicantInfo(applicant_no);
		commit(`${SET_CLASSROOM_B2B_SURVEY_DATA}`, surveyInfo);
	},
	async LOAD_CLASSROOM_ASSIGNMENT_QUESTION({ commit }) {
		const { data } = await this.$api.classroom.getAssignmentQuestion();
		if (isEmptyData(data)) {
			return;
		}
		commit(`${SET_CLASSROOM_ASSIGNMENT_QUESTION}`, data);
	},
	async LOAD_CHECK_EDU_WORK_EXPERIENCE_QUEST({ commit }, params) {
		const { data } = await this.$api.classroom.checkEduWorkExperience(params);
		commit(SET_FINISHED_EDU_WORK_EXPERIENCE, data.check);
	},
};
