import { isSelectorValid } from '@/utils/utils';

export default function (to, _from, savedPosition) {
	return new Promise(resolve => {
		setTimeout(async () => {
			const findEl = (hash, x) => {
				return (
					document.querySelector(hash) ||
					new Promise(resolve => {
						if (x > 50) {
							return resolve();
						}
						setTimeout(() => {
							resolve(findEl(hash, ++x || 1));
						}, 100);
					})
				);
			};

			if (to.hash && isSelectorValid(to.hash)) {
				const el = await findEl(to.hash);
				return resolve({ x: 0, y: el ? el.offsetTop : 0 });
			}

			if (savedPosition) {
				resolve(savedPosition);
			}
			// 라우트 이동될 때마다 스크롤 맨위로 이동
			resolve({ x: 0, y: 0 });
		}, 100);
	});
}
