import get from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';

import { COMMUNITY_FEED_TYPE } from '@/constants/community';

import {
	SORT_LATEST,
	ALL_CONTENT_VIEW,
	CASE_CATEGORY_GTM_MAP,
	CASE_CATEGORY_CAREER_CHANGE_GTM,
	CASE_CATEGORY_JOB_SEEKING_GTM,
	CASE_CATEGORY_WORKING_LEVEL_SKILL,
	CASE_CATEGORY_UNIVERSITY_STUDENT_WORRY,
	CASE_CATEGORY_ONLINE_MENTOR,
} from '@/plugins/constants';

import { getBookmarkUpdatedData } from '@/utils/community';
import { isEmptyData } from '@/utils/utils';

const DEFAULT_QUESTION_TYPE_OPTION = { label: ALL_CONTENT_VIEW, value: 0 };

const NAMESPACE = 'case';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfCase, mapMutations as mapMutationsOfCase, mapActions as mapActionsOfCase };

// state
export const CASE_CATEGORY = 'CASE_CATEGORY';
export const SORTING_OPTIONS = 'SORTING_OPTIONS';
export const CASE_LIST = 'CASE_LIST';
export const CASE_LIST_PAGINATION = 'CASE_LIST_PAGINATION';
export const IS_FINISH_RELATION_CONTENT = 'IS_FINISH_RELATION_CONTENT';
export const CASE_CATEGORY_GROUP_ID = 'CASE_CATEGORY_GROUP_ID';
export const CASE_CATEGORY_JOB = 'CASE_CATEGORY_JOB';
export const QUESTION_TYPE_OPTIONS = 'QUESTION_TYPE_OPTIONS';
export const CASE_LIST_INFO = 'CASE_LIST_INFO';
export const CASE_LIST_INFO_PAGINATION = 'CASE_LIST_INFO_PAGINATION';
export const INFINITE_ID = 'INFINITE_ID';
export const FEED_TYPE = 'FEED_TYPE';
export const RECOMMENDED_CAMP_LIST = 'RECOMMENDED_CAMP_LIST';
export const RECOMMENDED_KEYWORD_PT_LIST = 'RECOMMENDED_KEYWORD_PT_LIST';
export const CASE_DETAIL_QUESTION = 'CASE_DETAIL_QUESTION';
export const CASE_DETAIL_QUESTION_AUTHOR_NO = 'CASE_DETAIL_QUESTION_AUTHOR_NO';
export const ANSWER_CONTENT_NO = 'ANSWER_CONTENT_NO';
export const CASE_DETAIL_ANSWER_LIST = 'CASE_DETAIL_ANSWER_LIST';
export const CASE_DETAIL_ANSWER_INFO = 'CASE_DETAIL_ANSWER_INFO';
export const CASE_DETAIL_ANSWER_INFINITE_ID = 'CASE_DETAIL_ANSWER_INFINITE_ID';
export const IS_BUY_MENTORING = 'IS_BUY_MENTORING';
export const BUY_MENTORING_INFO = 'BUY_MENTORING_INFO';
export const IS_EMPTY_RECOMMENDED_LIST = 'IS_EMPTY_RECOMMENDED_LIST';
export const USER_PARTNER_INFO = 'USER_PARTNER_INFO';
export const IS_FETCHING_CASE_LIST = 'IS_FETCHING_CASE_LIST';
export const SHOULD_RESET_CASE_LIST = 'SHOULD_RESET_CASE_LIST';
export const COMMUNITY_BOOKMARK_TOTAL_COUNT = 'COMMUNITY_BOOKMARK_TOTAL_COUNT';
export const COMMUNITY_ANSWER_BOOKMARK_COUNT = 'COMMUNITY_ANSWER_BOOKMARK_COUNT';
export const COMMUNITY_BOOKMARK_LIST = 'COMMUNITY_BOOKMARK_LIST';
export const COMMUNITY_BOOKMARK_LIST_INFO = 'COMMUNITY_BOOKMARK_LIST_INFO';
export const IS_COMMUNITY_BOOKMARKED = 'IS_COMMUNITY_BOOKMARKED';
export const HAS_ANY_TYPE_COMMUNITY_BOOKMARKED = 'HAS_ANY_TYPE_COMMUNITY_BOOKMARKED';
export const IS_FROM_COMMUNITY_BOOKMARK = 'IS_FROM_COMMUNITY_BOOKMARK';
export const IS_AUTHORITY = 'IS_AUTHORITY';

// getters
export const IS_JOB_SEEKING = 'IS_JOB_SEEKING';
export const IS_CAREER_CHANGE = 'IS_CAREER_CHANGE';
export const IS_WORKING_LEVEL_SKILL = 'IS_WORKING_LEVEL_SKILL';
export const IS_UNIVERSITY_STUDENT_WORRY = 'IS_UNIVERSITY_STUDENT_WORRY';
export const IS_ONLINE_MENTOR = 'IS_ONLINE_MENTOR';
export const CASE_SORTING = 'CASE_SORTING';
export const IS_SORT_LATEST = 'IS_SORT_LATEST';
export const CASE_PAGE = 'CASE_PAGE';
export const CASE_PAGE_PAGINATION = 'CASE_PAGE_PAGINATION';

export const CASE_LIMIT = 'CASE_LIMIT';
export const CASE_QUESTION_TYPE_ID = 'CASE_QUESTION_TYPE_ID';
export const IS_UNIV_AFFILIATE_AVAILABLE = 'IS_UNIV_AFFILIATE_AVAILABLE';
export const CASE_GTM_CATEGORY = 'CASE_GTM_CATEGORY';
export const GET_IS_FETCHING_CASE_LIST = 'GET_IS_FETCHING_CASE_LIST';
export const GET_SHOULD_RESET_CASE_LIST = 'GET_SHOULD_RESET_CASE_LIST';
export const IS_FETCHING_CASE_DETAIL_QUESTION = 'IS_FETCHING_CASE_DETAIL_QUESTION';
export const IS_FETCHING_CASE_DETAIL_ANSWER_LIST = 'IS_FETCHING_CASE_DETAIL_ANSWER_LIST';
export const GET_COMMUNITY_BOOKMARK_TOTAL_COUNT = 'GET_COMMUNITY_BOOKMARK_TOTAL_COUNT';
export const GET_COMMUNITY_ANSWER_BOOKMARK_COUNT = 'GET_COMMUNITY_ANSWER_BOOKMARK_COUNT';
export const GET_COMMUNITY_BOOKMARK_LIST = 'GET_COMMUNITY_BOOKMARK_LIST';
export const GET_COMMUNITY_BOOKMARK_LIST_INFO = 'GET_COMMUNITY_BOOKMARK_LIST_INFO';
export const GET_IS_COMMUNITY_BOOKMARKED = 'GET_IS_COMMUNITY_BOOKMARKED';
export const GET_HAS_ANY_TYPE_COMMUNITY_BOOKMARKED = 'GET_HAS_ANY_TYPE_COMMUNITY_BOOKMARKED';
export const GET_REQUEST_NO = 'GET_REQUEST_NO';
export const GET_FEED_TYPE = 'GET_FEED_TYPE';
export const GET_ANSWER_CONTENT_NO = 'GET_ANSWER_CONTENT_NO';
export const GET_IS_FROM_COMMUNITY_BOOKMARK = 'GET_IS_FROM_COMMUNITY_BOOKMARK';
export const GET_IS_DELETED_POST = 'GET_IS_DELETED_POST';
export const GET_IS_FREE_MENTORING = 'GET_IS_FREE_MENTORING';
export const GET_IS_AUTHORITY = 'GET_IS_AUTHORITY';
export const GET_MEMBERSHIP_TYPE = 'GET_MEMBERSHIP_TYPE';
export const IS_SHOW_CASE_SHARE_MODAL = 'IS_SHOW_CASE_SHARE_MODAL';
export const AFFILIATE_UNIV_EXPOSURE_DATA = 'AFFILIATE_UNIV_EXPOSURE_DATA';

// mutations
export const SET_CASE_CATEGORY = 'SET_CASE_CATEGORY';
export const SET_SORTING_OPTIONS = 'SET_SORTING_OPTIONS';
export const SET_CASE_LIST = 'SET_CASE_LIST';
export const SET_CASE_LIST_PAGINATION = 'SET_CASE_LIST_PAGINATION';
export const SET_CASE_CATEGORY_GROUP_ID = 'SET_CASE_CATEGORY_GROUP_ID';
export const SET_CASE_CATEGORY_JOB = 'SET_CASE_CATEGORY_JOB';
export const SET_CASE_LIST_INFO = 'SET_CASE_LIST_INFO';
export const SET_CASE_LIST_INFO_PAGINATION = 'SET_CASE_LIST_INFO_PAGINATION';
export const SET_INFINITE_ID = 'SET_INFINITE_ID';
export const SET_QUESTION_TYPE_OPTIONS = 'SET_QUESTION_TYPE_OPTIONS';
export const SET_RECOMMENDED_CAMP_LIST = 'SET_RECOMMENDED_CAMP_LIST';
export const SET_RECOMMENDED_KEYWORD_PT_LIST = 'SET_RECOMMENDED_KEYWORD_PT_LIST';
export const SET_CASE_DETAIL_QUESTION = 'SET_CASE_DETAIL_QUESTION';
export const SET_CASE_DETAIL_ANSWER_LIST = 'SET_CASE_DETAIL_ANSWER_LIST';
export const SET_CASE_DETAIL_ANSWER = 'SET_CASE_DETAIL_ANSWER';
export const ADD_CASE_DETAIL_ANSWER_LIST = 'ADD_CASE_DETAIL_ANSWER_LIST';
export const SET_QUESTION_LIKE = 'SET_QUESTION_LIKE';
export const SET_ANSWER_LIKE = 'SET_ANSWER_LIKE';
export const SET_COMMENT_LIKE = 'SET_COMMENT_LIKE';
export const SET_REPLY_LIKE = 'SET_REPLY_LIKE';
export const UPDATE_ANSWER_IS_BOOKMARKED = 'UPDATE_ANSWER_IS_BOOKMARKED';
export const SET_ANSWER_CHOOSE = 'SET_ANSWER_CHOOSE';
export const SET_ANSWER_REPLY_FEEDBACK = 'SET_ANSWER_REPLY_FEEDBACK';
export const SET_CASE_DETAIL_ANSWER_INFO = 'SET_CASE_DETAIL_ANSWER_INFO';
export const SET_IS_BUY_MENTORING = 'SET_IS_BUY_MENTORING';
export const SET_BUY_MENTORING_INFO = 'SET_BUY_MENTORING_INFO';
export const SET_IS_EMPTY_RECOMMENDED_LIST = 'SET_IS_EMPTY_RECOMMENDED_LIST';
export const SET_USER_PARTNER_INFO = 'SET_USER_PARTNER_INFO';
export const SET_IS_FETCHING_CASE_LIST = 'SET_IS_FETCHING_CASE_LIST';
export const SET_RESET_CASE_LIST = 'SET_RESET_CASE_LIST';
export const SET_COMMUNITY_BOOKMARK_TOTAL_COUNT = 'SET_COMMUNITY_BOOKMARK_TOTAL_COUNT';
export const SET_COMMUNITY_ANSWER_BOOKMARK_COUNT = 'SET_COMMUNITY_ANSWER_BOOKMARK_COUNT';
export const ADD_COMMUNITY_BOOKMARK_LIST = 'ADD_COMMUNITY_BOOKMARK_LIST';
export const SET_COMMUNITY_BOOKMARK_LIST = 'SET_COMMUNITY_BOOKMARK_LIST';
export const SET_COMMUNITY_BOOKMARK_LIST_INFO = 'SET_COMMUNITY_BOOKMARK_LIST_INFO';
export const SET_IS_COMMUNITY_BOOKMARKED = 'SET_IS_COMMUNITY_BOOKMARKED';
export const SET_HAS_ANY_TYPE_COMMUNITY_BOOKMARKED = 'SET_HAS_ANY_TYPE_COMMUNITY_BOOKMARKED';
export const SET_IS_FROM_COMMUNITY_BOOKMARK = 'SET_IS_FROM_COMMUNITY_BOOKMARK';
export const SET_ANSWER_CONTENT_NO = 'SET_ANSWER_CONTENT_NO';
export const SET_FEED_TYPE = 'SET_FEED_TYPE';
export const SET_CASE_DETAIL_ANSWER_INFINITE_ID = 'SET_CASE_DETAIL_ANSWER_INFINITE_ID';
export const UPDATE_QUESTION_IS_BOOKMARKED = 'UPDATE_QUESTION_IS_BOOKMARKED';
export const UPDATE_COMMENT_IS_BOOKMARKED = 'UPDATE_COMMENT_IS_BOOKMARKED';
export const UPDATE_REPLY_IS_BOOKMARKED = 'UPDATE_REPLY_IS_BOOKMARKED';
export const UPDATE_REPLY_SUCCESS = 'UPDATE_REPLY_SUCCESS';
export const SET_IS_SHOW_CASE_SHARE_MODAL = 'SET_IS_SHOW_CASE_SHARE_MODAL';

export const SET_CASE_PAGE_PAGINATION = 'SET_CASE_PAGE_PAGINATION';
export const SET_AFFILIATE_UNIV_EXPOSURE_DATA = 'AFFILIATE_UNIV_EXPOSURE_DATA';

// actions
export const LOAD_QUESTION_TYPE_OPTIONS = 'LOAD_QUESTION_TYPE_OPTIONS';
export const LOAD_RECOMMENDED_CAMP_LIST = 'LOAD_RECOMMENDED_CAMP_LIST';
export const LOAD_RECOMMENDED_KEYWORD_PT_LIST = 'LOAD_RECOMMENDED_KEYWORD_PT_LIST';
export const LOAD_CASE_LIST = 'LOAD_CASE_LIST';
export const LOAD_CASE_LIST_PAGINATION = 'LOAD_CASE_LIST_PAGINATION';
export const LOAD_USER_PARTNER_INFO = 'LOAD_USER_PARTNER_INFO';
export const RESET_CASE_LIST = 'RESET_CASE_LIST';
export const LOAD_CASE_DETAIL_ANSWER_LIST = 'LOAD_CASE_DETAIL_ANSWER_LIST';
export const RESET_CASE_DETAIL_INFO = 'RESET_CASE_DETAIL_INFO';
export const LOAD_COMMUNITY_BOOKMARK_TOTAL_COUNT = 'LOAD_COMMUNITY_BOOKMARK_TOTAL_COUNT';
export const LOAD_COMMUNITY_ANSWER_BOOKMARK_COUNT = 'LOAD_COMMUNITY_ANSWER_BOOKMARK_COUNT';
export const LOAD_COMMUNITY_BOOKMARK_TOTAL_LIST = 'LOAD_COMMUNITY_BOOKMARK_TOTAL_LIST';
export const LOAD_COMMUNITY_BOOKMARK_LIST = 'LOAD_COMMUNITY_BOOKMARK_LIST';
export const LOAD_COMMUNITY_ANSWER_BOOKMARK_LIST = 'LOAD_COMMUNITY_ANSWER_BOOKMARK_LIST';
export const LOAD_IS_COMMUNITY_BOOKMARKED = 'LOAD_IS_COMMUNITY_BOOKMARKED';
export const LOAD_HAS_ANY_TYPE_COMMUNITY_BOOKMARKED = 'LOAD_HAS_ANY_TYPE_COMMUNITY_BOOKMARKED';
export const LOAD_IS_COMMUNITY_ANSWER_BOOKMARKED = 'LOAD_IS_COMMUNITY_ANSWER_BOOKMARKED';
export const LOAD_IS_COMMUNITY_COMMENT_BOOKMARKED = 'LOAD_IS_COMMUNITY_COMMENT_BOOKMARKED';
export const LOAD_IS_COMMUNITY_REPLY_BOOKMARKED = 'LOAD_IS_COMMUNITY_REPLY_BOOKMARKED';
export const TOGGLE_COMMUNITY_BOOKMARK = 'TOGGLE_COMMUNITY_BOOKMARK';
export const RESET_COMMUNITY_BOOKMARK_LIST = 'RESET_COMMUNITY_BOOKMARK_LIST';
export const LOAD_IS_BUY_MENTORING = 'LOAD_IS_BUY_MENTORING';
export const LOAD_CASE_DETAIL_QUESTION = 'LOAD_CASE_DETAIL_QUESTION';
export const LOAD_BOOKMARK_LIST = 'LOAD_BOOKMARK_LIST';
export const LOAD_AFFILIATE_UNIV_EXPOSURE_DATA = 'LOAD_AFFILIATE_UNIV_EXPOSURE_DATA';

export const state = () => ({
	[CASE_CATEGORY]: null,
	[SORTING_OPTIONS]: [],
	[CASE_LIST]: [],
	[CASE_LIST_PAGINATION]: [],
	[IS_FINISH_RELATION_CONTENT]: true,
	[CASE_CATEGORY_GROUP_ID]: null,
	[CASE_CATEGORY_JOB]: null,
	[QUESTION_TYPE_OPTIONS]: [],
	[CASE_LIST_INFO]: {
		keyword: null,
		count: 0,
		sorting: SORT_LATEST,
		questionTypeId: 0,
		page: 1,
		limit: 10,
	},
	[CASE_PAGE_PAGINATION]: 1,
	[CASE_LIST_INFO_PAGINATION]: {
		count: 0,
	},
	[INFINITE_ID]: +new Date(),
	[RECOMMENDED_CAMP_LIST]: [],
	[ANSWER_CONTENT_NO]: null,
	[CASE_DETAIL_QUESTION]: null,
	[CASE_DETAIL_ANSWER_LIST]: null,
	[CASE_DETAIL_ANSWER_INFO]: {
		page: 1,
		limit: 2,
		count: 0,
		requestNo: -1,
	},
	[CASE_DETAIL_ANSWER_INFINITE_ID]: +new Date(),
	[IS_BUY_MENTORING]: false,
	[BUY_MENTORING_INFO]: {},
	[IS_EMPTY_RECOMMENDED_LIST]: false,
	[USER_PARTNER_INFO]: {
		organizationType: '',
		isAffiliate: false,
		isReAffiliated: false,
		isUserUsingAffiliate: false,
		isUserAffiliatedBefore: false,
	},
	[IS_FETCHING_CASE_LIST]: false,
	[SHOULD_RESET_CASE_LIST]: false,
	[COMMUNITY_BOOKMARK_TOTAL_COUNT]: 0,
	[COMMUNITY_ANSWER_BOOKMARK_COUNT]: 0,
	[COMMUNITY_BOOKMARK_LIST]: null,
	[COMMUNITY_BOOKMARK_LIST_INFO]: {
		page: 1,
		total: 0,
		limit: 10,
	},
	[IS_COMMUNITY_BOOKMARKED]: false,
	[HAS_ANY_TYPE_COMMUNITY_BOOKMARKED]: false,
	[IS_FROM_COMMUNITY_BOOKMARK]: false,
	[FEED_TYPE]: 'case',
	[IS_AUTHORITY]: false,
	isShowCaseShareModal: false,
	[AFFILIATE_UNIV_EXPOSURE_DATA]: {
		contractNo: 0,
		list: '',
		partner: '',
		partnerUrl: '',
		univ: '',
	},
});

export const getters = {
	[CASE_CATEGORY]: state => state[CASE_CATEGORY],
	[IS_CAREER_CHANGE]: (_state, getters) => {
		// 이직고민인지
		return getters[CASE_GTM_CATEGORY] === CASE_CATEGORY_CAREER_CHANGE_GTM;
	},
	[IS_JOB_SEEKING]: (_state, getters) => {
		// 취업고민인지
		return getters[CASE_GTM_CATEGORY] === CASE_CATEGORY_JOB_SEEKING_GTM;
	},
	[IS_WORKING_LEVEL_SKILL]: (_state, getters) => {
		// 실무스킬인지
		return getters[CASE_GTM_CATEGORY] === CASE_CATEGORY_WORKING_LEVEL_SKILL;
	},
	[IS_UNIVERSITY_STUDENT_WORRY]: (_state, getters) => {
		// 대학생 고민인지
		return getters[CASE_GTM_CATEGORY] === CASE_CATEGORY_UNIVERSITY_STUDENT_WORRY.replace(' ', '');
	},
	[IS_ONLINE_MENTOR]: (_state, getters) => {
		// 랜선 사수(직장인 고민)인지
		return getters[CASE_GTM_CATEGORY] === CASE_CATEGORY_ONLINE_MENTOR.replace(' ', '');
	},
	[SORTING_OPTIONS]: state => state[SORTING_OPTIONS],
	[CASE_LIST]: state => state[CASE_LIST],
	[CASE_LIST_PAGINATION]: state => state[CASE_LIST_PAGINATION],
	[IS_FINISH_RELATION_CONTENT]: state => state[IS_FINISH_RELATION_CONTENT],
	/*
	 * CASE_CATEGORY_GROUP_ID
	 * 1: 취업 고민, 2: 이직 고민, 3: 랜선 사수(직장인고민), 4: 대학생 고민, 5: 실무고민(실무스킬)
	 */
	[CASE_CATEGORY_GROUP_ID]: state => state[CASE_CATEGORY_GROUP_ID],
	[CASE_CATEGORY_JOB]: state => state[CASE_CATEGORY_JOB],
	[CASE_LIST_INFO]: state => state[CASE_LIST_INFO],
	[CASE_LIST_INFO_PAGINATION]: state => state[CASE_LIST_INFO_PAGINATION],
	[CASE_SORTING]: state => state[CASE_LIST_INFO].sorting,
	[CASE_PAGE]: state => state[CASE_LIST_INFO].page,
	[CASE_PAGE_PAGINATION]: state => state[CASE_PAGE_PAGINATION],
	[CASE_LIMIT]: state => state[CASE_LIST_INFO].limit,
	[INFINITE_ID]: state => state[INFINITE_ID],
	[CASE_QUESTION_TYPE_ID]: state => state[CASE_LIST_INFO].questionTypeId,
	[QUESTION_TYPE_OPTIONS]: state => state[QUESTION_TYPE_OPTIONS],
	[IS_SORT_LATEST]: state => state[CASE_LIST_INFO].sorting === SORT_LATEST,
	[RECOMMENDED_CAMP_LIST]: state => state[RECOMMENDED_CAMP_LIST],
	[RECOMMENDED_KEYWORD_PT_LIST]: state => state[RECOMMENDED_KEYWORD_PT_LIST],
	[CASE_DETAIL_QUESTION]: state => state[CASE_DETAIL_QUESTION],
	[ANSWER_CONTENT_NO]: state => state[ANSWER_CONTENT_NO],
	[IS_FETCHING_CASE_DETAIL_QUESTION]: state => state[CASE_DETAIL_QUESTION] === null,
	[CASE_DETAIL_QUESTION_AUTHOR_NO]: state => state[CASE_DETAIL_QUESTION].user_no,
	[CASE_DETAIL_ANSWER_LIST]: state => state[CASE_DETAIL_ANSWER_LIST],
	[CASE_DETAIL_ANSWER_INFO]: state => state[CASE_DETAIL_ANSWER_INFO],
	[IS_FETCHING_CASE_DETAIL_ANSWER_LIST]: state => state[CASE_DETAIL_ANSWER_LIST] === null,
	[CASE_DETAIL_ANSWER_INFINITE_ID]: state => state[CASE_DETAIL_ANSWER_INFINITE_ID],
	[IS_BUY_MENTORING]: state => state[IS_BUY_MENTORING],
	[GET_MEMBERSHIP_TYPE]: state => get(state[BUY_MENTORING_INFO], ['itemInfo', 'status'], '').toLowerCase(),
	[IS_EMPTY_RECOMMENDED_LIST]: state => state[IS_EMPTY_RECOMMENDED_LIST],
	[USER_PARTNER_INFO]: state => state[USER_PARTNER_INFO],
	[IS_UNIV_AFFILIATE_AVAILABLE]: state => {
		// 제휴를 이용하고 있지 않고 대학 제휴를 이용할 수 있는 경우
		return (
			!state[USER_PARTNER_INFO].isUserUsingAffiliate &&
			state[USER_PARTNER_INFO].organizationType === 'university' &&
			state[USER_PARTNER_INFO].isAffiliate
		);
	},
	[CASE_GTM_CATEGORY]: state => {
		// gtm에는 띄어쓰기 없이 보내기
		const categoryGroupId =
			state[CASE_CATEGORY_GROUP_ID] ??
			state[CASE_DETAIL_QUESTION]?.mentoring_category.mentoring_category_group_id;
		const category = CASE_CATEGORY_GTM_MAP[categoryGroupId];
		if (isEmptyData(category)) {
			return null;
		}
		return category.replace(/ /gi, '');
	},
	[GET_IS_FETCHING_CASE_LIST]: state => {
		return state[IS_FETCHING_CASE_LIST];
	},
	[GET_SHOULD_RESET_CASE_LIST]: state => {
		return state[SHOULD_RESET_CASE_LIST];
	},
	[GET_COMMUNITY_BOOKMARK_TOTAL_COUNT]: state => {
		return state[COMMUNITY_BOOKMARK_TOTAL_COUNT];
	},
	[GET_COMMUNITY_ANSWER_BOOKMARK_COUNT]: state => {
		return state[COMMUNITY_ANSWER_BOOKMARK_COUNT];
	},
	[GET_COMMUNITY_BOOKMARK_LIST]: state => {
		return state[COMMUNITY_BOOKMARK_LIST];
	},
	[GET_COMMUNITY_BOOKMARK_LIST_INFO]: state => {
		return state[COMMUNITY_BOOKMARK_LIST_INFO];
	},
	[GET_IS_COMMUNITY_BOOKMARKED]: state => {
		return state[IS_COMMUNITY_BOOKMARKED];
	},
	[GET_HAS_ANY_TYPE_COMMUNITY_BOOKMARKED]: state => {
		return state[HAS_ANY_TYPE_COMMUNITY_BOOKMARKED];
	},
	[GET_REQUEST_NO]: state => {
		return state[CASE_DETAIL_QUESTION]?.no;
	},
	[GET_FEED_TYPE]: state => {
		return state[FEED_TYPE];
	},
	[GET_ANSWER_CONTENT_NO]: state => {
		return state[ANSWER_CONTENT_NO];
	},
	[GET_IS_FROM_COMMUNITY_BOOKMARK]: state => {
		return state[IS_FROM_COMMUNITY_BOOKMARK];
	},
	[GET_IS_DELETED_POST]: state => {
		return state[CASE_DETAIL_QUESTION]?.type === 'dl';
	},
	[GET_IS_FREE_MENTORING]: state => {
		return Boolean(state[CASE_DETAIL_QUESTION]?.mentoring_category.is_free);
	},
	[GET_IS_AUTHORITY]: state => {
		// 게시글 혹은 답변 북마크를 했거나, buymentoring값이 있으면 게시물을 권한이 Y
		if (state[GET_HAS_ANY_TYPE_COMMUNITY_BOOKMARKED]) {
			return true;
		}
		if (state[IS_BUY_MENTORING]) {
			return true;
		}
		return false;
	},
	[IS_SHOW_CASE_SHARE_MODAL]: state => {
		return state.isShowCaseShareModal;
	},
	[AFFILIATE_UNIV_EXPOSURE_DATA]: state => {
		return state[AFFILIATE_UNIV_EXPOSURE_DATA];
	},
};

export const mutations = {
	SET_CASE_CATEGORY: (state, payload) => {
		// readCase, 검색에서 store가 끊겨서 sessionStorage 필요
		// setSessionStorage(STATE_CASE_CATEGORY, payload);
		state[CASE_CATEGORY] = payload;
	},
	SET_SORTING_OPTIONS: (state, payload) => {
		state[SORTING_OPTIONS] = payload;
	},
	SET_CASE_LIST: (state, payload) => {
		state[CASE_LIST] = payload;
	},
	SET_CASE_LIST_PAGINATION: (state, payload) => {
		state[CASE_LIST_PAGINATION] = payload;
	},
	SET_CASE_CATEGORY_GROUP_ID: (state, payload) => {
		state[CASE_CATEGORY_GROUP_ID] = payload;
	},
	SET_CASE_CATEGORY_JOB: (state, payload) => {
		state[CASE_CATEGORY_JOB] = payload;
	},
	SET_CASE_LIST_INFO: (state, payload) => {
		state[CASE_LIST_INFO] = { ...state[CASE_LIST_INFO], ...payload };
	},
	SET_CASE_LIST_INFO_PAGINATION: (state, payload) => {
		state[CASE_LIST_INFO_PAGINATION] = { ...state[CASE_LIST_INFO_PAGINATION], ...payload };
	},
	SET_INFINITE_ID: (state, payload) => {
		state[INFINITE_ID] = payload;
	},
	SET_QUESTION_TYPE_OPTIONS: (state, payload) => {
		state[QUESTION_TYPE_OPTIONS] = payload;
	},
	SET_RECOMMENDED_CAMP_LIST: (state, payload) => {
		state[RECOMMENDED_CAMP_LIST] = payload;
	},
	SET_RECOMMENDED_KEYWORD_PT_LIST: (state, payload) => {
		state[RECOMMENDED_KEYWORD_PT_LIST] = payload;
	},
	SET_CASE_DETAIL_QUESTION: (state, payload) => {
		state[CASE_DETAIL_QUESTION] = payload ? { ...state[CASE_DETAIL_QUESTION], ...payload } : null;
	},
	SET_CASE_DETAIL_ANSWER_LIST: (state, payload) => {
		state[CASE_DETAIL_ANSWER_LIST] = payload;
	},
	SET_CASE_DETAIL_ANSWER: (state, { index, payload }) => {
		state[CASE_DETAIL_ANSWER_LIST][index] = payload;
	},
	ADD_CASE_DETAIL_ANSWER_LIST: (state, payload) => {
		const answers = [...(state[CASE_DETAIL_ANSWER_LIST] || []), ...payload];
		// 추가 답변을 달면 0번째에 추가되기 때문에 unique한 no를 판별해서 넣기
		state[CASE_DETAIL_ANSWER_LIST] = [...new Map(answers.map(item => [item.no, item])).values()];
	},
	SET_QUESTION_LIKE: (state, { isLike }) => {
		const copiedQuestion = { ...state[CASE_DETAIL_QUESTION] };
		copiedQuestion.isLike = isLike;
		copiedQuestion.likenum = copiedQuestion.isLike ? copiedQuestion.likenum + 1 : copiedQuestion.likenum - 1;
		state[CASE_DETAIL_QUESTION] = copiedQuestion;
	},
	SET_ANSWER_LIKE: (state, { index, isLike }) => {
		const copiedAnswer = [...state[CASE_DETAIL_ANSWER_LIST]];
		const answer = copiedAnswer[index];
		answer.isLike = isLike;
		answer.likenum = answer.isLike ? answer.likenum + 1 : answer.likenum - 1;
		copiedAnswer[index] = answer;
		state[CASE_DETAIL_ANSWER_LIST] = copiedAnswer;
	},
	SET_COMMENT_LIKE: (state, { isLike, answerIndex, commentIndex }) => {
		const copiedAnswer = [...state[CASE_DETAIL_ANSWER_LIST]];
		const comment = copiedAnswer[answerIndex].reply_feedback[commentIndex];
		comment.isLike = isLike;
		comment.likenum = comment.isLike ? comment.likenum + 1 : comment.likenum - 1;
		copiedAnswer[answerIndex].reply_feedback[commentIndex] = comment;
		state[CASE_DETAIL_ANSWER_LIST] = copiedAnswer;
	},
	SET_REPLY_LIKE: (state, { isLike, answerIndex, commentIndex, replyIndex }) => {
		const copiedAnswer = [...state[CASE_DETAIL_ANSWER_LIST]];
		const reply = copiedAnswer[answerIndex].reply_feedback[commentIndex].reply_comments[replyIndex];
		reply.isLike = isLike;
		reply.likenum = reply.isLike ? reply.likenum + 1 : reply.likenum - 1;
		copiedAnswer[answerIndex].reply_feedback[commentIndex].reply_comments[replyIndex] = reply;
		state[CASE_DETAIL_ANSWER_LIST] = copiedAnswer;
	},
	UPDATE_ANSWER_IS_BOOKMARKED: (state, { index, isBookmarked }) => {
		if (isBookmarked === null) {
			return;
		}
		const copiedAnswer = [...state[CASE_DETAIL_ANSWER_LIST]];
		const answer = copiedAnswer[index];
		answer.bookmarkCount = isBookmarked ? answer.bookmarkCount + 1 : answer.bookmarkCount - 1;
		answer.isBookmarked = isBookmarked;

		state[CASE_DETAIL_ANSWER_LIST] = copiedAnswer;
	},
	SET_ANSWER_CHOOSE: (state, { index, choose }) => {
		const copiedAnswer = [...state[CASE_DETAIL_ANSWER_LIST]];
		const answer = copiedAnswer[index];
		answer.choose = choose;
		copiedAnswer[index] = answer;
		state[CASE_DETAIL_ANSWER_LIST] = copiedAnswer;
	},
	SET_ANSWER_REPLY_FEEDBACK: (state, { index, feedbackItem }) => {
		const copiedAnswer = [...state[CASE_DETAIL_ANSWER_LIST]];
		const answer = copiedAnswer[index];
		answer.reply_feedback.push(feedbackItem);
		copiedAnswer[index] = answer;
		state[CASE_DETAIL_ANSWER_LIST] = copiedAnswer;
	},
	SET_CASE_DETAIL_ANSWER_INFO: (state, payload) => {
		state[CASE_DETAIL_ANSWER_INFO] = { ...state[CASE_DETAIL_ANSWER_INFO], ...payload };
	},
	SET_IS_BUY_MENTORING: (state, payload) => {
		state[IS_BUY_MENTORING] = payload;
	},
	SET_BUY_MENTORING_INFO: (state, payload) => {
		state[BUY_MENTORING_INFO] = payload;
	},
	SET_IS_EMPTY_RECOMMENDED_LIST: (state, payload) => {
		state[IS_EMPTY_RECOMMENDED_LIST] = payload;
	},
	SET_USER_PARTNER_INFO: (state, payload) => {
		state[USER_PARTNER_INFO] = payload;
	},
	SET_IS_FETCHING_CASE_LIST: (state, payload) => {
		state[IS_FETCHING_CASE_LIST] = payload;
	},
	SET_RESET_CASE_LIST: (state, payload) => {
		state[SHOULD_RESET_CASE_LIST] = payload;
	},
	SET_COMMUNITY_BOOKMARK_TOTAL_COUNT: (state, payload) => {
		state[COMMUNITY_BOOKMARK_TOTAL_COUNT] = payload;
	},
	SET_COMMUNITY_ANSWER_BOOKMARK_COUNT: (state, payload) => {
		state[COMMUNITY_ANSWER_BOOKMARK_COUNT] = payload;
	},
	SET_COMMUNITY_BOOKMARK_LIST: (state, payload) => {
		state[COMMUNITY_BOOKMARK_LIST] = payload;
	},
	ADD_COMMUNITY_BOOKMARK_LIST: (state, payload) => {
		const currentList = state[COMMUNITY_BOOKMARK_LIST] || [];
		state[COMMUNITY_BOOKMARK_LIST] = [...currentList, ...payload];
	},
	SET_COMMUNITY_BOOKMARK_LIST_INFO: (state, payload) => {
		state[COMMUNITY_BOOKMARK_LIST_INFO] = { ...state[COMMUNITY_BOOKMARK_LIST_INFO], ...payload };
	},
	SET_IS_COMMUNITY_BOOKMARKED: (state, payload) => {
		state[IS_COMMUNITY_BOOKMARKED] = payload;
	},
	SET_HAS_ANY_TYPE_COMMUNITY_BOOKMARKED: (state, payload) => {
		state[HAS_ANY_TYPE_COMMUNITY_BOOKMARKED] = payload;
	},
	SET_IS_FROM_COMMUNITY_BOOKMARK: (state, payload) => {
		state[IS_FROM_COMMUNITY_BOOKMARK] = payload;
	},
	SET_ANSWER_CONTENT_NO: (state, payload) => {
		state[ANSWER_CONTENT_NO] = payload;
	},
	SET_FEED_TYPE: (state, payload) => {
		state[FEED_TYPE] = payload;
	},
	SET_CASE_DETAIL_ANSWER_INFINITE_ID: (state, payload) => {
		state[CASE_DETAIL_ANSWER_INFINITE_ID] = payload;
	},
	UPDATE_QUESTION_IS_BOOKMARKED: (state, { isBookmarked }) => {
		if (isBookmarked === null) {
			return;
		}
		const copiedQuestion = { ...state[CASE_DETAIL_QUESTION] };
		state[CASE_DETAIL_QUESTION] = getBookmarkUpdatedData(copiedQuestion, isBookmarked);
	},
	UPDATE_COMMENT_IS_BOOKMARKED: (state, { isBookmarked, answerIndex, commentIndex }) => {
		if (isBookmarked === null) {
			return;
		}
		const copiedAnswer = [...state[CASE_DETAIL_ANSWER_LIST]];
		const comment = copiedAnswer[answerIndex].reply_feedback[commentIndex];
		comment.bookmarkCount = isBookmarked ? comment.bookmarkCount + 1 : comment.bookmarkCount - 1;
		comment.isBookmarked = isBookmarked;
		state[CASE_DETAIL_ANSWER_LIST] = copiedAnswer;
	},
	UPDATE_REPLY_IS_BOOKMARKED: (state, { isBookmarked, answerIndex, commentIndex, replyIndex }) => {
		if (isBookmarked === null) {
			return;
		}
		const copiedAnswer = [...state[CASE_DETAIL_ANSWER_LIST]];
		const reply = copiedAnswer[answerIndex].reply_feedback[commentIndex].reply_comments[replyIndex];
		reply.bookmarkCount = isBookmarked ? reply.bookmarkCount + 1 : reply.bookmarkCount - 1;
		reply.isBookmarked = isBookmarked;
		state[CASE_DETAIL_ANSWER_LIST] = copiedAnswer;
	},
	UPDATE_REPLY_SUCCESS: (state, { answerIndex, commentIndex, reply }) => {
		const copiedAnswer = [...state[CASE_DETAIL_ANSWER_LIST]];
		copiedAnswer[answerIndex].reply_feedback[commentIndex].reply_comments = [
			...state[CASE_DETAIL_ANSWER_LIST][answerIndex].reply_feedback[commentIndex].reply_comments,
			reply,
		];
		state[CASE_DETAIL_ANSWER_LIST] = copiedAnswer;
	},
	[SET_IS_SHOW_CASE_SHARE_MODAL]: (state, payload) => {
		state.isShowCaseShareModal = payload;
	},
	[SET_CASE_PAGE_PAGINATION]: (state, payload) => {
		state[CASE_PAGE_PAGINATION] = payload;
	},
	[SET_AFFILIATE_UNIV_EXPOSURE_DATA]: (state, payload) => {
		state[AFFILIATE_UNIV_EXPOSURE_DATA] = payload;
	},
};

export const actions = {
	async [LOAD_QUESTION_TYPE_OPTIONS]({ commit }, params) {
		try {
			const { data } = await this.$api.case.getCaseCategories(params);

			// Select 구조에 맞게 object key 이름 바꾸기 (category -> label, id -> value)
			const newCategories = data.map(category => {
				category.value = category.id;
				category.label = category.category;
				delete category.id;
				delete category.category;
				return category;
			});

			commit(SET_QUESTION_TYPE_OPTIONS, [DEFAULT_QUESTION_TYPE_OPTION, ...newCategories]);
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_RECOMMENDED_CAMP_LIST]({ commit }, params) {
		try {
			const {
				data: { list },
			} = await this.$api.camp.getRecommendedCampList(params);
			commit(SET_RECOMMENDED_CAMP_LIST, list);
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_RECOMMENDED_KEYWORD_PT_LIST]({ commit }, params) {
		try {
			const {
				data: { list },
			} = await this.$api.classPt.getPtList(params);
			commit(SET_RECOMMENDED_KEYWORD_PT_LIST, list);
		} catch (error) {
			console.error(error);
		}
	},
	[LOAD_CASE_LIST](_, params) {
		return this.$axios.get('/api/case-list/question-list', {
			params,
		});
	},
	[LOAD_CASE_LIST_PAGINATION]({ getters }, params) {
		return new Promise((resolve, reject) => {
			const getCaseList = getters[IS_SORT_LATEST]
				? this.$api.case.getCaseList(params)
				: this.$api.case.getCaseRelationList(params);

			getCaseList
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	async [LOAD_USER_PARTNER_INFO]({ commit }) {
		try {
			const {
				data: { organization_type, is_affiliate, is_re_affiliated, is_user_using_affiliate, affiliated_before },
			} = await this.$api.case.checkAffiliate();
			commit(SET_USER_PARTNER_INFO, {
				organizationType: organization_type,
				isAffiliate: is_affiliate,
				isReAffiliated: is_re_affiliated,
				isUserUsingAffiliate: is_user_using_affiliate,
				isUserAffiliatedBefore: affiliated_before,
			});
		} catch (error) {
			console.error(error);
		}
	},
	[RESET_CASE_LIST]({ commit }) {
		commit(SET_CASE_LIST, []);
	},
	[LOAD_CASE_DETAIL_ANSWER_LIST](_, params) {
		return new Promise((resolve, reject) => {
			this.$api.case
				.getCaseDetailAnswerPagination(params)
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[RESET_CASE_DETAIL_INFO]({ commit }) {
		commit(SET_CASE_DETAIL_QUESTION, null);
		commit(SET_CASE_DETAIL_ANSWER_LIST, null);
		commit(SET_CASE_DETAIL_ANSWER_INFO, {
			page: 1,
			limit: 2,
			count: 0,
			requestNo: -1,
		});
	},
	async [LOAD_COMMUNITY_BOOKMARK_TOTAL_COUNT]({ commit }) {
		try {
			const { data } = await this.$api.case.getBookMarkTotalCnt();
			commit(SET_COMMUNITY_BOOKMARK_TOTAL_COUNT, data);
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_COMMUNITY_ANSWER_BOOKMARK_COUNT]({ commit }) {
		try {
			const {
				data: { cnt },
			} = await this.$api.case.getCommunityBookmarkAnswerCount();

			commit(SET_COMMUNITY_ANSWER_BOOKMARK_COUNT, cnt);
		} catch (error) {
			console.error(error);
		}
	},
	[LOAD_COMMUNITY_BOOKMARK_TOTAL_LIST](_, params) {
		try {
			return new Promise((resolve, reject) => {
				this.$api.case
					.getBookMarkTotalList(params)
					.then(response => {
						resolve(response);
					})
					.catch(error => {
						reject(error);
					});
			});
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_BOOKMARK_LIST](_, params) {
		try {
			const { data } = await this.$api.case.getBookmarkList(params);
			return data;
		} catch (error) {
			console.error(error);
		}
	},
	[LOAD_COMMUNITY_BOOKMARK_LIST](_, params) {
		try {
			return new Promise((resolve, reject) => {
				this.$api.case
					.getCommunityBookmarkListWithPagination(params)
					.then(response => {
						resolve(response);
					})
					.catch(error => {
						reject(error);
					});
			});
		} catch (error) {
			console.error(error);
		}
	},
	[LOAD_COMMUNITY_ANSWER_BOOKMARK_LIST](_, params) {
		try {
			return new Promise((resolve, reject) => {
				this.$api.case
					.getCommunityBookmarkAnswerListWithPagination(params)
					.then(response => {
						resolve(response);
					})
					.catch(error => {
						reject(error);
					});
			});
		} catch (error) {
			console.error(error);
		}
	},

	async [LOAD_HAS_ANY_TYPE_COMMUNITY_BOOKMARKED]({ commit, getters }, item) {
		try {
			const params = { request_no: item.request_no ?? getters[GET_REQUEST_NO], user_no: item.user_no };
			const { data } = await this.$api.case.checkBookmark(params);
			commit(SET_HAS_ANY_TYPE_COMMUNITY_BOOKMARKED, Boolean(data));
			return Boolean(data);
		} catch (error) {
			console.error('LOAD_HAS_ANY_TYPE_COMMUNITY_BOOKMARKED error: ', error);
		}
	},
	async [LOAD_IS_COMMUNITY_BOOKMARKED]({ commit }, params) {
		try {
			const {
				data: { status },
			} = await this.$api.case.getIsBookmarked(params);
			commit(SET_IS_COMMUNITY_BOOKMARKED, Boolean(status));
			return Boolean(status);
		} catch (error) {
			console.error('LOAD_IS_COMMUNITY_BOOKMARKED error: ', error);
		}
	},
	async [LOAD_IS_COMMUNITY_ANSWER_BOOKMARKED](_, params) {
		try {
			const {
				data: { status },
			} = await this.$api.case.getIsAnswerBookmarked(params);
			return Boolean(status);
		} catch (error) {
			console.error('LOAD_IS_COMMUNITY_ANSWER_BOOKMARKED error: ', error);
		}
	},
	async [LOAD_IS_COMMUNITY_COMMENT_BOOKMARKED](_, contentNo) {
		try {
			const params = { content_no: contentNo, type: 'comment' };
			const {
				data: { status },
			} = await this.$api.case.getIsAnswerBookmarked(params);
			return Boolean(status);
		} catch (error) {
			console.error('LOAD_IS_COMMUNITY_COMMENT_BOOKMARKED error: ', error);
		}
	},
	async [LOAD_IS_COMMUNITY_REPLY_BOOKMARKED](_, contentNo) {
		try {
			const params = { content_no: contentNo, type: 'reply' };
			const {
				data: { status },
			} = await this.$api.case.getIsAnswerBookmarked(params);
			return Boolean(status);
		} catch (error) {
			console.error('LOAD_IS_COMMUNITY_REPLY_BOOKMARKED error: ', error);
		}
	},
	async [TOGGLE_COMMUNITY_BOOKMARK]({ getters, commit, dispatch }, params) {
		try {
			// 답변 북마크인 경우 bookmarkAnswer / 기본은 case
			const isCase = getters[GET_FEED_TYPE] === COMMUNITY_FEED_TYPE.CASE;
			const {
				data: { isBookmarked },
			} = await this.$api.case.toggleCommunityBookmark(params);
			dispatch(LOAD_COMMUNITY_BOOKMARK_TOTAL_COUNT);
			if (isCase) {
				commit(SET_IS_COMMUNITY_BOOKMARKED, isBookmarked);
				dispatch(LOAD_IS_COMMUNITY_BOOKMARKED, params);
			}
			return isBookmarked;
		} catch (error) {
			console.error(error);
			return null;
		}
	},
	[RESET_COMMUNITY_BOOKMARK_LIST]({ commit }) {
		commit(SET_COMMUNITY_BOOKMARK_LIST, null);
		commit(SET_COMMUNITY_BOOKMARK_LIST_INFO, {
			page: 1,
			total: 0,
			limit: 10,
		});
	},
	async [LOAD_IS_BUY_MENTORING]({ commit }) {
		try {
			const { data } = await this.$api.case.getBuyMentoring();
			commit(SET_IS_BUY_MENTORING, data.buy_mentoring);
			commit(SET_BUY_MENTORING_INFO, data);
		} catch (error) {
			console.error('LOAD_IS_BUY_MENTORING error : ', error);
		}
	},
	async [LOAD_CASE_DETAIL_QUESTION]({ commit }, params) {
		try {
			const { data } = await this.$api.case.getCaseDetailQuestion(params);
			commit(SET_CASE_DETAIL_QUESTION, data);
			commit(SET_CASE_CATEGORY_GROUP_ID, data.mentoring_category.mentoring_category_group_id);
		} catch (error) {
			console.error('LOAD_CASE_DETAIL_QUESTION error: ', error);
		}
	},
	// [LOAD_MATCHED_AFFILIATE_CAMP_LIST]과 사용하는 값이 달라서 별도 Api로 작성
	async [LOAD_AFFILIATE_UNIV_EXPOSURE_DATA]({ commit, state }) {
		try {
			const params = {
				page: 1,
				limit: 10,
				type: 'community',
				is_random: true,
			};
			const {
				data: { list, partner, partner_url, contractNo, univ },
			} = await this.$api.camp.getUnivMatchedEduCamp(params);
			commit(SET_AFFILIATE_UNIV_EXPOSURE_DATA, {
				contractNo,
				list,
				partner,
				partnerUrl: partner_url,
				univ,
			});
		} catch (error) {
			console.error(error);
			commit(SET_AFFILIATE_UNIV_EXPOSURE_DATA, state[AFFILIATE_UNIV_EXPOSURE_DATA]);
		}
	},
};
