import { createNamespacedHelpers } from 'vuex';

import {
	CAREER,
	INTEREST_DEPARTMENT,
	NICKNAME,
	UNIV,
	MAJOR,
	SCHOOL,
	MARKETING,
	SCHOOL_DEFAULT_PROPERTY,
	COMPANY_INFO,
} from '@/constants/user-meta';

const NAMESPACE = 'userMeta';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfUserMeta,
	mapMutations as mapMutationsOfUserMeta,
	mapActions as mapActionsOfUserMeta,
};

// state
export const USER_META = 'USER_META';
export const CURRENT_COMPANY = 'CURRENT_COMPANY';
export const FORMER_COMPANY = 'FORMER_COMPANY';
export const IS_SHOW_USER_META_MODAL = 'IS_SHOW_USER_META_MODAL';
export const USER_META_STEP = 'USER_META_STEP';
export const IS_FINISHED_UPDATE_PROFILE = 'IS_FINISHED_UPDATE_PROFILE';

// getters
export const GET_USER_META = 'GET_USER_META';
export const GET_USER_META_CAREER = 'GET_USER_META_CAREER';
export const GET_USER_META_CAREER_FOR_GTM = 'GET_USER_META_CAREER_FOR_GTM';
export const GET_USER_META_INTEREST_DEPARTMENT = 'GET_USER_META_INTEREST_DEPARTMENT';
export const GET_USER_META_NICKNAME = 'GET_USER_META_NICKNAME';
export const GET_USER_META_UNIV = 'GET_USER_META_UNIV';
export const GET_USER_META_MAJOR = 'GET_USER_META_MAJOR';
export const GET_USER_META_SCHOOL = 'GET_USER_META_SCHOOL';
export const GET_USER_META_MARKETING = 'GET_USER_META_MARKETING';
export const GET_USER_META_CURRENT_COMPANY = 'GET_USER_META_CURRENT_COMPANY';
export const GET_USER_META_FORMER_COMPANY = 'GET_USER_META_FORMER_COMPANY';
export const GET_IS_SHOW_USER_META_MODAL = 'GET_IS_SHOW_USER_META_MODAL';
export const GET_USER_META_STEP = 'GET_USER_META_STEP';
export const GET_IS_FINISHED_UPDATE_PROFILE = 'GET_IS_FINISHED_UPDATE_PROFILE';

// mutations
export const SET_USER_META = 'SET_USER_META';
export const SET_USER_META_SCHOOL = 'SET_USER_META_SCHOOL';
export const SET_USER_META_CURRENT_COMPANY = 'SET_USER_META_CURRENT_COMPANY';
export const SET_USER_META_FORMER_COMPANY = 'SET_USER_META_FORMER_COMPANY';
export const SET_USER_META_CAREER = 'SET_USER_META_CAREER';
export const SET_USER_META_INTEREST_DEPARTMENT = 'SET_USER_META_INTEREST_DEPARTMENT';
export const SET_IS_SHOW_USER_META_MODAL = 'SET_IS_SHOW_USER_META_MODAL';
export const SET_USER_META_STEP = 'SET_USER_META_STEP';
export const SET_IS_FINISHED_UPDATE_PROFILE = 'SET_IS_FINISHED_UPDATE_PROFILE';

// actions
export const LOAD_CHECK_UPDATE_PROFILE = 'LOAD_CHECK_UPDATE_PROFILE';
export const LOAD_USER_META_FOR_GTM = 'LOAD_USER_META_FOR_GTM';

export const state = () => ({
	[USER_META]: {
		[CAREER]: null,
		[INTEREST_DEPARTMENT]: [],
		[NICKNAME]: null,
		[UNIV]: null,
		[MAJOR]: null,
		[MARKETING]: null,
		[SCHOOL]: [SCHOOL_DEFAULT_PROPERTY],
		[CURRENT_COMPANY]: COMPANY_INFO,
		[FORMER_COMPANY]: [],
	},
	[IS_SHOW_USER_META_MODAL]: false,
	[USER_META_STEP]: 1,
	[IS_FINISHED_UPDATE_PROFILE]: null,
});

export const getters = {
	[GET_USER_META]: state => state[USER_META],
	[GET_USER_META_CAREER]: state => state[USER_META][CAREER],
	[GET_USER_META_CAREER_FOR_GTM]: state => (state[USER_META][CAREER] === 1 ? '신입' : '경력'),
	[GET_USER_META_INTEREST_DEPARTMENT]: state => state[USER_META][INTEREST_DEPARTMENT],
	[GET_USER_META_NICKNAME]: state => state[USER_META][NICKNAME],
	[GET_USER_META_UNIV]: state => state[USER_META][UNIV],
	[GET_USER_META_MAJOR]: state => state[USER_META][MAJOR],
	[GET_USER_META_SCHOOL]: state => state[USER_META][SCHOOL],
	[GET_USER_META_MARKETING]: state => state[USER_META][MARKETING],
	[GET_USER_META_CURRENT_COMPANY]: state => state[USER_META][CURRENT_COMPANY],
	[GET_USER_META_FORMER_COMPANY]: state => state[USER_META][FORMER_COMPANY],
	[GET_IS_SHOW_USER_META_MODAL]: state => state[IS_SHOW_USER_META_MODAL],
	[GET_USER_META_STEP]: state => state[USER_META_STEP],
	[GET_IS_FINISHED_UPDATE_PROFILE]: state => state[IS_FINISHED_UPDATE_PROFILE],
};

export const mutations = {
	[SET_USER_META]: (state, payload) => {
		state[USER_META] = { ...state[USER_META], ...payload };
	},
	[SET_USER_META_SCHOOL]: (state, payload) => {
		state[USER_META][SCHOOL] = payload;
	},
	[SET_USER_META_CURRENT_COMPANY]: (state, payload) => {
		state[USER_META][CURRENT_COMPANY] = payload;
	},
	[SET_USER_META_FORMER_COMPANY]: (state, payload) => {
		state[USER_META][FORMER_COMPANY] = payload;
	},
	[SET_USER_META_CAREER]: (state, payload) => {
		state[USER_META][CAREER] = payload;
	},
	[SET_USER_META_INTEREST_DEPARTMENT]: (state, payload) => {
		state[USER_META][INTEREST_DEPARTMENT] = payload;
	},
	[SET_IS_SHOW_USER_META_MODAL]: (state, payload) => {
		state[IS_SHOW_USER_META_MODAL] = payload;
	},
	[SET_USER_META_STEP]: (state, payload) => {
		state[USER_META_STEP] = payload;
	},
	[SET_IS_FINISHED_UPDATE_PROFILE]: (state, payload) => {
		state[IS_FINISHED_UPDATE_PROFILE] = payload;
	},
};

export const actions = {
	async [LOAD_CHECK_UPDATE_PROFILE]({ commit }) {
		try {
			const { data } = await this.$api.userMeta.checkUpdateProfile();
			commit(SET_IS_FINISHED_UPDATE_PROFILE, Boolean(data));
		} catch (error) {
			console.error('LOAD_CHECK_UPDATE_PROFILE error: ', error);
		}
	},
	async [LOAD_USER_META_FOR_GTM]({ commit }) {
		try {
			const { data } = await this.$api.userMeta.fetchUserMetaAll();
			commit(SET_USER_META, {
				[CAREER]: parseInt(data[CAREER], 10),
				[UNIV]: data.univ.univ,
				[MAJOR]: data.univ.major,
			});
		} catch (error) {
			console.error('LOAD_USER_META_FOR_GTM error:', error);
		}
	},
};
