
import { mapGetters } from 'vuex';

import { GTM_COMMUNITY_AD_PARAMETERS } from '@/constants/community/gtm';

import { getSessionStorage } from '@/utils/utils';

import { CAMP_CHARM_ITEM, mapGettersOfCamp } from '@/store/camp';
import { CASE_GTM_CATEGORY, mapGettersOfCase } from '@/store/case';

import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

import LoadingSkeleton from '@/src/components/Other/Skeleton.vue';

export default {
	name: 'CaseListEduCampBannerVer2',
	mixins: [layoutMixin, routeMixin, userInfoMixin, gtmTriggerMixin, communityGtmTriggerMixin],
	data() {
		return {
			hasVedCategory: false,
			targetCampInfo: null,
		};
	},
	computed: {
		...mapGetters(['userInfo']),
		...mapGettersOfCamp([CAMP_CHARM_ITEM]),
		...mapGettersOfCase([CASE_GTM_CATEGORY]),
		gtmCommonParameters() {
			return {
				...this.$_userInfoForGtm,
				where: GTM_COMMUNITY_AD_PARAMETERS.WHERE.HOME,
				product_name: GTM_COMMUNITY_AD_PARAMETERS.PRODUCT_NAME.CALLOUT,
				page_depth: 1,
				order: 1,
				inventory_number: 1,
				category: this[CASE_GTM_CATEGORY],
				agency_name: null,
				client_name: GTM_COMMUNITY_AD_PARAMETERS.CLIENT_NAME.COMENTO_EDU,
				job_posting_id: `${GTM_COMMUNITY_AD_PARAMETERS.CLIENT_NAME.COMENTO_EDU}-230921`,
			};
		},
	},
	async mounted() {
		this.hasVedCategory = getSessionStorage('comentoEduDetailTopCategory');
		this.$_impression({
			...this.gtmCommonParameters,
		});
		if (this.hasVedCategory) {
			await this.getPopularCamp();
		} else {
			this.targetCampInfo = this[CAMP_CHARM_ITEM];
		}
	},
	methods: {
		async getPopularCamp() {
			try {
				const params = {
					category: [getSessionStorage('comentoEduDetailTopCategory')],
					page: 1,
					limit: 1,
				};
				const {
					data: { code, list },
				} = await this.$axios.get(`/api/edu/eduListPopular`, { params });
				if (code === 200) {
					this.targetCampInfo = list[0];
				}
			} catch (error) {
				console.error('Error fetching popular camp:', error);
			}
		},
		goToVED() {
			this.$_clickAdBanner({ ...this.gtmCommonParameters });
			this.$_clickEduBanner({
				user_no: this.userNo,
				user_type: this.userType,
				email: this.userId,
				banner_type: 'native',
				banner_at: 'onboardCaseList',
				banner_to: 'viewEduDetail',
				camp_id: null,
				group_id: this.targetCampInfo.no,
				local_storage_id: this.comentoLocalStorageId,
			});
			this.$_routeMixin_href(this.targetCampInfo.url);
		},
	},
	components: { LoadingSkeleton },
};
