const TIMELINE_PREFIX = `/api/timeline`;

export default axios => ({
	/**
	 * 타임라인 리스트 가져오기
	 * @param {Object} params
	 * @param {number} params.page
	 * @param {number} params.limit
	 * @returns {*}
	 */
	fetchTimeline(params) {
		return axios.get(`${TIMELINE_PREFIX}/sync`, { params });
	},
	/**
	 * 필터가 적용된 피드 가져오기
	 * @param {object} params - query params
	 * @param {number} params.page - 페이지 번호
	 * @param {number} params.limit - 페이지당 아이템 수
	 * @param {string} params.jobs - 직무 필터('|'로 구분됨)
	 * @returns {Promise}
	 */
	fetchFilteredFeed(params) {
		return axios.get(`/api/community/case-list/feed/comento`, { params });
	},
});
