export const MY_PAGE_PATH = `/mypage`;
export const MY_PAGE_ALL_NAME = '전체';
export const MY_PAGE_NAV_PREFIX = '?nav=';
export const MY_PAGE_SELECT_PREFIX = '?select=';
export const MY_PAGE_THANKS_PATH = `${MY_PAGE_PATH}/thanks`;

export const MY_PAGE_ESSAY_PATH = `${MY_PAGE_PATH}/essay`;
export const MY_PAGE_AI_ANALYTICS_PATH = `${MY_PAGE_PATH}/analytics`;

export const MY_PAGE_CREDIT_SHOP_PATH = `${MY_PAGE_PATH}/shop/list`;
export const MY_PAGE_CREDIT_CASH_PATH = `${MY_PAGE_PATH}/credit/cash`;
export const MY_PAGE_CREDIT_LIST_PATH = `${MY_PAGE_PATH}/credit/list`;

export const MY_PAGE_COMMUNITY_PATH = `${MY_PAGE_PATH}/community`;
export const MY_PAGE_COMMUNITY_NAV = {
	ALL: '',
	MY_POST: 'my-post',
	MY_POST_LIKED: 'post-liked',
	MY_COMMENT: 'my-comment',
	MY_COMMENT_LIKED: 'comment-liked',
};

export const MY_PAGE_CREATE_CLASS_PATH = `${MY_PAGE_PATH}/class-open`;
export const MY_PAGE_CREATE_CLASS_NAV = { ALL: '', PT: 'pts', EDU: 'edu' };
export const MY_PAGE_CREATE_CLASS_SELECT = { PROGRESS_TYPE: 0, COMPLETE_TYPE: 1 };

export const MY_PAGE_PURCHASE_HISTORY_PATH = `${MY_PAGE_PATH}/payment`;
export const MY_PAGE_PURCHASE_NAV = { PAYMENT: '', REFUND: 'refund' };

export const MY_PAGE_CLASSROOM_PATH = `${MY_PAGE_PATH}/classroom`;
export const MY_PAGE_CLASSROOM_APPLIED_PATH = `${MY_PAGE_CLASSROOM_PATH}/applied`;
export const MY_PAGE_CLASSROOM_OPENED_PATH = `${MY_PAGE_CLASSROOM_PATH}/opened`;
export const MY_PAGE_CLASSROOM_NAV = {
	ALL: '',
	PT: 'pts',
	EDU: 'edu',
	VOD: 'vod',
	PATH: 'path',
	KDC: 'kdc',
	EXTERNSHIP: 'externship',
	VIRTUAL_COMPANY: 'virtualCompany',
};
export const MY_PAGE_CLASSROOM_SELECT = { OPENED_CLASS_TYPE: 0, APPLIED_CLASS_TYPE: 1 };

export const MY_PAGE_SETTING_PATH = `${MY_PAGE_PATH}/setting`;
export const MY_PAGE_SETTING_NAV = { PROFILE: '', CAREER: 'career', NOTI: 'noti', QUIT: 'quit' };
export const MY_PAGE_SETTING_CAREER_PATH = `${MY_PAGE_SETTING_PATH}/${MY_PAGE_SETTING_NAV.CAREER}`;
export const MY_PAGE_SETTING_CAREER_PC_PATH = MY_PAGE_SETTING_CAREER_PATH;
export const MY_PAGE_SETTING_NOTI_PATH = `${MY_PAGE_SETTING_PATH}/${MY_PAGE_SETTING_NAV.NOTI}`;
export const MY_PAGE_SETTING_NOTI_PC_PATH = MY_PAGE_SETTING_NOTI_PATH;
export const MY_PAGE_SETTING_QUIT_PATH = `${MY_PAGE_SETTING_PATH}/${MY_PAGE_SETTING_NAV.QUIT}`;

export const MY_PAGE_SETTING_NAME = `프로필`;
export const MY_PAGE_SETTING_CAREER_NAME = `이력 정보`;
export const MY_PAGE_SETTING_NOTI_NAME = `알림 설정`;
export const MY_PAGE_SETTING_QUIT_NAME = `회원 탈퇴`;

export const MY_PAGE_MATCHING_PATH = `${MY_PAGE_PATH}/matching`;
