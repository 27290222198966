import { baseUrl } from '@/plugins/constants';

export default axios => ({
	/**
	 * vod 검색
	 * @apiParam {String} keyword 검색어 [optional]
	 * @apiParam {Number} page 페이지번호 [required]
	 * @apiParam {Number} limit 페이지당 갯수 [required]
	 **/
	searchVod(params) {
		return axios.get('/api/vod/search', { params });
	},
	/**
	 * vod detail 정보
	 * @apiData {Number} vod_no vod 넘버 [required]
	 **/
	getVODDetail(data) {
		return axios.post('/api/vod/detail', data);
	},
	/**
	 * vod detail Number
	 * @apiParam {Number} week 주차 | -1(전체)
	 * @apiParam {Number} camp_no 페이지번호 캠프 번호
	 * @apiParam {Number} isMentor detail에서는 0을 보내기
	 * @apiParam {Number} page 페이지당 갯수
	 * @apiParam {String} sort 페이지당 갯수 ASC | DESC
	 **/
	getVodDetailReview(params) {
		return axios.get('/api/edu/survey/list', { params });
	},
	/**
	 * vod detail 직부캠 group no 찾기
	 * @apiParam {Number} vod_id
	 **/
	getVodGroupSearch(params) {
		return axios.get('/api/vod/groupSearch', { params });
	},
	/**
	 * vod detail 직부캠 추천 가져오기
	 * @apiParam {Number} vod_id
	 **/
	getVodRecommend(params) {
		return axios.get('/api/vod/recommend', { params });
	},
	/**
	 * vod payment (vod 결제 확인)
	 **/
	getVodPayment(params) {
		return axios.get('/api/vod/payment', { params });
	},
	/**
	 * vod check payment (vod 결제 확인 - partner 사용자 용)
	 * @apiParam {Number} vod_id
	 **/
	getCheckPayment(params) {
		return axios.get('/api/vod/check/payment', { params });
	},
	/**
	 * vod log start (vimeo 로그 )
	 * @apiParam {String} vod_session: '',
	 * @apiParam {Number} user_no: 1234,
	 * @apiParam {Number} vod_no: 12,
	 * @apiParam {String} vod_type: 'trailer' or,'main',
	 * @apiParam {String} event_name: ended, play,
	 **/
	handelVimeoLog(data) {
		return axios.post('/api/vod/log/start', data);
	},
	/**
	 * vod log history (vimeo 로그 히스토리 )
	 * @apiParam {String} vod_session: '',
	 * @apiParam {Number} vod_no: 12,
	 * @apiParam {String} vod_type: 'trailer' or,'main',
	 * @apiParam {Array} vod_history: [],
	 * @apiParam {Number} vod_play_time: 12
	 **/
	handelVimenoLogHistory(data) {
		return axios.post('/api/vod/log/history', data);
	},

	/**
	 * 직무 에센스 강의 수강률 가져오기
	 * @apiParam {Number} campNo 캠프 번호
	 */
	async getVodProgress(campNo) {
		const {
			data: { view_percentage, is_passed, vod_no },
		} = await axios.get(`/api/vod/played?camp_no=${campNo}`);

		return { view_percentage, is_passed, vod_no };
	},
	/**
	 * @apiParam option :1,2,3
	 **/
	postKDCCheckCard(data) {
		return axios.post('/api/kdc/check/card', data);
	},
	/**
	 * VOD 기존 재생 정보
	 * @param {Object} params
	 * @param {number} params.vod_no VOD 번호
	 * @param {'main' | 'trailer'} params.vod_type VOD 종류
	 * @returns {Object} VOD 시청 기록
	 */
	getVodPlayInfo(params) {
		return axios.get('/api/vod/log/info', { params });
	},
	/**
	 * 직무VOD 실시간 로그
	 * @param {Object} data 실시간 로그
	 * @param {number} data.vod_no VOD 번호
	 * @param {'main' | 'trailer'} data.vod_type VOD 종류
	 * @param {number} data.totalPlaytime 총 재생 시간
	 * @param {number} data.completion 시청 완료율
	 * @param {Object} data.vimeo Vimeo 이벤트 객체
	 */
	postRealtimeLog(data) {
		return axios.post('/api/vod/log/realtime', data);
	},
	/**
	 * 직무VOD 이벤트 로그
	 * @param {Object} data 실시간 로그
	 * @param {number} data.vod_no VOD 번호
	 * @param {'main' | 'trailer'} data.vod_type VOD 종류
	 * @param {string} data.event 이벤트 종류
	 * @param {number} data.totalPlaytime 총 재생 시간
	 * @param {number} data.completion 시청 완료율
	 * @param {Object} data.vimeo Vimeo 이벤트 객체
	 */
	postEventLog(data) {
		return axios.post('/api/vod/log/eventtime', data);
	},
	/**
	 * 브라우저가 종료되는 시점에서 사용하는 fetch
	 * fetch의 keepalive 옵션을 쓰면 브라우저가 종료되는 시점에서도 안정적으로 전송 가능
	 * axios에는 이 옵션이 아직 없음
	 */
	postEventLogKeepalive(data, token) {
		try {
			fetch(`${baseUrl}/api/vod/log/eventtime`, {
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					Authorization: 'Bearer ' + token,
					'Content-Type': 'application/json',
				},
				keepalive: true,
			});
		} catch (err) {
			console.error(err);
		}
	},
	/**
	 * 시청 기록 저장 (기존과 다르게 시청 시간을 보내면 백엔드에서 시청 시간을 계산)
	 * @param {object} data
	 * @param {number} data.vod_no VOD 번호
	 * @param {'main' | 'trailer'} data.vod_type VOD 종류
	 * @param {number} data.currentWatchedTime 지난 저장 후 시청 시간
	 * @param {object} data.vimeo Vimeo 이벤트 객체
	 * @param {number} data.vimeo.duration VOD 총 시간
	 * @param {number} data.vimeo.seconds 현재 시간
	 * @param {number} data.vimeo.percent 총 시간 대비 현재 시간의 비율
	 * @returns {object} VOD 시청 기록
	 */
	postRealtimeLogV2(data) {
		return axios.post('/api/vod/log/real-time', data);
	},
	/**
	 * 시청 이벤트를 기록
	 * @param {object} data
	 * @param {number} data.vod_no VOD 번호
	 * @param {'main' | 'trailer'} data.vod_type VOD 종류
	 * @param {object} data.vimeo Vimeo 이벤트 객체
	 * @param {number} data.vimeo.duration VOD 총 시간
	 * @param {number} data.vimeo.seconds 현재 시간
	 * @param {number} data.vimeo.percent 총 시간 대비 현재 시간의 비율
	 * @param {string} data.event 이벤트 종류
	 * @returns {boolean} 처리 결과
	 */
	postEventtimeLogV2(data) {
		return axios.post('/api/vod/log/event-time', data);
	},
	/**
	 * 시청가능 확인 및 vodData 확인
	 * @apiParam {String} userId (암호화된 값),
	 * @apiParam {String} elProvCorsNum (암호화된 값)
	 * @apiParam {String} gCorsAttnId (암호화된 값),
	 * @apiParam {String} type,
	 */
	postCheckPlayableTarget(data) {
		return axios.post('/api/vod/external/check/playable', data);
	},
	/**
	 * 실시간 시청 기록 및 이벤트 로그
	 * @apiParam {String} external_user_id,
	 * @apiParam {Number} vod_no
	 * @apiParam {String} vod_type,
	 * @apiParam {object} vimeo,
	 * @apiParam {String} event,
	 * @apiParam {Number} currentWatchedTime,
	 */
	postExternalRealtime(data) {
		return axios.post('/api/vod/external/log/realtime', data);
	},
});
