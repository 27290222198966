import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'community/timeline';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfCommunityTimeline,
	mapMutations as mapMutationsOfCommunityTimeline,
	mapActions as mapActionsOfCommunityTimeline,
};

// states
export const POSTS = 'POSTS';
export const INFINITE_ID = 'INFINITE_ID';
export const POSTS_PARAMS = 'POSTS_PARAMS';
export const EDU_APPLICANT_INFO = 'EDU_APPLICANT_INFO';
export const EDU_ASSIGNMENT_FEEDBACK_INFO = 'EDU_ASSIGNMENT_FEEDBACK_INFO';
export const defaultActivityLogs = null;
// getters
export const GET_POSTS = 'GET_POSTS';
export const GET_INFINITE_ID = 'GET_INFINITE_ID';
export const GET_POSTS_PARAMS = 'GET_POSTS_PARAMS';
export const GET_EDU_APPLICANT_INFO = 'GET_EDU_APPLICANT_INFO';
export const GET_EDU_ASSIGNMENT_FEEDBACK_INFO = 'GET_EDU_ASSIGNMENT_FEEDBACK_INFO';

// mutations
export const SET_POSTS = 'SET_POSTS';
export const SET_INFINITE_ID = 'SET_INFINITE_ID';
export const SET_POSTS_PARAMS = 'SET_POSTS_PARAMS';
export const SET_EDU_APPLICANT_INFO = 'SET_EDU_APPLICANT_INFO';
export const SET_EDU_ASSIGNMENT_FEEDBACK_INFO = 'SET_EDU_ASSIGNMENT_FEEDBACK_INFO';

// actions
export const FETCH_POSTS = 'FETCH_POSTS';

export const state = () => ({
	[POSTS]: [],
	[INFINITE_ID]: +new Date(),
	[POSTS_PARAMS]: {
		page: 1,
		perPage: 16,
	},
	[EDU_APPLICANT_INFO]: null,
	[EDU_ASSIGNMENT_FEEDBACK_INFO]: null,
});

export const getters = {
	[GET_POSTS]: state => state[POSTS],
	[GET_INFINITE_ID]: state => state[INFINITE_ID],
	[GET_POSTS_PARAMS]: state => state[POSTS_PARAMS],
	[GET_EDU_APPLICANT_INFO]: state => state[EDU_APPLICANT_INFO],
	[GET_EDU_ASSIGNMENT_FEEDBACK_INFO]: state => state[EDU_ASSIGNMENT_FEEDBACK_INFO],
};

export const mutations = {
	[SET_POSTS]: (state, payload) => {
		state[POSTS] = payload;
	},
	[SET_INFINITE_ID]: (state, payload) => {
		state[INFINITE_ID] = payload;
	},
	[SET_POSTS_PARAMS]: (state, payload) => {
		state[POSTS_PARAMS] = payload;
	},
	[SET_EDU_APPLICANT_INFO]: (state, payload) => {
		state[EDU_APPLICANT_INFO] = payload;
	},
	[SET_EDU_ASSIGNMENT_FEEDBACK_INFO]: (state, payload) => {
		state[EDU_ASSIGNMENT_FEEDBACK_INFO] = payload;
	},
};

export const actions = {
	[FETCH_POSTS](_, params) {
		return new Promise((resolve, reject) => {
			const fetchPosts = this.$api.timeline.fetchTimeline(params);
			fetchPosts
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
};
