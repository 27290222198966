import { mapGetters } from 'vuex';

import { CASE_PATH } from '@/constants/paths';

import { baseUrl } from '@/plugins/constants';

import { isEmptyData } from '@/utils/utils';

import {
	mapGettersOfCase,
	SET_CASE_LIST_INFO,
	mapMutationsOfCase,
	SET_CASE_LIST,
	INFINITE_ID,
	SET_INFINITE_ID,
	CASE_QUESTION_TYPE_ID,
	SET_IS_FETCHING_CASE_LIST,
} from '@/store/case';

import routeMixin from '@/mixins/routeMixin';

const caseMixin = {
	mixins: [routeMixin],
	data() {
		return {
			baseUrl,
		};
	},
	computed: {
		...mapGetters(['isUser']),
		...mapGettersOfCase([INFINITE_ID, CASE_QUESTION_TYPE_ID]),
	},
	methods: {
		...mapMutationsOfCase([
			SET_CASE_LIST_INFO,
			SET_CASE_LIST,
			SET_CASE_LIST_INFO,
			SET_INFINITE_ID,
			SET_IS_FETCHING_CASE_LIST,
		]),
		$_goRequestPageWithCategory() {
			return this.$_routeMixin_href(`${this.baseUrl}/request/question`);
		},
		$_goLoginPage() {
			this.$_routeMixin_href(`${this.baseUrl}/logout`);
		},
		/**
		 * 질문하기 페이지로 이동하기
		 * 선택한 카테고리가 있으면 파라미터를 함께 넘기기
		 * 비회원은 로그인 페이지로 보내기
		 */
		$_handleGoRequestPage() {
			if (this.isUser) {
				this.$_goRequestPageWithCategory();
			} else {
				this.$_goLoginPage();
			}
		},
		/**
		 * readCase로 이동시키기
		 * @param search_c: 회사명
		 * @param search_j: 직무명
		 * @param search_o: 제목
		 * @param request_no: 요청 번호
		 */
		$_goReadCase({ search_c = '모든회사', search_j = '모든직무', search_o = '_', request_no = 0 }) {
			const readCasePath = this.$_handleMakeUrl(search_c, search_j, search_o, request_no);
			this.$_routeMixin_href(readCasePath);
		},
		$_handleMakeUrl(company = '모든회사', position = '모든직무', title = '_', request_no = 0) {
			company = isEmptyData(company?.trim()) ? '모든회사' : company;
			position = isEmptyData(position?.trim()) ? '모든직무' : position;
			title = isEmptyData(title?.trim()) ? '_' : title;
			request_no = request_no ?? 0;

			company = company.replace(/[#&+\-%@=\/\\:;,.'"^`~_|!?*$<>()\[\]{}\n‘’㈜]|\s+/gi, '');
			position = position.replace(/[#&+\-%@=\/\\:;,.'"^`~_|!?*$<>()\[\]{}\n‘’㈜]|\s+/gi, '');
			title = title.replace(/\s+/g, '_');

			return `${CASE_PATH}/${company}/${position}/${title}-${request_no}`;
		},
		$_handleSetCaseSorting(value) {
			const params = { sorting: value };
			this[SET_CASE_LIST_INFO](params);
		},
		$_initCase() {
			this[SET_CASE_LIST]([]);
			this[SET_CASE_LIST_INFO]({
				count: 0,
				page: 1,
			});
			this[SET_INFINITE_ID](this[INFINITE_ID] + 1);
			this[SET_IS_FETCHING_CASE_LIST](true);
		},
		$_getUserListItem(userList, property) {
			if (!userList) {
				return null;
			}
			return userList[property];
		},
		$_getUserProfileImg(userList) {
			if (isEmptyData(userList?.user_profile?.profile_img)) {
				return null;
			}
			return userList.user_profile.profile_img;
		},
	},
};

export default caseMixin;
