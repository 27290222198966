
import { defineComponent } from 'vue';

import layoutMixin from '@/mixins/layoutMixin';

export default defineComponent({
	name: 'CaseListEduCampBannerContents',
	mixins: [layoutMixin],
	props: {
		hasAffiliateUnivExposure: {
			type: Boolean,
			default: false,
		},
		nickname: {
			type: String,
			default: '',
		},
		mentorNickname: {
			type: String,
			default: '',
		},
		category: {
			type: String,
			default: '',
		},
		affiliateDisplayUnivName: {
			type: String,
			default: '',
		},
	},
});
