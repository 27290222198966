
import { mapGetters } from 'vuex';

import { INSIGHT_PATH, LOUNGE_PATH } from '@/constants/paths';
import { INTEREST_DEPARTMENT } from '@/constants/user-meta';

import { INSIGHT, LOUNGE } from '@/plugins/constants';

import {
	GET_ROUTE_NAME,
	mapGettersOfLoading,
	ROUTE_NAME_FOR_COMMUNITY_LOUNGE,
	ROUTE_NAME_FOR_COMMUNITY_INSIGHT,
	ROUTE_NAME_FOR_COMMUNITY_ANSWER,
} from '@/store/loading';
import { mapMutationsOfUserMeta, SET_USER_META_INTEREST_DEPARTMENT } from '@/store/userMeta';

import AnswerSideBox from '@/components/answer/organisms/AnswerSideBox.vue';
import CaseListSideBox from '@/components/case/list/main/organisms/CaseListSideBox.vue';
import CommunityUserVoiceAlert from '@/components/case/timeline/UserVoiceAlert.vue';
import GridLayout from '@/components/common/atoms/GridLayout.vue';
import CommunityBottomDrawerFAB from '@/components/common/FAB/CommunityBottomDrawerFAB.vue';
import CommonLoginModal from '@/components/common/molecules/login/CommonLoginModal.vue';
import WhiteScreen from '@/components/skeleton/WhiteScreen.vue';

import appCommandMixin from '@/mixins/appCommandMixin';
import caseMixin from '@/mixins/caseMixin';
import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';
import userVoiceAlertMixin from '@/mixins/userVoiceAlertMixin';

export const toastCookieName = 'PREVENT_ANSWER_ACTION_TOAST_APPEAR';

export default {
	name: 'CommunityListNuxt',
	mixins: [
		layoutMixin,
		gtmTriggerMixin,
		userInfoMixin,
		routeMixin,
		caseMixin,
		appCommandMixin,
		userVoiceAlertMixin,
		communityGtmTriggerMixin,
	],
	async fetch() {
		await this.fetchUserMeta();
	},
	fetchOnServer: false,
	data() {
		return {
			isShowLoginModal: false,
			ROUTE_NAME_FOR_COMMUNITY_LOUNGE,
			ROUTE_NAME_FOR_COMMUNITY_INSIGHT,
		};
	},
	computed: {
		...mapGetters(['isMentor', 'isUser', 'isComentoMembers']),
		...mapGettersOfLoading([GET_ROUTE_NAME]),
		currentUrl() {
			return this.$route.fullPath;
		},
		showCommunityFAB() {
			if (this.IS_PC) {
				return false;
			}
			if (this.$_app_canShowFAB || this.isAnswer) {
				return false;
			}
			switch (this[GET_ROUTE_NAME]) {
				case this.ROUTE_NAME_FOR_COMMUNITY_INSIGHT:
					return this.isComentoMembers;
				default:
					return true;
			}
		},
		showUserVoiceAlertStyle() {
			const alertHeight = '32px';
			// 커뮤니티 공지가 있을 때 간격 추가
			if (this.$_showUserVoiceAlert && this.IS_PC) {
				return { marginTop: `calc(36px + ${alertHeight})` };
			}
			return { marginTop: '32px' };
		},
		isAnswer() {
			return this.$route.name.includes(ROUTE_NAME_FOR_COMMUNITY_ANSWER);
		},
	},
	// created() {
	// 	this.$_checkShowUserVoiceAlertInitially();
	// },
	methods: {
		...mapMutationsOfUserMeta([SET_USER_META_INTEREST_DEPARTMENT]),
		clickWritingButton() {
			this.clickServiceButton('visitRequest-qa');

			if (!this.isUser) {
				return this.showLoginModal();
			}

			switch (this[GET_ROUTE_NAME]) {
				case this.ROUTE_NAME_FOR_COMMUNITY_INSIGHT:
					return this.goInsightCreate();
				case this.ROUTE_NAME_FOR_COMMUNITY_LOUNGE:
					return this.goLoungeCreate();
				default:
					return this.$_goRequestPageWithCategory();
			}
		},
		clickServiceButton(where) {
			// GTM - clickServiceButton
			this.$_clickServiceButton({ ...this.$_userInfoForGtm, where: 'onboardCaseList', click_to: where });
		},
		showLoginModal() {
			this.isShowLoginModal = true;
		},
		goInsightCreate() {
			// GTM - clickWriteInsightPost
			this.$_clickWriteInsightPost({ ...this.$_userInfoForGtm, category: INSIGHT });
			this.$_routeMixin_go_page(`${INSIGHT_PATH}/create`);
		},
		goLoungeCreate() {
			this.$_clickWriteBBSPost({ ...this.$_userInfoForGtm, category: LOUNGE });
			this.$_routeMixin_go_page(`${LOUNGE_PATH}/create`);
		},
		handleArray(value) {
			if (Array.isArray(value)) {
				return value;
			} else if (typeof value === 'string') {
				return value.split(',');
			}
			return [value];
		},
		async fetchUserMeta() {
			try {
				const { data } = await this.$api.userMeta.fetchUserMetaAll();
				if (data[INTEREST_DEPARTMENT]) {
					this.SET_USER_META_INTEREST_DEPARTMENT(this.handleArray(data[INTEREST_DEPARTMENT]));
				}
			} catch (e) {
				console.error('fetchUserMeta error: ', e);
			}
		},
	},
	components: {
		CommunityUserVoiceAlert,
		CommunityBottomDrawerFAB,
		WhiteScreen,
		CommonLoginModal,
		GridLayout,
		CaseListSideBox,
		AnswerSideBox,
	},
};
